import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-rows-content-header-title',
  templateUrl: './rows-content-header-title.component.html',
  styles: [':host{display: flex; flex: 1}']
})
export class RowsContentHeaderTitleComponent {
  @Input()
  title = '';
}
