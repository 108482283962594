import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '../../request/request-service';
import { ApiUrlService } from '../../api-url/api-url.service';
import { PriceConfig } from '../../../../models/price/price-config-model';

@Injectable({
  providedIn: 'root'
})
export class PriceConfigService {
  constructor(
    private readonly requestService: RequestService,
    private readonly apiUrlService: ApiUrlService
  ) {}

  private getPriceConfigsUrl(): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/priceconfigs`;
  }

  private getPriceConfigByIdPublicUrl(priceConfigId: number): string {
    return `${this.apiUrlService.getPublicApiBasePath()}/priceconfigs/${priceConfigId}`;
  }

  private getPriceConfigByIdUrl(priceConfigId: number): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/priceconfigs/${priceConfigId}`;
  }

  private deletePriceConfigUrl(priceConfigId: number): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/priceconfigs/${priceConfigId}`;
  }

  private savePriceConfigUrl(): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/priceconfigs`;
  }

  private copyPriceConfigByIdUrl(priceConfigId: number): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/priceconfigs/${priceConfigId}/copy`;
  }

  getPriceConfigs$(): Observable<PriceConfig[]> {
    return this.requestService.getRequest$<PriceConfig[]>(this.getPriceConfigsUrl());
  }

  getPriceConfigByIdPublic$(priceConfigId: number): Observable<PriceConfig> {
    return this.requestService.getRequest$<PriceConfig>(this.getPriceConfigByIdPublicUrl(priceConfigId));
  }

  getPriceConfigById$(priceConfigId: number): Observable<PriceConfig> {
    return this.requestService.getRequest$<PriceConfig>(this.getPriceConfigByIdUrl(priceConfigId));
  }

  deletePriceConfig$(priceConfigId: number): Observable<boolean> {
    return this.requestService.deleteRequest$<boolean>(this.deletePriceConfigUrl(priceConfigId));
  }

  createPriceConfig$(priceConfig: PriceConfig): Observable<PriceConfig> {
    return this.requestService.postRequest$<PriceConfig, PriceConfig>(this.savePriceConfigUrl(), priceConfig);
  }

  updatePriceConfig$(priceConfig: PriceConfig): Observable<PriceConfig> {
    return this.requestService.putRequest$<PriceConfig, PriceConfig>(this.savePriceConfigUrl(), priceConfig);
  }

  copyPriceConfig$(priceConfigId: number) {
    return this.requestService.putRequest$<PriceConfig, undefined>(this.copyPriceConfigByIdUrl(priceConfigId), undefined);
  }
}
