import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { filter } from 'rxjs';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { LanguageService } from '../language';
import { WebsiteRoute } from '../../const/routes/website-routes';
import { CustomerDashboardRoute } from '../../const/routes/customer-dashboard-routes';
import { AdminDashboardRoute } from '../../const/routes/admin-dashboard-routes';
import { BackofficeRoute } from '../../const/routes/backoffice-routes';
import { RoutingParamResolverService } from '../routing/routing-param-resolver-service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private currentUrl = '';

  constructor(
    private readonly router: Router,
    private readonly localizeRouterService: LocalizeRouterService,
    private readonly languageService: LanguageService,
    private readonly routingParamResolverService: RoutingParamResolverService
  ) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.setCurrentUrl();
    });
  }

  private setCurrentUrl(): void {
    this.currentUrl = this.router.url.replace(`/${this.localizeRouterService.parser.currentLang}`, '').split('?')[0]!;
  }

  private handleCaseThatShouldNeverHappen() {
    console.error('Should never happen.');
  }

  getCurrentUrl() {
    if (this.currentUrl && this.currentUrl !== '') {
      return this.currentUrl;
    }

    return this.router.url;
  }

  navigate(path: WebsiteRoute | CustomerDashboardRoute | AdminDashboardRoute | BackofficeRoute, params?: (string | number)[]) {
    this.navigateWithExtras(path, params);
  }

  navigateWithExtras(
    path: WebsiteRoute | CustomerDashboardRoute | AdminDashboardRoute | BackofficeRoute,
    params?: (string | number)[],
    extras?: NavigationExtras
  ) {
    const resolvedPath = params ? this.routingParamResolverService.resolveRouteParams(path, params) : path;

    if (!resolvedPath) {
      return;
    }

    let navPath: string | any[] = resolvedPath;

    const languageFromUrl = this.languageService.getLanguageFromUrl(resolvedPath);
    if (!languageFromUrl) {
      navPath = this.localizeRouterService.translateRoute(resolvedPath);
    }
    this.router.navigate([navPath], extras).catch(() => this.handleCaseThatShouldNeverHappen());
  }
}
