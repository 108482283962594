import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { CartModel } from '../../models';
import { ApiUrlService, RequestService } from '../rest';
import { CartResponse } from '../../models/cart/cart-response-model';
import { LocalStorageService } from '../local-storage';
import { TicketData } from '../../models/ticketdata/ticket-data-model';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private readonly ADD_TO_CART_URL = `${this.apiUrlService.getPublicApiBasePath()}/cart/add`;
  private readonly GET_CART_URL = `${this.apiUrlService.getPublicApiBasePath()}/cart`;
  private readonly GET_INTERNAL_CART_URL = `${this.apiUrlService.getInternalApiBasePath()}/cart`;

  private readonly cart$ = new BehaviorSubject<CartResponse | undefined>(undefined);

  constructor(
    private readonly apiUrlService: ApiUrlService,
    private readonly requestService: RequestService,
    private readonly localStorageService: LocalStorageService
  ) {
    this.fetchCart();
  }

  getCart() {
    return this.cart$.asObservable();
  }

  fetchCart() {
    const cartSessionId = this.localStorageService.getCartId();

    if (cartSessionId) {
      const url = `${this.GET_CART_URL}/${cartSessionId}`;

      this.requestService.getRequest$<CartResponse>(url).subscribe(cartResponse => {
        this.cart$.next(cartResponse);
      });
    }
  }

  addToCart$(cartObject: CartModel) {
    this.requestService.postRequest$<CartResponse, CartModel>(this.ADD_TO_CART_URL, cartObject).subscribe(cartResponse => {
      this.localStorageService.storeCartId(cartResponse.cartSessionId);
      this.cart$.next(cartResponse);
    });
  }

  removeEntryFromCart(orderEntryId: number) {
    const cartSessionId = this.localStorageService.getCartId();

    if (cartSessionId) {
      const url = `${this.GET_CART_URL}/${cartSessionId}/remove/${orderEntryId}`;

      this.requestService.deleteRequest$<CartResponse>(url).subscribe(cartResponse => {
        this.cart$.next(cartResponse);
      });
    }
  }

  updatePriceForOrderEntry$(orderEntryId: number, priceId: number) {
    const cartSessionId = this.localStorageService.getCartId();

    if (cartSessionId) {
      const url = `${this.GET_CART_URL}/${cartSessionId}/${orderEntryId}/price/${priceId}`;

      this.requestService.putRequest$<CartResponse, unknown>(url, undefined).subscribe(cartResponse => {
        this.cart$.next(cartResponse);
      });
    }
  }

  setTicketData$(ticketData: TicketData[]) {
    const cartSessionId = this.localStorageService.getCartId();
    if (!cartSessionId) {
      return of(false);
    }

    return this.requestService.postRequest$<unknown, TicketData[]>(`${this.GET_INTERNAL_CART_URL}/${cartSessionId}/ticketData`, ticketData);
  }

  resetCart() {
    this.localStorageService.removeCartSessionId();
    this.cart$.next(undefined);
  }
}
