import { Injectable } from '@angular/core';
import { AVAILABLE_COUNTRIES, SWITZERLAND } from '../../const';

export const DEFAULT_COUNTRY = SWITZERLAND;

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  mapCountryKeyToName(countryName: string | undefined): string {
    for (const country of AVAILABLE_COUNTRIES) {
      if (country.key === countryName) {
        return country.value;
      }
    }

    return DEFAULT_COUNTRY.value;
  }
}
