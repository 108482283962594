import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { ANGLE_RIGHT } from '../../const';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  imports: [FaIconComponent],
  standalone: true
})
export class LinkComponent {
  protected readonly ANGLE_RIGHT = ANGLE_RIGHT;

  @Input({ required: true })
  label!: string;
  @Output()
  clicked = new EventEmitter<void>();

  onClick() {
    this.clicked.emit();
  }
}
