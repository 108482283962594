<div class="flex gap-2 pt-2">
  <label [for]="id" [classList]="'block mb-2 text-sm font-medium text-text-default text-' + labelColor"
    >{{ label }}
    <ng-container *ngIf="isRequired && label">*</ng-container>
  </label>
  <ng-container *ngIf="hasError">
    <p [classList]="'block mb-2 text-sm font-medium text-' + errorTextColor">
      <app-icon class="mr-2" [icon]="ERROR_TRIANGLE"></app-icon>
      <span *ngIf="!onErrorOnlyShowIcon">{{ errorMessage }}</span>
    </p>
  </ng-container>
</div>
