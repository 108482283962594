import { AbstractEvent } from './abstract-event-model';

export const EVENT_TYPE = 'EVENT';

export interface Event extends AbstractEvent {
  startTime: string;
  type: 'EVENT';
  eventGroupId: number | undefined;
  locationId: number | undefined;
  published: boolean;
  scanningPassword: string | undefined;
  canceled: boolean;
  finished: boolean;
}
