import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '../../request';
import { ApiUrlService } from '../../api-url';
import { AdminInvitation, AdminInvitationConfirmationRequest } from '../../../../models';

@Injectable({
  providedIn: 'root'
})
export class AdminInvitationService {
  constructor(
    private readonly requestService: RequestService,
    private readonly apiUrlService: ApiUrlService
  ) {}

  private getInviteNewAdminUrl() {
    return `${this.apiUrlService.getAdminApiBasePath()}/admininvitations`;
  }

  private getDeleteAdminInvitationUrl(id: number) {
    return `${this.apiUrlService.getAdminApiBasePath()}/admininvitations/${id}`;
  }

  private getAdminInvitationByIdUrl(id: number) {
    return `${this.apiUrlService.getAdminApiBasePath()}/admininvitations/${id}`;
  }

  private getAdminInvitationByEncodedIdAndCodeUrl(id: string, code: string) {
    return `${this.apiUrlService.getPublicApiBasePath()}/admininvitations/${id}&${code}`;
  }

  private getAdminInvitationsUrl() {
    return `${this.apiUrlService.getAdminApiBasePath()}/admininvitations`;
  }

  private getResendAdminInvitationUrl(id: number) {
    return `${this.apiUrlService.getAdminApiBasePath()}/admininvitations/${id}`;
  }

  private getUpdateAndResendAdminInvitationUrl() {
    return `${this.apiUrlService.getAdminApiBasePath()}/admininvitations`;
  }

  private getConfirmAdminInvitationUrl() {
    return `${this.apiUrlService.getPublicApiBasePath()}/admininvitations`;
  }

  inviteNewAdmin$(adminInvitation: AdminInvitation): Observable<AdminInvitation> {
    return this.requestService.postRequest$<AdminInvitation, AdminInvitation>(this.getInviteNewAdminUrl(), adminInvitation);
  }

  deleteAdminInvitation$(id: number): Observable<boolean> {
    return this.requestService.deleteRequest$<boolean>(this.getDeleteAdminInvitationUrl(id));
  }

  getAdminInvitationById$(id: number): Observable<AdminInvitation> {
    return this.requestService.getRequest$<AdminInvitation>(this.getAdminInvitationByIdUrl(id));
  }

  getAdminInvitationByEncodedIdAndCode$(id: string, code: string): Observable<AdminInvitation> {
    return this.requestService.getRequest$<AdminInvitation>(this.getAdminInvitationByEncodedIdAndCodeUrl(id, code));
  }

  getAdminInvitations$(): Observable<AdminInvitation[]> {
    return this.requestService.getRequest$<AdminInvitation[]>(this.getAdminInvitationsUrl());
  }

  resendAdminInvitation$(id: number): Observable<boolean> {
    return this.requestService.postRequest$<boolean, undefined>(this.getResendAdminInvitationUrl(id), undefined);
  }

  updateAndResendAdminInvitation$(adminInvitation: AdminInvitation): Observable<AdminInvitation> {
    return this.requestService.putRequest$<AdminInvitation, AdminInvitation>(this.getUpdateAndResendAdminInvitationUrl(), adminInvitation);
  }

  confirmAdminInvitation$(adminInvitationConfirmation: AdminInvitationConfirmationRequest): Observable<boolean> {
    return this.requestService.postRequest$<boolean, AdminInvitationConfirmationRequest>(
      this.getConfirmAdminInvitationUrl(),
      adminInvitationConfirmation
    );
  }
}
