import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '../../request';
import { ApiUrlService } from '../../api-url';
import { Admin } from '../../../../models';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  constructor(
    private readonly requestService: RequestService,
    private readonly apiUrlService: ApiUrlService
  ) {}

  private getAdminUrl(): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/admins/own`;
  }

  private getAdminsUrl(): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/admins`;
  }

  private getDeleteAdminByIdUrl(adminId: number): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/admins/${adminId}`;
  }

  getAdmin$(): Observable<Admin> {
    return this.requestService.getRequest$<Admin>(this.getAdminUrl());
  }

  getAdmins$(): Observable<Admin[]> {
    return this.requestService.getRequest$<Admin[]>(this.getAdminsUrl());
  }

  deleteAdmin$(adminId: number): Observable<boolean> {
    return this.requestService.deleteRequest$<boolean>(this.getDeleteAdminByIdUrl(adminId));
  }

  updateAdmin$(admin: Admin) {
    return this.requestService.putRequest$<Admin, Admin>(`${this.getAdminsUrl()}`, admin);
  }
}
