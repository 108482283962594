import { Component, Inject, inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarModule, MatSnackBarRef } from '@angular/material/snack-bar';
import { NgIf } from '@angular/common';

export const SNACKBAR_TYPES = {
  INFO: 'info',
  SUCCESS: 'success',
  ERROR: 'error'
};

@Component({
  selector: 'app-snack-bar',
  templateUrl: 'snack-bar.component.html',
  styles: [
    `
      :host {
        display: flex;
        padding: 3px;
      }
    `
  ],
  standalone: true,
  imports: [MatSnackBarModule, NgIf]
})
export class SnackBarComponent {
  protected readonly SNACKBAR_TYPES = SNACKBAR_TYPES;
  type = SNACKBAR_TYPES.INFO;

  snackBarRef = inject(MatSnackBarRef);

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackBarData) {
    if (!data.type) {
      this.type = SNACKBAR_TYPES.INFO;
    } else {
      this.type = data.type;
    }
  }
}

export interface SnackBarData {
  message: string;
  buttonLabel: string;
  type: string;
}
