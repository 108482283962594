<app-auth-header />

<app-auth-main-page-content>
  <app-h1 [title]="'Password Reset Failed'"></app-h1>

  <p>This password reset link is no longer valid.</p>
  <p [class]="'mb-4'">Please request a new link to reset your password.</p>

  <app-action-bar>
    <app-icon-button buttonType="primary" label="Reset Password" (clicked)="onResetPassword()"></app-icon-button>
  </app-action-bar>
</app-auth-main-page-content>
