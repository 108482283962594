<app-auth-header />

<app-auth-main-page-content>
  <app-h1 [title]="'Reset Password'"></app-h1>

  <app-rows-content-wrapper>
    <app-error-container *ngIf="!doPasswordsMatch()" [errorMessage]="PASSWORDS_NOT_MATCHING_VALUE"></app-error-container>

    <app-form-row [label]="'Password'" [for]="'password'">
      <app-text-input
        [id]="'password'"
        [placeholder]="'Password *'"
        [isRequired]="true"
        [(value)]="password"
        [type]="PASSWORD"
        (valueChange)="validateForm()"
      ></app-text-input>
    </app-form-row>

    <app-form-row [label]="'Repeat password'" [for]="'password_repeat'">
      <app-text-input
        [id]="'password_repeat'"
        [placeholder]="'Repeat *'"
        [isRequired]="true"
        [(value)]="passwordConfirm"
        [type]="PASSWORD"
        (valueChange)="validateForm()"
      ></app-text-input>
    </app-form-row>
  </app-rows-content-wrapper>

  <app-action-bar>
    <app-icon-button label="Change password" buttonType="primary" (clicked)="onSubmit()" [enabled]="formValid"></app-icon-button>
  </app-action-bar> </app-auth-main-page-content
>>
