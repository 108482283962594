import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MINIMAL_PASSWORD_LENGTH } from '../../../shared';

export const PASSWORD_STRENGTH_VALIDATION_FAILED_KEY = 'passwordStrength';

export function createPasswordStrengthValidator(): ValidatorFn {
  const specialChars = /[`!@#$%^&*()_\-+=[\]{};':"\\|,.<>/?~ ]/;

  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as string;

    if (!value) {
      // eslint-disable-next-line no-null/no-null
      return null;
    }

    const hasUpperCase = /[A-Z]+/.test(value);

    const hasLowerCase = /[a-z]+/.test(value);

    const hasNumeric = /[0-9]+/.test(value);

    const hasSpecialChars = specialChars.test(value);

    const passwordValid = hasUpperCase && hasLowerCase && hasNumeric && hasSpecialChars;

    // eslint-disable-next-line no-null/no-null
    return value.length < MINIMAL_PASSWORD_LENGTH || !passwordValid
      ? {
          passwordStrength: {
            hasUpperCase: hasUpperCase,
            hasLowerCase: hasLowerCase,
            hasNumeric: hasNumeric,
            hasSpecialChars: hasSpecialChars,
            minLength: value.length >= MINIMAL_PASSWORD_LENGTH
          }
        }
      : null;
  };
}
