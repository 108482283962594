import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '../../request/request-service';
import { ApiUrlService } from '../../api-url/api-url.service';
import { EventGroup } from '../../../../models/event/event-group-model';
import { DateModel } from '../../../../models/date-model';
import { Organizer } from '../../../../models/user/organizer-model';
import { Price } from '../../../../models/price/price-model';

@Injectable({
  providedIn: 'root'
})
export class EventGroupService {
  constructor(
    private readonly requestService: RequestService,
    private readonly apiUrlService: ApiUrlService
  ) {}

  private saveEventGroupUrl(): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/eventgroups`;
  }

  private deleteEventGroupUrl(eventGroupId: number): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/eventgroups/${eventGroupId}`;
  }

  private getAllEventGroupsUrl(): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/eventgroups`;
  }

  private getEventGroupByIdPublicUrl(eventGroupId: number): string {
    return `${this.apiUrlService.getPublicApiBasePath()}/eventgroups/${eventGroupId}`;
  }

  private getEventGroupByIdUrl(eventGroupId: number): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/eventgroups/${eventGroupId}`;
  }

  private copyEventGroupByIdUrl(eventGroupId: number): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/eventgroups/${eventGroupId}/copy`;
  }

  private getFirstEventDateForGroupUrl(eventGroupId: number) {
    return `${this.apiUrlService.getPublicApiBasePath()}/eventgroups/${eventGroupId}/date`;
  }

  private getOrganizerForGroupUrl(eventGroupId: number) {
    return `${this.apiUrlService.getPublicApiBasePath()}/eventgroups/${eventGroupId}/organizer`;
  }

  private getLowestPriceForEventGroupUrl(eventGroupId: number) {
    return `${this.apiUrlService.getPublicApiBasePath()}/eventgroups/${eventGroupId}/lowestPrice`;
  }

  saveEventGroup(eventGroup: EventGroup) {
    return this.requestService.postRequest$<EventGroup, EventGroup>(this.saveEventGroupUrl(), eventGroup);
  }

  updateEventGroup(eventGroup: EventGroup) {
    return this.requestService.putRequest$<EventGroup, EventGroup>(this.saveEventGroupUrl(), eventGroup);
  }

  getEventGroupByIdPublic$(eventGroupId: number): Observable<EventGroup> {
    return this.requestService.getRequest$<EventGroup>(this.getEventGroupByIdPublicUrl(eventGroupId));
  }

  getEventGroupById$(eventGroupId: number): Observable<EventGroup> {
    return this.requestService.getRequest$<EventGroup>(this.getEventGroupByIdUrl(eventGroupId));
  }

  deleteEventGroup$(eventGroupId: number) {
    return this.requestService.deleteRequest$<boolean>(this.deleteEventGroupUrl(eventGroupId));
  }

  getAllEventGroups$(): Observable<EventGroup[]> {
    return this.requestService.getRequest$<EventGroup[]>(this.getAllEventGroupsUrl());
  }

  getEventGroupByLink$(eventGroupLink: string): Observable<EventGroup> {
    return this.requestService.getRequest$<EventGroup>(eventGroupLink);
  }

  copyEventGroup(id: number) {
    return this.requestService.putRequest$<EventGroup, undefined>(this.copyEventGroupByIdUrl(id), undefined);
  }

  getFirstEventDateForEventGroup$(id: number) {
    return this.requestService.getRequest$<DateModel>(this.getFirstEventDateForGroupUrl(id));
  }

  getOrganizerForEventGroup$(id: number) {
    return this.requestService.getRequest$<Organizer>(this.getOrganizerForGroupUrl(id));
  }

  getLowestPriceForEventGroup$(id: number) {
    return this.requestService.getRequest$<Price>(this.getLowestPriceForEventGroupUrl(id));
  }
}
