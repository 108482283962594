import { Component, OnInit } from '@angular/core';
import { AuthService, NavigationService, NavigationUrlService } from '../../../../index';
import { ADMIN_DASHBOARD_LOGIN_ROUTE } from '../../../../const/routes/admin-dashboard-routes';
import { WEBSITE_LOGIN_ROUTE } from '../../../../const/routes/website-routes';

@Component({
  selector: 'app-email-confirmation-successful',
  templateUrl: './email-confirmation-successful.component.html'
})
export class EmailConfirmationSuccessfulComponent implements OnInit {
  confirmMobileNumber = false;
  confirmationCode = '';

  deviceConfirmationSuccessful = false;
  deviceConfirmationFailed = false;

  constructor(
    private readonly navigationService: NavigationService,
    private readonly navigationUrlService: NavigationUrlService,
    private readonly authService: AuthService
  ) {}

  onGoToLogin() {
    if (this.navigationUrlService.isAdminModule()) {
      this.navigationService.navigate(ADMIN_DASHBOARD_LOGIN_ROUTE);
    } else {
      this.navigationService.navigate(WEBSITE_LOGIN_ROUTE);
    }
  }

  confirmPhone() {
    if (!this.confirmationCode) {
      return;
    }
    this.authService.sendPhoneConfirmation$(this.confirmationCode).subscribe({
      next: result => {
        if (result) {
          this.deviceConfirmationFailed = false;
          this.deviceConfirmationSuccessful = true;
        } else {
          this.deviceConfirmationFailed = true;
          this.deviceConfirmationSuccessful = false;
        }
      },
      error: () => {
        this.deviceConfirmationFailed = true;
        this.deviceConfirmationSuccessful = false;
      }
    });
  }

  ngOnInit() {
    if (!this.navigationUrlService.isAdminModule()) {
      this.confirmMobileNumber = true;
    }
  }
}
