import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { RequestService } from '../../request/request-service';
import { ApiUrlService } from '../../api-url/api-url.service';
import { EventDescription } from '../../../../models/event/event-description-model';
import { AbstractEvent } from '../../../../models/event/abstract-event-model';
import { EVENT_TYPE } from '../../../../models/event/event-model';

@Injectable({
  providedIn: 'root'
})
export class EventDescriptionService {
  constructor(
    private readonly requestService: RequestService,
    private readonly apiUrlService: ApiUrlService
  ) {}

  private getEventDescriptionsByAbstractEventIdUrl(abstractEventId: number): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/abstractevents/${abstractEventId}/descriptions`;
  }

  private getEventDescriptionsByEventIdUrlPublic(eventId: number): string {
    return `${this.apiUrlService.getPublicApiBasePath()}/eventdescriptions/event/${eventId}`;
  }

  private getEventDescriptionsByEventGroupIdUrlPublic(eventGroupId: number): string {
    return `${this.apiUrlService.getPublicApiBasePath()}/eventdescriptions/eventGroup/${eventGroupId}`;
  }

  getEventDescriptionsByAbstractEventId$(abstractEventId: number): Observable<EventDescription[]> {
    return this.requestService.getRequest$<EventDescription[]>(this.getEventDescriptionsByAbstractEventIdUrl(abstractEventId));
  }

  getEventDescriptionsByAbstractEventPublic$(abstractEvent: AbstractEvent): Observable<EventDescription[]> {
    if (abstractEvent.id) {
      const url =
        abstractEvent.type === EVENT_TYPE
          ? this.getEventDescriptionsByEventIdUrlPublic(abstractEvent.id)
          : this.getEventDescriptionsByEventGroupIdUrlPublic(abstractEvent.id);

      return this.requestService.getRequest$<EventDescription[]>(url);
    }

    return of([]);
  }
}
