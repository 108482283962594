<app-auth-header />

<app-auth-main-page-content>
  <app-h1 [title]="'Reset Password'"></app-h1>

  <p>Password successfully changed.</p>
  <p class="mb-4">You can now login to your account</p>

  <app-action-bar>
    <app-icon-button buttonType="primary" label="Login" (clicked)="onGoToLogin()"></app-icon-button>
  </app-action-bar> </app-auth-main-page-content
>>
