<app-header
  [showLoginButton]="true"
  [showSearchField]="true"
  [showBackgroundImage]="false"
  [headerHeightClass]="HEADER_HEIGHTS_CLASSES.SMALL"
></app-header>

<app-main-content [lightBackGround]="true">
  <app-rows-no-content-text [noContentText]="'This page does not exist'" />
</app-main-content>
<app-footer></app-footer>
