export interface Language {
  key: string;
  value: string;
  locale: string;
}

export const Languages: Record<string, Language> = {
  GERMAN: { key: 'DE', value: 'German', locale: 'de' },
  ENGLISH: { key: 'EN', value: 'English', locale: 'en' },
  FRENCH: { key: 'FR', value: 'French', locale: 'fr' },
  ITALIAN: { key: 'IT', value: 'Italian', locale: 'it' }
};

export const AVAILABLE_LOCALES: string[] = Object.values(Languages).map(l => l.locale);

export const AVAILABLE_LANGUAGES: Language[] = Object.values(Languages);

export function getLanguageByKey(key: string): Language {
  const language = AVAILABLE_LANGUAGES.find(l => l.key === key);
  if (language) {
    return language;
  }
  throw new Error(`Language  with key "${key}" not found`);
}

export function getLanguageByLocale(locale: string): Language {
  const language = AVAILABLE_LANGUAGES.find(l => l.locale === locale);
  if (language) {
    return language;
  }
  throw new Error(`Language  with locale "${locale}" not found`);
}

export function mapLanguageKeyToName(key: string): string {
  return getLanguageByKey(key).value;
}
