<app-dialog-wrapper [transparent]="true" dialogTitle="Removal not possible" (closeClicked)="confirm()">
  <app-dialog-body>
    <p>
      Removal of <b>{{ data.violatedObjectTable }}</b> was not possible because it is still used for a
      <b>{{ data.objectReferenceTable }}</b>
    </p>
  </app-dialog-body>
  <app-dialog-footer>
    <app-action-bar>
      <app-icon-button buttonType="primary" label="Got it!" [icon]="CHECK" (clicked)="confirm()"></app-icon-button>
    </app-action-bar>
  </app-dialog-footer>
</app-dialog-wrapper>
