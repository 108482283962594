import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-row-full-width',
  templateUrl: './form-row-full-width.component.html'
})
export class FormRowFullWidthComponent implements OnInit {
  @Input()
  label = '';

  @Input()
  for = '';

  @Input()
  stickLabelToTop = false;

  @Input()
  textSize = 'lg';

  @Input()
  labelColor = 'black';

  @Input()
  mobileWidth = 'w-full';

  @Input()
  mobileDisplayType = 'block';

  classList = '';

  ngOnInit() {
    this.classList = `${this.mobileDisplayType} lg:flex grow gap-8 ${this.stickLabelToTop ? 'items-start' : 'items-end'}`;
  }
}
