import { Component } from '@angular/core';
import { NavigationService, NavigationUrlService } from '../../../../index';
import { ADMIN_DASHBOARD_SIGNUP_RESEND_CONFIRMATION_ROUTE } from '../../../../const/routes/admin-dashboard-routes';
import { WEBSITE_SIGNUP_RESEND_CONFIRMATION_ROUTE } from '../../../../const/routes/website-routes';

@Component({
  selector: 'app-email-confirmation-failed',
  templateUrl: './email-confirmation-failed.component.html'
})
export class EmailConfirmationFailedComponent {
  constructor(
    private readonly navigationService: NavigationService,
    private readonly navigationUrlService: NavigationUrlService
  ) {}

  onRequestNewConfirmationLink() {
    const path = this.navigationUrlService.isAdminModule()
      ? ADMIN_DASHBOARD_SIGNUP_RESEND_CONFIRMATION_ROUTE
      : WEBSITE_SIGNUP_RESEND_CONFIRMATION_ROUTE;
    this.navigationService.navigate(path);
  }
}
