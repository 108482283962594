<div
  tabindex="-1"
  aria-hidden="true"
  [class]="
    ' flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-full ' +
    (transparent ? ' bg-transparent-dialog-background ' : ' bg-header-background ')
  "
>
  <div
    [class]="
      'relative p-4 max-w-full max-h-full ' +
      (widthPx === DIALOG_WIDTH_DEFAULT ? 'w-[400px]' : '') +
      (widthPx === DIALOG_WIDTH_MEDIUM ? 'w-[550px]' : '') +
      (widthPx === DIALOG_WIDTH_WIDE ? 'w-[650px]' : '') +
      (widthPx === DIALOG_WIDTH_WIDEST ? 'w-[800px]' : '') +
      (widthPx === DIALOG_WIDTH_EVEN_WIDER ? 'w-[1000px]' : '')
    "
  >
    <div class="relative bg-white rounded-lg shadow">
      <app-dialog-header>
        <app-h1 [title]="dialogTitle"></app-h1>
        <app-icon-button buttonType="closeDialog" (clicked)="onCloseClicked()"></app-icon-button>
      </app-dialog-header>

      <ng-content></ng-content>
    </div>
  </div>
</div>
