import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translated',
  // eslint-disable-next-line @angular-eslint/no-pipe-impure
  pure: false,
  standalone: true
})
export class TranslatedPipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}

  transform(query: string, ...args: unknown[]): string {
    let key = query;
    if (key.length > 0) {
      key = key.charAt(0).toLowerCase() + key.slice(1);
    }

    return this.translateService.instant(key, args) as string;
  }
}
