import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DEFAULT_MAX_TICKETS_AMOUNT } from '../../const';

@Injectable({
  providedIn: 'root'
})
export class PasswordValidatorService {
  PASSWORD_EMPTY_KEY = 'Please enter a password';
  PASSWORD_TOO_SHORT_KEY = 'The password must at least 4 letters';
  PASSWORDS_NOT_MATCHING = 'The passwords do not match';

  constructor(private readonly translateService: TranslateService) {}

  validatePassword(password: string): string {
    if (password === '') {
      return (this.translateService.instant(this.PASSWORD_EMPTY_KEY) as string) ?? this.PASSWORD_EMPTY_KEY;
    }
    if (password.length < DEFAULT_MAX_TICKETS_AMOUNT) {
      return (this.translateService.instant(this.PASSWORD_TOO_SHORT_KEY) as string) ?? this.PASSWORD_TOO_SHORT_KEY;
    }

    return '';
  }

  validatePasswordsMatch(password: string, passwordRepeat: string): string {
    return password === passwordRepeat
      ? ''
      : (this.translateService.instant(this.PASSWORDS_NOT_MATCHING) as string) ?? this.PASSWORDS_NOT_MATCHING;
  }
}
