<div class="flex grow items-end gap-8">
  <div class="w-1/4 p-1 text-lg leading-form-label-line-height">
    <label [for]="for" class="font-bold">{{ label }}</label>
  </div>
  <div class="w-1/4 flex gap-2 p-1 items-end">
    <div class="flex-1 text-lg">
      <ng-content select="[slot=street]"></ng-content>
    </div>
    <div class="w-1/4 text-lg">
      <ng-content select="[slot=number]"></ng-content>
    </div>
  </div>
  <div class="w-1/4 p-1 text-lg"></div>
  <div class="w-1/4 p-1 text-lg"></div>
</div>

<div class="flex grow items-end gap-8">
  <div class="w-1/4"></div>
  <div class="w-1/4 flex gap-2 p-1 items-end">
    <div class="flex-1 text-lg">
      <ng-content select="[slot=zip]"></ng-content>
    </div>
    <div class="w-3/4 text-lg">
      <ng-content select="[slot=city]"></ng-content>
    </div>
  </div>
  <div class="w-1/4 p-1 text-lg"></div>
  <div class="w-1/4 p-1 text-lg"></div>
</div>
