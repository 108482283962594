import { Component, OnInit } from '@angular/core';
import { AbstractEvent, DEFAULT_PAGE, EventService, PROPOSED_EVENTS_COUNT } from '../../../../../shared';
import { HEADER_HEIGHTS_CLASSES } from '../../../page_partials/header/header.component';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html'
})
export class PageNotFoundComponent implements OnInit {
  protected readonly HEADER_HEIGHTS_CLASSES = HEADER_HEIGHTS_CLASSES;

  events: AbstractEvent[] = [];

  constructor(private readonly eventService: EventService) {}

  ngOnInit() {
    this.eventService.getEventsForUser(DEFAULT_PAGE, PROPOSED_EVENTS_COUNT).subscribe(events => {
      this.events = events;
    });
  }
}
