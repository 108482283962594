import { Injectable } from '@angular/core';
import { WebsiteRoute } from '../../const/routes/website-routes';
import { CustomerDashboardRoute } from '../../const/routes/customer-dashboard-routes';
import { AdminDashboardRoute } from '../../const/routes/admin-dashboard-routes';
import { BackofficeRoute } from '../../const/routes/backoffice-routes';

@Injectable({
  providedIn: 'root'
})
export class RoutingParamResolverService {
  resolveRouteParams(path: WebsiteRoute | CustomerDashboardRoute | AdminDashboardRoute | BackofficeRoute, params?: (string | number)[]) {
    if (!params || params.length === 0) {
      return path;
    }

    const paramPlaceholders = (path.match(/\/:\w+/g) ?? []).length;

    if (params.length !== paramPlaceholders) {
      //Number of params does not match
      console.error('Number of params provided does not match the route param placeholders');

      return undefined;
    }

    let resolvedPath = path as string;
    for (const param of params) {
      resolvedPath = resolvedPath.replace(/\/:\w+/, `/${param}`);
    }

    return resolvedPath;
  }
}
