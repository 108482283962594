/// <reference types="@angular/localize" />

import { config } from '@fortawesome/fontawesome-svg-core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { ADYEN_CLIENT_KEY, ADYEN_ENVIRONMENT, API_URL } from './tokens';
import { environment } from './environments/environment';
// Make sure this is before any FontAwesome imports
config.autoAddCss = false;

platformBrowserDynamic([
  { provide: API_URL, useValue: environment.apiUrl },
  {
    provide: ADYEN_ENVIRONMENT,
    useValue: environment.adyenEnvironment
  },
  {
    provide: ADYEN_CLIENT_KEY,
    useValue: environment.adyenClientKey
  }
])
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
