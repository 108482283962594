import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import {
  DialogBodyComponent,
  DialogFooterComponent,
  DialogHelperService,
  DialogWrapperComponent,
  GridCommons,
  H1Component,
  IconButtonComponent,
  InputLabelComponent,
  SharedModule,
  TextInputComponent
} from './shared';
import { AppConfig } from './app.config';

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    FontAwesomeModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    SharedModule,
    DialogWrapperComponent,
    DialogBodyComponent,
    DialogFooterComponent,
    IconButtonComponent,
    InputLabelComponent,
    TextInputComponent,
    H1Component,
    TranslateModule
  ],
  bootstrap: [AppComponent],
  providers: [AppConfig.providers, DatePipe, DialogHelperService, GridCommons]
})
export class AppModule {}
