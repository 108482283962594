import { Component, Input } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-grid',
  standalone: true,
  imports: [AgGridAngular, NgIf],
  templateUrl: './grid.component.html'
})
export class GridComponent<T> {
  @Input()
  autoHeight = true;
  @Input()
  heightClass: string | undefined;

  @Input({ required: true })
  gridData!: T[];

  @Input({ required: true })
  colDefs: ColDef[] = [];
}
