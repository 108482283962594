import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgIf } from '@angular/common';
import { Icon, X_MARK } from '../../../const';
import { IconComponent } from '../../icon';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styles: [':host{display: flex; flex: 1}'],
  standalone: true,
  imports: [NgIf, IconComponent]
})
export class ButtonComponent {
  protected readonly X_MARK = X_MARK;

  @Input()
  type: Type = 'button';
  @Input()
  cssClass = '';
  @Input()
  tabIndex = 0;

  @Input()
  label: string | undefined;
  @Input()
  enabled = true;
  @Input()
  icon: Icon | undefined;
  @Input()
  tooltipLabel = '';
  @Input()
  id = '';

  @Output()
  clicked = new EventEmitter<void>();

  onClick() {
    this.clicked.emit();
  }
}

type Type = 'submit' | 'reset' | 'button';
