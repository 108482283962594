<app-input-label
  [id]="id"
  [label]="label"
  [hasError]="hasError && !disabled"
  [errorTextColor]="errorTextColor"
  [errorMessage]="getErrorMessage()"
  [isRequired]="isRequired"
  [onErrorOnlyShowIcon]="onErrorOnlyShowIcon"
  [labelColor]="labelColor"
  *ngIf="!hideLabel"
></app-input-label>

<ng-container *ngIf="icon">
  <div class="relative">
    <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
      <app-icon [icon]="icon"></app-icon>
    </div>

    <span *ngIf="prefix">{{ prefix }}</span>

    <input
      class="border border-button-blue-darkest text-text-default text-sm rounded-lg focus:ring-button-blue-medium focus:border-button-blue-medium block w-full ps-10 p-2.5"
      [id]="id"
      [name]="id"
      [type]="type === PASSWORD ? PASSWORD : TEXT"
      [placeholder]="placeholder"
      [formControl]="formControl"
      (input)="onInputChange()"
      (focusout)="onInputChange()"
      autocomplete="on"
    />
  </div>
</ng-container>

<ng-container *ngIf="!icon">
  <div class="flex items-center">
    <span [classList]="prefixColor + ' p-2'" *ngIf="prefix">{{ prefix }}</span>
    <input
      *ngIf="type !== DECIMAL"
      [class]="
        'border border-button-blue-darkest text-text-default text-sm rounded-lg focus:ring-button-blue-medium focus:border-button-blue-medium block w-full p-2.5'
      "
      [id]="id"
      [name]="id"
      [type]="type"
      [placeholder]="placeholder"
      [formControl]="formControl"
      (input)="onInputChange()"
      (focusout)="onInputChange()"
      autocomplete="on"
    />
    <input
      *ngIf="type === DECIMAL"
      [class]="
        'border border-button-blue-darkest text-text-default text-sm rounded-lg focus:ring-button-blue-medium focus:border-button-blue-medium block w-full p-2.5'
      "
      [id]="id"
      [name]="id"
      [type]="type"
      [placeholder]="placeholder"
      [formControl]="formControl"
      (input)="onInputChange()"
      (focusout)="onInputChange()"
      appTwoDigitDecimalNumber
      autocomplete="on"
    />
  </div>
</ng-container>
