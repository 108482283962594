import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService, NavigationService } from './shared';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  constructor(
    private readonly titleService: Title,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly languageService: LanguageService,
    private readonly navigationService: NavigationService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit() {
    this.languageService.defaultLanguageOnApplicationInit(this.navigationService.getCurrentUrl());
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          let result = route;
          while (result.firstChild) {
            result = result.firstChild;
          }

          return result;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe(data => {
        if (data.title) {
          this.titleService.setTitle(this.translateService.instant(data.title as string) as string);
        }
      });
  }
}
