import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UxHelperService {
  handleButtonKeyDown(event: KeyboardEvent, buttonKeyDownAction: () => void) {
    if (event.key === 'Enter' || event.key === 'Space') {
      buttonKeyDownAction();
    }
  }
}
