import {
  ADMIN_INVITATION_EMAIL_NOT_REGISTERED_CONSTRAINT_ERROR,
  NO_PENDING_INVITATION_CONSTRAINT_ERROR,
  UNIQUE_EVENT_NAME_CONSTRAINT_ERROR,
  UNIQUE_LAYOUT_NAME_CONSTRAINT_ERROR,
  UNIQUE_LOCATION_NAME_CONSTRAINT_ERROR,
  UNIQUE_PRICE_CONFIG_NAME_CONSTRAINT_ERROR
} from './error-messages';

export const UNIQUE_PRICE_CONFIG_NAME_CONSTRAINT = 'UniquePriceConfigNameConstraint';

export const UNIQUE_LAYOUT_NAME_CONSTRAINT = 'UniqueLayoutNameConstraint';
export const UNIQUE_LOCATION_NAME_CONSTRAINT = 'UniqueLocationInternalNameConstraint';

export const UNIQUE_EVENT_NAME_CONSTRAINT = 'UniqueEventInternalNameConstraint';

export const ADMIN_INVITATION_EMAIL_NOT_REGISTERED_CONSTRAINT = 'AdminInvitationEmailNotRegistered';
export const NO_PENDING_INVITATION_CONSTRAINT = 'NoPendingInvitation';

export const VALIDATION_KEY_MESSAGE_MAP: Map<string, string> = new Map<string, string>([
  [UNIQUE_PRICE_CONFIG_NAME_CONSTRAINT, UNIQUE_PRICE_CONFIG_NAME_CONSTRAINT_ERROR],
  [UNIQUE_LAYOUT_NAME_CONSTRAINT, UNIQUE_LAYOUT_NAME_CONSTRAINT_ERROR],
  [UNIQUE_LOCATION_NAME_CONSTRAINT, UNIQUE_LOCATION_NAME_CONSTRAINT_ERROR],
  [UNIQUE_EVENT_NAME_CONSTRAINT, UNIQUE_EVENT_NAME_CONSTRAINT_ERROR],
  [ADMIN_INVITATION_EMAIL_NOT_REGISTERED_CONSTRAINT, ADMIN_INVITATION_EMAIL_NOT_REGISTERED_CONSTRAINT_ERROR],
  [NO_PENDING_INVITATION_CONSTRAINT, NO_PENDING_INVITATION_CONSTRAINT_ERROR]
]);
