import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ADMIN_DASHBOARD, BACKOFFICE, CUSTOMER_DASHBOARD, ModuleType } from '../../const';

@Injectable({
  providedIn: 'root'
})
export class NavigationUrlService {
  private readonly dashboardRegex = /^(\/[a-z]{2})?\/dashboard/;
  private readonly adminRegex = /^(\/[a-z]{2})?\/admin/;
  private readonly backofficeRegex = /^(\/[a-z]{2})?\/backoffice/;

  constructor(private readonly router: Router) {}

  getModuleFromUrl(url: string): ModuleType {
    let result = CUSTOMER_DASHBOARD;
    if (url.match(this.adminRegex)) {
      result = ADMIN_DASHBOARD;
    } else if (url.match(this.dashboardRegex)) {
      result = CUSTOMER_DASHBOARD;
    } else if (url.match(this.backofficeRegex)) {
      result = BACKOFFICE;
    }

    return result as ModuleType;
  }

  isAdminModule(url?: string) {
    const path = url ? url : this.router.url;

    return this.getModuleFromUrl(path) === ADMIN_DASHBOARD;
  }

  isCustomerDashboardModule(url?: string) {
    const path = url ? url : this.router.url;

    return this.getModuleFromUrl(path) === CUSTOMER_DASHBOARD;
  }

  isBackofficeModule(url?: string) {
    const path = url ? url : this.router.url;

    return this.getModuleFromUrl(path) === BACKOFFICE;
  }
}
