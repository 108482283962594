import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-error-container',
  templateUrl: './error-container.component.html'
})
export class ErrorContainerComponent {
  @Input()
  errorMessage = '';

  @Input()
  subError?: string;

  @Input()
  errorObjects?: string[];
}
