<ng-container *ngIf="buttonType === 'primary'">
  <app-button
    type="button"
    [cssClass]="
      ' flex gap-2 items-center justify-between font-medium disabled:opacity-25' +
      ' bg-button-blue-dark text-button-default-text rounded-lg text-sm px-5 py-2.5' +
      ' hover:bg-button-blue-darkest focus:ring-4 focus:ring-button-blue-bright focus:outline-none'
    "
    [enabled]="enabled"
    [icon]="icon"
    [label]="label"
    [tooltipLabel]="tooltipLabel"
    [id]="id"
    (clicked)="onClick()"
  ></app-button>
</ng-container>

<ng-container *ngIf="buttonType === 'secondary'">
  <app-button
    type="button"
    [cssClass]="enabled ? 'default-button secondary-button icon-button' : 'default-button-disabled icon-button'"
    [enabled]="enabled"
    [icon]="icon"
    [label]="label"
    [tooltipLabel]="tooltipLabel"
    [id]="id"
    (clicked)="onClick()"
  ></app-button>
</ng-container>

<ng-container *ngIf="buttonType === 'cancel'">
  <app-button
    type="button"
    [cssClass]="
      ' flex gap-2 items-center justify-between font-medium disabled:opacity-25' +
      ' bg-button-red-dark text-button-default-text rounded-lg text-sm px-5 py-2.5' +
      ' hover:bg-button-red-darkest focus:ring-4 focus:ring-button-red-bright focus:outline-none'
    "
    [enabled]="enabled"
    [icon]="icon"
    [label]="label"
    [tooltipLabel]="tooltipLabel"
    [id]="id"
    (clicked)="onClick()"
  ></app-button>
</ng-container>

<ng-container *ngIf="buttonType === 'delete'">
  <app-button
    type="button"
    [cssClass]="
      ' flex gap-2 items-center justify-between font-medium disabled:opacity-25' +
      ' bg-button-red-dark text-button-default-text rounded-lg text-sm px-5 py-2.5' +
      ' hover:bg-button-red-darkest focus:ring-4 focus:ring-button-red-bright focus:outline-none'
    "
    [enabled]="enabled"
    [icon]="icon"
    [label]="label"
    [tooltipLabel]="tooltipLabel"
    [id]="id"
    (clicked)="onClick()"
  ></app-button>
</ng-container>

<ng-container *ngIf="buttonType === 'closeDialog'">
  <app-button
    type="button"
    [cssClass]="
      ' inline-flex justify-center items-center' +
      ' bg-transparent text-text-default rounded-lg text-sm w-8 h-8 ms-auto' +
      ' hover:bg-light-gray hover:text-text-default'
    "
    [enabled]="enabled"
    [icon]="X_MARK"
    [label]="label"
    [tooltipLabel]="tooltipLabel"
    [tabIndex]="-1"
    [id]="id"
    (clicked)="onClick()"
  ></app-button>
</ng-container>
