import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgForOf } from '@angular/common';
import { ActionBarComponent } from '../../../widgets/action-bar/action-bar.component';
import { DialogBodyComponent } from '../../../widgets/dialog/dialog-body/dialog-body.component';
import { DialogFooterComponent } from '../../../widgets/dialog/dialog-footer/dialog-footer.component';
import { DialogWrapperComponent } from '../../../widgets/dialog/dialog-wrapper/dialog-wrapper.component';
import { IconButtonComponent } from '../../../widgets';
import { CHECK } from '../../../const';
import { AlertComponent } from '../../../widgets/alert/alert.component';

@Component({
  selector: 'app-create-error-dialog',
  templateUrl: './create-error-dialog.component.html',
  imports: [
    ActionBarComponent,
    DialogBodyComponent,
    DialogFooterComponent,
    DialogWrapperComponent,
    IconButtonComponent,
    NgForOf,
    AlertComponent
  ],
  standalone: true
})
export class CreateErrorDialogComponent {
  protected readonly CHECK = CHECK;

  constructor(
    public dialogRef: MatDialogRef<CreateErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      errorMessages: string[];
    },
    public dialog: MatDialog
  ) {}

  confirm(): void {
    this.dialogRef.close(false);
  }
}

export const CREATE_ERROR_DIALOG_TYPE = CreateErrorDialogComponent;
