<app-dialog-wrapper [transparent]="true" dialogTitle="Something went wrong" (closeClicked)="confirm()">
  <app-dialog-body>
    <app-alert message="We are sorry! There was an issue while doing this operation"></app-alert>
  </app-dialog-body>
  <app-dialog-footer>
    <app-action-bar>
      <app-icon-button buttonType="primary" label="Got it!" [icon]="CHECK" (clicked)="confirm()"></app-icon-button>
    </app-action-bar>
  </app-dialog-footer>
</app-dialog-wrapper>
