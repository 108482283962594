import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgForOf, NgIf } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { InputLabelComponent } from '../input-label';
import { SelectOption } from '../types';
import { AsPipe, TranslatedPipe } from '../../../pipes';

@Component({
  selector: 'app-select-input',
  templateUrl: './select-input.component.html',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgIf, MatSelectModule, NgForOf, InputLabelComponent, AsPipe, TranslatedPipe]
})
export class SelectInputComponent<K, V> implements OnInit {
  @Input({ required: true })
  id!: string;
  @Input()
  label = '';
  @Input()
  placeholder = '';
  @Input()
  isRequired = false;
  @Input()
  disabled = false;
  @Input()
  hideLabel = false;
  @Input()
  translate = false;
  @Input()
  selectCss?: string;
  @Input()
  optionCss?: string;

  @Input({ required: true })
  domain!: SelectOption<K, V>[];
  @Input({ required: true })
  value!: K | undefined;
  @Output()
  valueChange = new EventEmitter<K>();

  private emitValueChange() {
    this.valueChange.emit(this.value);
  }

  protected onInputChange(event: Event): void {
    if (typeof this.domain[0]?.key === 'number') {
      this.value = parseInt((event.target as HTMLInputElement).value) as K;
    } else {
      this.value = (event.target as HTMLInputElement).value as K;
    }
    this.emitValueChange();
  }

  protected isSelected(item: SelectOption<K, V>) {
    return item.key?.toString() === this.value?.toString();
  }

  ngOnInit(): void {
    if (!this.value) {
      if (this.domain[0]) {
        this.value = this.domain[0].key;
      }
    }
    this.emitValueChange();
  }
}
