import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { QRCodeModule } from 'angularx-qrcode';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faAngleRight,
  faArrowRight,
  faBars,
  faCartShopping,
  faChair,
  faChartLine,
  faCheck,
  faChevronDown,
  faCircleCheck,
  faCircleExclamation,
  faCircleQuestion,
  faCircleUser,
  faCircleXmark,
  faClock,
  faCopy,
  faDownload,
  faEnvelope,
  faEye,
  faEyeSlash,
  faFaceRollingEyes,
  faFileCsv,
  faFlagCheckered,
  faFloppyDisk,
  faFont,
  faGlobe,
  faKey,
  faMagnifyingGlass,
  faMinus,
  faMoneyBill,
  faPaste,
  faPen,
  faPlus,
  faQrcode,
  faRightFromBracket,
  faRightToBracket,
  faRotateRight,
  faSquare,
  faTicket,
  faTrashCan,
  faTriangleExclamation,
  faUpload,
  faUser,
  faXmark
} from '@fortawesome/free-solid-svg-icons';
import {
  ConfirmRemovalDialogComponent,
  CreateErrorDialogComponent,
  DeleteErrorDialogComponent,
  ForbiddenDialogComponent,
  GenericErrorDialogComponent,
  LoginDialogComponent,
  NotFoundDialogComponent,
  ServerDownDialogComponent,
  ServerErrorDialogComponent,
  UpdateErrorDialogComponent
} from './dialogs';
import {
  ActionBarComponent,
  AlertComponent,
  DialogBodyComponent,
  DialogFooterComponent,
  DialogHeaderComponent,
  DialogWrapperComponent,
  H1Component,
  IconButtonComponent,
  InputLabelComponent,
  LineLayoutComponent,
  LinkComponent,
  RowEntryComponent,
  RowEntryTextComponent,
  RowsContentHeaderComponent,
  RowsContentHeaderTitleComponent,
  RowsContentWrapperComponent,
  RowsNoContentTextComponent,
  SnackBarComponent,
  TextInputComponent
} from './widgets';
import { H2Component } from './widgets/tags/h2/h2.component';
import { ObjectDetailRowComponent } from './widgets/rows-content/object-detail-row/object-detail-row.component';
import { ObjectDetailAddressComponent } from './widgets/rows-content/object-detail-address/object-detail-address.component';
import { FormRowComponent } from './widgets/rows-content/form-row/form-row.component';
import { FormAddressComponent } from './widgets/rows-content/form-address/form-address.component';
import { ErrorContainerComponent } from './widgets/error/error-container/error-container.component';
import { FormRowTwoColumnsComponent } from './widgets/rows-content/form-row-two-columns/form-row-two-columns.component';
import { FormRowFullWidthComponent } from './widgets/rows-content/form-row-full-width/form-row-full-width.component';
import { ObjectImageDetailRowComponent } from './widgets/rows-content/object-image-detail-row/object-image-detail-row.component';
import { LoadingIndicatorComponent } from './widgets/loading-indicator/loading-indicator.component';
import { ObjectHiddenDetailRowComponent } from './widgets/rows-content/object-hidden-detail-row/object-hidden-detail-row.component';
import { QrCodeDialogComponent } from './dialogs/qr-code-dialog/qr-code-dialog.component';
import { SignupConfirmationComponent } from './pages/auth/confirmation/signup-confirmation.component';
import { AuthHeaderComponent } from './widgets/auth-header/auth-header.component';
import { AuthMainPageContentComponent } from './widgets/auth-main-page-content/auth-main-page-content.component';
import { ResendConfirmationLinkComponent } from './pages/auth/resend-confirmation-link/resend-confirmation-link.component';
import { ConfirmationLinkSendComponent } from './pages/auth/resend-confirmation-link/confirmation-link-send/confirmation-link-send.component';
import { SignupEmailConfirmationComponent } from './pages/auth/email-confirmation/signup-email-confirmation.component';
import { ResetPasswordConfirmationComponent } from './pages/auth/reset-password-confirmation/reset-password-confirmation.component';
import { EmailConfirmationFailedComponent } from './pages/auth/email-confirmation/failed/email-confirmation-failed.component';
import { EmailConfirmationSuccessfulComponent } from './pages/auth/email-confirmation/successfull/email-confirmation-successful.component';
import { ChangePasswordConfirmationComponent } from './pages/auth/change-password/confirmation/change-password-confirmation.component';
import { ChangePasswordComponent } from './pages/auth/change-password/change-password.component';
import { ResetPasswordFailedComponent } from './pages/auth/change-password/reset-password-failed/reset-password-failed.component';
import { SuccessContainerComponent } from './widgets/success/success-container/success-container.component';
import { ImprintComponent } from './widgets/legal/imprint/imprint.component';
import { PrivacyStatementComponent } from './widgets/legal/privacy-statement/privacy-statement.component';
import { TermsAndConditionsComponent } from './widgets/legal/terms-and-conditions/terms-and-conditions.component';

@NgModule({
  declarations: [
    RowsContentWrapperComponent,
    RowEntryComponent,
    RowEntryTextComponent,
    RowsContentHeaderComponent,
    RowsNoContentTextComponent,
    RowsContentHeaderTitleComponent,
    H2Component,
    ObjectDetailRowComponent,
    ObjectDetailAddressComponent,
    FormRowComponent,
    FormAddressComponent,
    ErrorContainerComponent,
    SuccessContainerComponent,
    FormRowTwoColumnsComponent,
    FormRowFullWidthComponent,
    ObjectImageDetailRowComponent,
    LoadingIndicatorComponent,
    ObjectHiddenDetailRowComponent,
    QrCodeDialogComponent,
    SignupConfirmationComponent,
    ChangePasswordComponent,
    ChangePasswordConfirmationComponent,
    AuthHeaderComponent,
    AuthMainPageContentComponent,
    ResendConfirmationLinkComponent,
    ConfirmationLinkSendComponent,
    SignupEmailConfirmationComponent,
    ResetPasswordConfirmationComponent,
    EmailConfirmationFailedComponent,
    EmailConfirmationSuccessfulComponent,
    ResetPasswordFailedComponent
  ],
  imports: [
    ActionBarComponent,
    H1Component,
    AlertComponent,
    CommonModule,
    RouterModule,
    MatDialogModule,
    LineLayoutComponent,
    InputLabelComponent,
    TextInputComponent,
    IconButtonComponent,
    SnackBarComponent,
    DialogWrapperComponent,
    DialogHeaderComponent,
    DialogBodyComponent,
    DialogFooterComponent,
    LinkComponent,
    LoginDialogComponent,
    ConfirmRemovalDialogComponent,
    CreateErrorDialogComponent,
    DeleteErrorDialogComponent,
    ForbiddenDialogComponent,
    GenericErrorDialogComponent,
    NotFoundDialogComponent,
    ServerDownDialogComponent,
    ServerErrorDialogComponent,
    UpdateErrorDialogComponent,
    FormsModule,
    QRCodeModule,
    NgOptimizedImage,
    MatProgressSpinnerModule,
    ImprintComponent,
    PrivacyStatementComponent,
    TermsAndConditionsComponent
  ],
  exports: [
    ConfirmRemovalDialogComponent,
    CreateErrorDialogComponent,
    DeleteErrorDialogComponent,
    ForbiddenDialogComponent,
    GenericErrorDialogComponent,
    NotFoundDialogComponent,
    ServerDownDialogComponent,
    ServerErrorDialogComponent,
    UpdateErrorDialogComponent,
    LoginDialogComponent,
    SnackBarComponent,
    ActionBarComponent,
    RowsContentWrapperComponent,
    RowEntryComponent,
    RowEntryTextComponent,
    RowsContentHeaderComponent,
    RowsNoContentTextComponent,
    RowsContentHeaderTitleComponent,
    H2Component,
    ObjectDetailRowComponent,
    ObjectDetailAddressComponent,
    FormRowComponent,
    FormAddressComponent,
    ErrorContainerComponent,
    FormRowTwoColumnsComponent,
    FormRowFullWidthComponent,
    ObjectImageDetailRowComponent,
    LoadingIndicatorComponent,
    ObjectHiddenDetailRowComponent,
    SuccessContainerComponent
  ]
})
export class SharedModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faXmark,
      faRightToBracket,
      faRightFromBracket,
      faCircleUser,
      faKey,
      faTriangleExclamation,
      faCircleExclamation,
      faAngleRight,
      faCheck,
      faTrashCan,
      faPen,
      faCopy,
      faPlus,
      faClock,
      faCircleXmark,
      faCircleCheck,
      faFloppyDisk,
      faEye,
      faFaceRollingEyes,
      faCircleQuestion,
      faUpload,
      faDownload,
      faEnvelope,
      faChevronDown,
      faSquare,
      faChair,
      faMinus,
      faPaste,
      faFont,
      faUser,
      faCartShopping,
      faGlobe,
      faMagnifyingGlass,
      faTicket,
      faBars,
      faArrowRight,
      faMoneyBill,
      faChartLine,
      faFlagCheckered,
      faFileCsv,
      faEyeSlash,
      faQrcode,
      faRotateRight
    );
  }
}
