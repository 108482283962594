import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import { InputLabelComponent } from '../input-label';

@Component({
  selector: 'app-toggle-input',
  standalone: true,
  imports: [FormsModule, InputLabelComponent, NgIf],
  templateUrl: './toggle-input.component.html'
})
export class ToggleInputComponent implements OnInit {
  @Input({ required: true })
  id!: string;
  @Input()
  disabled = false;
  @Input()
  label?: string;

  @Input()
  hideLabel = false;

  @Input({ required: true })
  value!: boolean;
  @Output()
  valueChange = new EventEmitter<boolean>();

  protected onSelectChange() {
    this.valueChange.emit(this.value);
  }

  ngOnInit() {
    if (this.value === undefined) {
      this.value = false;
    }
  }
}
