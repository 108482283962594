export interface Organizer {
  [key: string]: string | number | boolean | undefined;

  id?: number;
  name: string;
  email: string;
  websiteUrl: string;
  language: string;
  street: string;
  streetNumber: string;
  zipCode: string;
  city: string;
  country: string;
  approved: boolean;
  rejected: boolean;
  rejectionReason?: string;
  password: string;
  passwordConfirm: string;
  iban?: string;
  accountOwner?: string;
  imageUrl: string | undefined;
}
