import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { RequestService } from '../../request';
import { ApiUrlService } from '../../api-url';
import { AbstractEvent, Event, EventGroup, Organizer, Price, SearchFields } from '../../../../models';
import { VendorEventPayoutRequest } from '../../../../models/payout/vendor-event-payout-request-model';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  private readonly EVENTS_FOR_USER_URI = `${this.apiUrlService.getPublicApiBasePath()}/abstractevents`;

  constructor(
    private readonly requestService: RequestService,
    private readonly apiUrlService: ApiUrlService
  ) {}

  private getEventsByOrganizerIdPublicUrl(organizerId: number): string {
    return `${this.apiUrlService.getPublicApiBasePath()}/organizers/${organizerId}/events`;
  }

  private copyEventByIdUrl(eventId: number): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/events/${eventId}/copy`;
  }

  private getEventsAndGroupsByOrganizerIdUrl(): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/events`;
  }

  private getLowestPriceForEventUrl(eventGroupId: number) {
    return `${this.apiUrlService.getPublicApiBasePath()}/events/${eventGroupId}/lowestPrice`;
  }

  private getOrganizerForEventUrl(eventId: number) {
    return `${this.apiUrlService.getPublicApiBasePath()}/events/${eventId}/organizer`;
  }

  private getEventsByEventGroupIdPublicUrl(eventGroupId: number): string {
    return `${this.apiUrlService.getPublicApiBasePath()}/events/group/${eventGroupId}`;
  }

  private getAllEventsWithoutGroupUrl(): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/events/groupless`;
  }

  private saveEventUrl(): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/events`;
  }

  private updateEventUrl(): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/events`;
  }

  private generateNewScanningPasswordUrl(eventId: number): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/events/generateNewScanningPassword/${eventId}`;
  }

  private deleteEventUrl(eventId: number): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/events/${eventId}`;
  }

  private getEventByIdUrl(eventId: number): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/events/${eventId}`;
  }

  private getEventByIdPublicUrl(eventId: number): string {
    return `${this.apiUrlService.getPublicApiBasePath()}/events/${eventId}`;
  }

  private getEventsByEventGroupIdUrl(eventGroupId: number): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/eventgroups/${eventGroupId}/events`;
  }

  reloadEventsAndGroups$(): Observable<AbstractEvent[]> {
    return this.requestService.getRequest$<AbstractEvent[]>(this.getEventsAndGroupsByOrganizerIdUrl());
  }

  getEventsByEventGroupId$(eventGroupId: number): Observable<Event[]> {
    return this.requestService.getRequest$<Event[]>(this.getEventsByEventGroupIdUrl(eventGroupId)).pipe(
      map(result => {
        return result;
      })
    );
  }

  getEventsByEventGroupIdPublic$(eventGroupId: number): Observable<Event[]> {
    return this.requestService.getRequest$<Event[]>(this.getEventsByEventGroupIdPublicUrl(eventGroupId)).pipe(
      map(result => {
        return result;
      })
    );
  }

  getEventsByOrganizerIdPublic$(organizerId: number): Observable<AbstractEvent[]> {
    return this.requestService.getRequest$<AbstractEvent[]>(this.getEventsByOrganizerIdPublicUrl(organizerId));
  }

  getAllEventsWithoutGroup$(): Observable<Event[]> {
    return this.requestService.getRequest$<Event[]>(this.getAllEventsWithoutGroupUrl()).pipe(
      map(result => {
        return result;
      })
    );
  }

  saveEvent$(event: Event) {
    return this.requestService.postRequest$<Event, Event>(this.saveEventUrl(), event);
  }

  getEventByIdPublic$(eventId: number): Observable<Event> {
    return this.requestService.getRequest$<Event>(this.getEventByIdPublicUrl(eventId));
  }

  getEventById$(eventId: number): Observable<Event> {
    return this.requestService.getRequest$<Event>(this.getEventByIdUrl(eventId));
  }

  deleteEvent(eventId: number) {
    return this.requestService.deleteRequest$<Observable<boolean>>(this.deleteEventUrl(eventId));
  }

  updateEvent$(event: Event): Observable<Event> {
    return this.requestService.putRequest$<Event, Event>(this.updateEventUrl(), event);
  }

  generateNewScanningPassword$(eventId: number): Observable<Event> {
    return this.requestService.putRequest$<Event, undefined>(this.generateNewScanningPasswordUrl(eventId), undefined);
  }

  publishAllEvents$(eventGroupId: number): Observable<boolean> {
    return this.requestService.putRequest$<boolean, unknown>(
      `${this.apiUrlService.getAdminApiBasePath()}/eventgroups/${eventGroupId}/publish`
    );
  }

  getEventGroupByLink$(link: string): Observable<EventGroup> {
    return this.requestService.getRequest$<EventGroup>(link).pipe(
      map(result => {
        return result;
      })
    );
  }

  copyEvent$(id: number): Observable<Event> {
    return this.requestService.putRequest$<Event, undefined>(this.copyEventByIdUrl(id), undefined);
  }

  getSearchUrlParams(searchFields: SearchFields) {
    searchFields.searchText.replace(/[^a-zA-Z ]/g, '');
    (searchFields.dateFrom ?? '').replace(/[^a-zA-Z ]/g, '');
    (searchFields.dateTo ?? '').replace(/[^a-zA-Z ]/g, '');

    return `?searchText=${searchFields.searchText}&dateFrom=${searchFields.dateFrom ?? ''}&dateTo=${
      searchFields.dateTo ?? ''
    }&searchEvents=${searchFields.searchEvents}&searchEventGroups=${searchFields.searchGroups}&searchLocations=${
      searchFields.searchLocations
    }&searchOrganizers=${searchFields.searchOrganizers}`;
  }

  getEventsForUser(page: number, limit: number, searchFields?: SearchFields) {
    if (searchFields) {
      return this.requestService.getRequest$<AbstractEvent[]>(
        `${this.EVENTS_FOR_USER_URI}/${page}/${limit}${this.getSearchUrlParams(searchFields)}`
      );
    }

    return this.requestService.getRequest$<AbstractEvent[]>(`${this.EVENTS_FOR_USER_URI}/${page}/${limit}`);
  }

  getLowestPriceForEvent$(id: number) {
    return this.requestService.getRequest$<Price>(this.getLowestPriceForEventUrl(id));
  }

  getOrganizerForEvent$(id: number) {
    return this.requestService.getRequest$<Organizer>(this.getOrganizerForEventUrl(id));
  }

  cancelEvent$(id: number) {
    return this.requestService.putRequest$<unknown, unknown>(`${this.apiUrlService.getAdminApiBasePath()}/events/${id}/cancel`, {});
  }

  finishEvent$(data: VendorEventPayoutRequest) {
    return this.requestService.putRequest$<unknown, VendorEventPayoutRequest>(
      `${this.apiUrlService.getAdminApiBasePath()}/events/finish`,
      data
    );
  }
}
