import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { NgIf } from '@angular/common';
import {
  createPasswordStrengthValidator,
  PASSWORD_STRENGTH_VALIDATION_FAILED_KEY
} from '../../../../modules/admin_dashboard/validators/password-requirements-validator';
import { Icon, NO_VALUE_ENTERED_MESSAGE, NOT_A_VALID_EMAIL_MESSAGE, PASSWORD_STRENGTH_VALIDATION_FAILED_MESSAGE } from '../../../const';
import { InputLabelComponent } from '../input-label';
import { IconComponent } from '../../icon';
import { DECIMAL, EMAIL, PASSWORD, TEXT, TextInputType } from './text-input-type';
import { TwoDigitDecimalNumberDirective } from './two-digit-decimal-number.directive';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  standalone: true,
  imports: [NgIf, ReactiveFormsModule, InputLabelComponent, TwoDigitDecimalNumberDirective, IconComponent]
})
export class TextInputComponent<T> implements OnInit {
  protected readonly PASSWORD = PASSWORD;
  protected readonly TEXT = TEXT;
  protected readonly DECIMAL = DECIMAL;

  protected hasError?: boolean = undefined;

  @Input({ required: true })
  id!: string;
  @Input()
  label = '';
  @Input()
  icon?: Icon;
  @Input()
  isRequired = false;
  @Input()
  type: TextInputType = TEXT;
  @Input()
  validatePassword = true;
  @Input()
  placeholder?: string;
  @Input()
  disabled = false;
  @Input()
  errorMessage?: string;
  @Input()
  errorTextColor = 'text-error';
  @Input()
  onErrorOnlyShowIcon = false;
  @Input()
  hideLabel = false;
  @Input()
  labelColor = 'black';
  @Input()
  prefix?: string;
  @Input()
  prefixColor = 'text-black';

  @Input({ required: true })
  value!: T | undefined;
  @Output()
  valueChange = new EventEmitter<T>();

  formControl = new FormControl({ value: this.value, disabled: this.disabled });

  private initFormControl() {
    const validators: ValidatorFn[] = [];
    if (this.isRequired && !this.disabled) {
      validators.push(Validators.required);
    }
    if (this.type === EMAIL) {
      validators.push(Validators.email);
    }
    if (this.type === PASSWORD && this.validatePassword) {
      validators.push(createPasswordStrengthValidator());
    }
    this.formControl = new FormControl(this.value, validators);
  }

  private populateValue() {
    if (this.value) {
      this.formControl.setValue(this.value);
      this.onInputChange();
      if (this.disabled) {
        this.formControl.disable();
      }
    }
  }

  protected getErrorMessage() {
    if (this.errorMessage) {
      return this.errorMessage;
    }
    if (this.formControl.hasError('required')) {
      return NO_VALUE_ENTERED_MESSAGE;
    }
    if (this.formControl.hasError(PASSWORD_STRENGTH_VALIDATION_FAILED_KEY)) {
      return PASSWORD_STRENGTH_VALIDATION_FAILED_MESSAGE;
    }

    return this.formControl.hasError('email') ? NOT_A_VALID_EMAIL_MESSAGE : '';
  }

  protected onInputChange(): void {
    const newValue = !!this.formControl.value && this.formControl.valid ? this.formControl.value : undefined;
    this.hasError = !this.formControl.valid;
    this.valueChange.emit(newValue as T);
  }

  ngOnInit(): void {
    this.initFormControl();
    this.populateValue();

    if (!this.placeholder) {
      this.placeholder = this.label;
    }
  }
}
