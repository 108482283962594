<div class="flex items-center p-4 mb-4 text-sm text-text-error border border-text-error rounded-lg bg-text-error-background" role="alert">
  <ng-container *ngIf="message">
    <app-icon class="w-5 pr-5 mr-2" [icon]="ERROR_CIRCLE"></app-icon>
    <div>
      <span class="font-medium">{{ title }}</span> {{ message }}
    </div>
  </ng-container>

  <ng-container *ngIf="messages">
    <span class="sr-only">Danger</span>
    <app-icon class="w-5 pr-5 mr-2" [icon]="ERROR_CIRCLE"></app-icon>
    <div>
      <span class="font-medium">{{ title }}</span>
      <ul class="mt-1.5 list-disc list-inside">
        <li *ngFor="let message of messages">{{ message }}</li>
      </ul>
    </div>
  </ng-container>
</div>
