<footer
  [classList]="
    'absolute bottom-0 w-full h-[60px] justify-center flex items-center border-t' +
    (darkFooter ? ' bg-black' : ' bg-white') +
    (darkFooter ? ' border-t-white' : ' border-t-black') +
    (darkFooter ? ' text-white' : ' text-black')
  "
>
  <div class="flex gap-4">
    <app-imprint [linkColor]="'white'" />
    <app-terms-and-conditions [linkColor]="'white'" />
    <app-privacy-statement [linkColor]="'white'" />
  </div>
</footer>
