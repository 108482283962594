import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActionBarComponent } from '../../../widgets/action-bar/action-bar.component';
import { DialogBodyComponent } from '../../../widgets/dialog/dialog-body/dialog-body.component';
import { DialogFooterComponent } from '../../../widgets/dialog/dialog-footer/dialog-footer.component';
import { DialogWrapperComponent } from '../../../widgets/dialog/dialog-wrapper/dialog-wrapper.component';
import { IconButtonComponent } from '../../../widgets';
import { CHECK } from '../../../const';

@Component({
  selector: 'app-delete-error-dialog',
  templateUrl: './delete-error-dialog.component.html',
  imports: [ActionBarComponent, DialogBodyComponent, DialogFooterComponent, DialogWrapperComponent, IconButtonComponent],
  standalone: true
})
export class DeleteErrorDialogComponent {
  protected readonly CHECK = CHECK;

  constructor(
    public dialogRef: MatDialogRef<DeleteErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      violatedObjectTable: string;
      objectReferenceTable: string;
    },
    public dialog: MatDialog
  ) {}

  confirm(): void {
    this.dialogRef.close(false);
  }
}

export const DELETE_ERROR_DIALOG_TYPE = DeleteErrorDialogComponent;
