import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { RequestService } from '../../../request/request-service';
import { ApiUrlService } from '../../../api-url/api-url.service';
import { SeatMapObject } from '../../../../../models/layout/seat-map-object-model';
import { AbstractRow } from '../../../../../models/abstract-row-model';
import { PriceConfig } from '../../../../../models/price/price-config-model';
import { Seat } from '../../../../../models/seat-model';

@Injectable({
  providedIn: 'root'
})
export class SeatMapService {
  constructor(
    private readonly requestService: RequestService,
    private readonly apiUrlService: ApiUrlService
  ) {}

  private getSeatMapObjectsBySeatMapAndEventIdPublicUrl(seatMapId: number, eventId: number) {
    return `${this.apiUrlService.getPublicApiBasePath()}/seatmaps/${seatMapId}/objects/${eventId}`;
  }

  private getSeatMapObjectsBySeatMapIdUrl(seatMapId: number) {
    return `${this.apiUrlService.getAdminApiBasePath()}/seatmaps/${seatMapId}/objects`;
  }

  private getRowsBySeatMapSectionIdPublicUrl(sectionId: number): string {
    return `${this.apiUrlService.getPublicApiBasePath()}/seatmapsections/${sectionId}/rows`;
  }

  private getRowsBySeatMapSectionIdUrl(sectionId: number): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/seatmapsections/${sectionId}/rows`;
  }

  private getSeatsBySeatMapSectionIdPublicUrl(sectionId: number): string {
    return `${this.apiUrlService.getPublicApiBasePath()}/seatmapsections/${sectionId}/seats`;
  }

  private getSeatsBySeatMapSectionIdUrl(sectionId: number): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/seatmapsections/${sectionId}/seats`;
  }

  private getPriceConfigBySeatMapSectionIdPublicUrl(seatMapId: number): string {
    return `${this.apiUrlService.getPublicApiBasePath()}/seatmaps/${seatMapId}/priceconfig`;
  }

  private getPriceConfigBySeatMapSectionIdUrl(seatMapId: number): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/seatmaps/${seatMapId}/priceconfig`;
  }

  getSeatMapObjectsBySeatMapIdAndEventIdPublic$(seatMapId: number, eventId: number): Observable<SeatMapObject[]> {
    return this.requestService.getRequest$<SeatMapObject[]>(this.getSeatMapObjectsBySeatMapAndEventIdPublicUrl(seatMapId, eventId)).pipe(
      map(result => {
        return result;
      })
    );
  }

  getSeatMapObjectsBySeatMapId$(seatMapId: number): Observable<SeatMapObject[]> {
    return this.requestService.getRequest$<SeatMapObject[]>(this.getSeatMapObjectsBySeatMapIdUrl(seatMapId)).pipe(
      map(result => {
        return result;
      })
    );
  }

  getRowsBySeatMapSectionIdPublic$(sectionId: number): Observable<AbstractRow[]> {
    return this.requestService.getRequest$<AbstractRow[]>(this.getRowsBySeatMapSectionIdPublicUrl(sectionId)).pipe(
      map(result => {
        return result;
      })
    );
  }

  getRowsBySeatMapSectionId$(sectionId: number): Observable<AbstractRow[]> {
    return this.requestService.getRequest$<AbstractRow[]>(this.getRowsBySeatMapSectionIdUrl(sectionId)).pipe(
      map(result => {
        return result;
      })
    );
  }

  getSeatsBySeatMapSectionIdPublic$(sectionId: number): Observable<Seat[]> {
    return this.requestService.getRequest$<Seat[]>(this.getSeatsBySeatMapSectionIdPublicUrl(sectionId));
  }

  getSeatsBySeatMapSectionId$(sectionId: number): Observable<Seat[]> {
    return this.requestService.getRequest$<Seat[]>(this.getSeatsBySeatMapSectionIdUrl(sectionId));
  }

  getPriceConfigForSeatMapSectionPublic$(sectionId: number): Observable<PriceConfig> {
    return this.requestService.getRequest$<PriceConfig>(this.getPriceConfigBySeatMapSectionIdPublicUrl(sectionId));
  }

  getPriceConfigForSeatMapSection$(sectionId: number): Observable<PriceConfig> {
    return this.requestService.getRequest$<PriceConfig>(this.getPriceConfigBySeatMapSectionIdUrl(sectionId));
  }
}
