import { AfterViewInit, Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgForOf } from '@angular/common';
import { AVAILABLE_LANGUAGES, getLanguageByKey, LANGUAGE } from '../../const';
import { SelectInputComponent } from '../form-elements';
import { LanguageService, NavigationService } from '../../services';
import { IconComponent } from '../icon';

@Component({
  selector: 'app-locale-select',
  templateUrl: './locale-select.component.html',
  imports: [SelectInputComponent, FormsModule, NgForOf, IconComponent],
  standalone: true
})
export class LocaleSelectComponent implements AfterViewInit {
  protected readonly LANGUAGE = LANGUAGE;
  protected readonly AVAILABLE_LANGUAGES = AVAILABLE_LANGUAGES;

  selectedLanguage: string;

  constructor(
    private readonly languageService: LanguageService,
    private readonly navigationService: NavigationService
  ) {
    this.selectedLanguage = this.languageService.getCurrentLanguageKey();
  }

  protected onLanguageChange() {
    const language = getLanguageByKey(this.selectedLanguage);
    const currentLanguage = this.languageService.getCurrentLanguage();
    if (language !== currentLanguage) {
      this.languageService.changeLanguage(language);
    }
  }

  ngAfterViewInit(): void {
    const currentUrl = this.navigationService.getCurrentUrl();
    if (currentUrl) {
      // @ts-expect-error: We should always get a correct route here however it will be provided as a string
      this.navigationService.navigate(currentUrl);
    }
  }
}
