import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService, EMAIL, NavigationService, NavigationUrlService } from '../../../index';
import { ADMIN_DASHBOARD_SIGNUP_RESEND_CONFIRMATION_SUCCESS_ROUTE } from '../../../const/routes/admin-dashboard-routes';
import { WEBSITE_SIGNUP_RESEND_CONFIRMATION_SUCCESS_ROUTE } from '../../../const/routes/website-routes';

@Component({
  selector: 'app-resend-confirmation-link',
  templateUrl: './resend-confirmation-link.component.html'
})
export class ResendConfirmationLinkComponent implements OnInit {
  protected readonly EMAIL = EMAIL;

  email?: string;
  formValid = false;

  showActiveError = false;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly navigationService: NavigationService,
    private readonly navigationUrlService: NavigationUrlService
  ) {}

  onEmailChange() {
    this.formValid = !!this.email;
  }

  onResendConfirmationLink() {
    if (this.email) {
      const path = this.navigationUrlService.isAdminModule()
        ? ADMIN_DASHBOARD_SIGNUP_RESEND_CONFIRMATION_SUCCESS_ROUTE
        : WEBSITE_SIGNUP_RESEND_CONFIRMATION_SUCCESS_ROUTE;

      this.authService.resendConfirmationLink$(this.email).subscribe({
        next: () => {
          if (!this.email) {
            return;
          }
          this.navigationService.navigate(path, [this.email]);
        },
        error: () => {
          if (!this.email) {
            return;
          }
          this.navigationService.navigate(path, [this.email]);
        }
      });
    }
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params.showActiveError) {
        this.showActiveError = true;
      }
    });
    const emailParam = this.route.snapshot.paramMap.get('email');
    if (emailParam) {
      this.email = emailParam;
      this.onEmailChange();
    }
  }
}
