import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    if (params.key.startsWith('ROUTES.')) {
      return params.key;
    }

    return `??${params.key}??`;
  }
}
