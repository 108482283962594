<label [for]="id" class="font-bold block mb-2 text-md">{{ label }}</label>

<textarea
  [id]="id"
  [(ngModel)]="value"
  (ngModelChange)="onInputChange()"
  [disabled]="disabled"
  [placeholder]="placeholder"
  rows="10"
  class="mb-4 block p-2.5 w-full text-sm border border-button-blue-darkest rounded-lg"
></textarea>
