export const ADMIN_DASHBOARD_BASE_ROUTE = '/admin';
export const ADMIN_DASHBOARD_LOGIN_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/login`;
export const ADMIN_DASHBOARD_SIGNUP_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/signup`;
export const ADMIN_DASHBOARD_RESET_PASSWORD_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/reset-password`;
export const ADMIN_DASHBOARD_RESET_PASSWORD_CONFIRMATION_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/reset-password/confirmation/:email`;
export const ADMIN_DASHBOARD_CHANGE_PASSWORD_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/change-password`;
export const ADMIN_DASHBOARD_CHANGE_PASSWORD_CONFIRMATION_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/change-password/confirmation`;
export const ADMIN_DASHBOARD_CHANGE_PASSWORD_FAILED_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/change-password/failed`;
export const ADMIN_DASHBOARD_SIGNUP_SUCCESSFUL_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/signup/successful/:email`;
export const ADMIN_DASHBOARD_SIGNUP_RESEND_CONFIRMATION_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/signup/confirmation/resend`;
export const ADMIN_DASHBOARD_SIGNUP_RESEND_CONFIRMATION_WITH_EMAIL_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/signup/confirmation/resend/:email`;
export const ADMIN_DASHBOARD_SIGNUP_RESEND_CONFIRMATION_SUCCESS_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/signup/confirmation/resend/confirmation/:email`;
export const ADMIN_DASHBOARD_EMAIL_CONFIRMATION_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/signup/email-confirmation`;
export const ADMIN_DASHBOARD_EMAIL_CONFIRMATION_FAILED_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/signup/email-confirmation/failed`;
export const ADMIN_DASHBOARD_EMAIL_CONFIRMATION_SUCCESSFUL_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/signup/email-confirmation/successful`;
export const ADMIN_DASHBOARD_EVENTS_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/events`;
export const ADMIN_DASHBOARD_LOCATIONS_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/locations`;
export const ADMIN_DASHBOARD_LAYOUTS_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/layouts`;
export const ADMIN_DASHBOARD_PRICES_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/prices`;
export const ADMIN_DASHBOARD_EVENT_STATISTICS_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/statistics/event/:id`;
export const ADMIN_DASHBOARD_EVENT_GROUP_STATISTICS_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/statistics/eventGroup/:id`;
export const ADMIN_DASHBOARD_STATISTICS_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/statistics`;
export const ADMIN_DASHBOARD_USERS_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/users`;
export const ADMIN_DASHBOARD_CREATE_EVENT_GROUP_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/event-group/create`;
export const ADMIN_DASHBOARD_EDIT_EVENT_GROUP_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/event-group/edit/:id`;
export const ADMIN_DASHBOARD_EVENT_GROUP_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/event-group/:id`;
export const ADMIN_DASHBOARD_CREATE_EVENT_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/event/create`;
export const ADMIN_DASHBOARD_CREATE_EVENT_FOR_GROUP_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/event/create/:eventGroupId`;
export const ADMIN_DASHBOARD_EDIT_EVENT_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/event/edit/:id`;
export const ADMIN_DASHBOARD_EVENT_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/event/:id`;
export const ADMIN_DASHBOARD_CREATE_LOCATION_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/location/create`;
export const ADMIN_DASHBOARD_EDIT_LOCATION_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/location/edit/:id`;
export const ADMIN_DASHBOARD_LOCATION_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/location/:id`;
export const ADMIN_DASHBOARD_CREATE_LAYOUT_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/layout/create`;
export const ADMIN_DASHBOARD_EDIT_SIMPLE_SECTION_CONFIG_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/simplesectionconfig/edit/:id`;
export const ADMIN_DASHBOARD_SIMPLE_SECTION_CONFIG_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/simplesectionconfig/:id`;
export const ADMIN_DASHBOARD_EDIT_SEATMAP_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/seatmap/edit/:id`;
export const ADMIN_DASHBOARD_SEATMAP_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/seatmap/:id`;
export const ADMIN_DASHBOARD_CREATE_PRICE_CONFIG_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/price-config/create`;
export const ADMIN_DASHBOARD_EDIT_PRICE_CONFIG_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/price-config/edit/:id`;
export const ADMIN_DASHBOARD_PRICE_CONFIG_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/price-config/:id`;
export const ADMIN_DASHBOARD_CREATE_USER_INVITATION_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/users/invitation/create`;
export const ADMIN_DASHBOARD_EDIT_USER_INVITATION_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/users/invitation/edit/:id`;
export const ADMIN_DASHBOARD_ORGANIZER_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/organizer`;
export const ADMIN_DASHBOARD_INVITATION_CONFIRMATION_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/invitation/confirmation`;
export const ADMIN_DASHBOARD_INVITATION_CONFIRMATION_SUCCESS_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/invitation/confirmation/successful`;
export const ADMIN_DASHBOARD_EDIT_ORGANIZER_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/organizer/edit`;
export const ADMIN_DASHBOARD_PAYOUTS_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/payouts`;
export const ADMIN_DASHBOARD_BILLS_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/bills`;
export const ADMIN_DASHBOARD_EDIT_PROFILE_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/profile/edit`;
export const ADMIN_DASHBOARD_PROFILE_ROUTE = `${ADMIN_DASHBOARD_BASE_ROUTE}/profile`;

export type AdminDashboardRoute =
  | typeof ADMIN_DASHBOARD_BASE_ROUTE
  | typeof ADMIN_DASHBOARD_LOGIN_ROUTE
  | typeof ADMIN_DASHBOARD_SIGNUP_ROUTE
  | typeof ADMIN_DASHBOARD_RESET_PASSWORD_ROUTE
  | typeof ADMIN_DASHBOARD_RESET_PASSWORD_CONFIRMATION_ROUTE
  | typeof ADMIN_DASHBOARD_CHANGE_PASSWORD_ROUTE
  | typeof ADMIN_DASHBOARD_CHANGE_PASSWORD_CONFIRMATION_ROUTE
  | typeof ADMIN_DASHBOARD_CHANGE_PASSWORD_FAILED_ROUTE
  | typeof ADMIN_DASHBOARD_SIGNUP_SUCCESSFUL_ROUTE
  | typeof ADMIN_DASHBOARD_SIGNUP_RESEND_CONFIRMATION_ROUTE
  | typeof ADMIN_DASHBOARD_SIGNUP_RESEND_CONFIRMATION_WITH_EMAIL_ROUTE
  | typeof ADMIN_DASHBOARD_SIGNUP_RESEND_CONFIRMATION_SUCCESS_ROUTE
  | typeof ADMIN_DASHBOARD_EMAIL_CONFIRMATION_ROUTE
  | typeof ADMIN_DASHBOARD_EMAIL_CONFIRMATION_FAILED_ROUTE
  | typeof ADMIN_DASHBOARD_EMAIL_CONFIRMATION_SUCCESSFUL_ROUTE
  | typeof ADMIN_DASHBOARD_EVENTS_ROUTE
  | typeof ADMIN_DASHBOARD_LOCATIONS_ROUTE
  | typeof ADMIN_DASHBOARD_LAYOUTS_ROUTE
  | typeof ADMIN_DASHBOARD_PRICES_ROUTE
  | typeof ADMIN_DASHBOARD_EVENT_STATISTICS_ROUTE
  | typeof ADMIN_DASHBOARD_EVENT_GROUP_STATISTICS_ROUTE
  | typeof ADMIN_DASHBOARD_STATISTICS_ROUTE
  | typeof ADMIN_DASHBOARD_USERS_ROUTE
  | typeof ADMIN_DASHBOARD_CREATE_EVENT_GROUP_ROUTE
  | typeof ADMIN_DASHBOARD_EDIT_EVENT_GROUP_ROUTE
  | typeof ADMIN_DASHBOARD_EVENT_GROUP_ROUTE
  | typeof ADMIN_DASHBOARD_CREATE_EVENT_ROUTE
  | typeof ADMIN_DASHBOARD_CREATE_EVENT_FOR_GROUP_ROUTE
  | typeof ADMIN_DASHBOARD_EDIT_EVENT_ROUTE
  | typeof ADMIN_DASHBOARD_EVENT_ROUTE
  | typeof ADMIN_DASHBOARD_CREATE_LOCATION_ROUTE
  | typeof ADMIN_DASHBOARD_EDIT_LOCATION_ROUTE
  | typeof ADMIN_DASHBOARD_LOCATION_ROUTE
  | typeof ADMIN_DASHBOARD_CREATE_LAYOUT_ROUTE
  | typeof ADMIN_DASHBOARD_EDIT_SIMPLE_SECTION_CONFIG_ROUTE
  | typeof ADMIN_DASHBOARD_SIMPLE_SECTION_CONFIG_ROUTE
  | typeof ADMIN_DASHBOARD_EDIT_SEATMAP_ROUTE
  | typeof ADMIN_DASHBOARD_SEATMAP_ROUTE
  | typeof ADMIN_DASHBOARD_CREATE_PRICE_CONFIG_ROUTE
  | typeof ADMIN_DASHBOARD_EDIT_PRICE_CONFIG_ROUTE
  | typeof ADMIN_DASHBOARD_PRICE_CONFIG_ROUTE
  | typeof ADMIN_DASHBOARD_CREATE_USER_INVITATION_ROUTE
  | typeof ADMIN_DASHBOARD_EDIT_USER_INVITATION_ROUTE
  | typeof ADMIN_DASHBOARD_ORGANIZER_ROUTE
  | typeof ADMIN_DASHBOARD_INVITATION_CONFIRMATION_ROUTE
  | typeof ADMIN_DASHBOARD_INVITATION_CONFIRMATION_SUCCESS_ROUTE
  | typeof ADMIN_DASHBOARD_EDIT_ORGANIZER_ROUTE
  | typeof ADMIN_DASHBOARD_PAYOUTS_ROUTE
  | typeof ADMIN_DASHBOARD_BILLS_ROUTE
  | typeof ADMIN_DASHBOARD_EDIT_PROFILE_ROUTE
  | typeof ADMIN_DASHBOARD_PROFILE_ROUTE;
