import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/overlay';
import { map, Observable } from 'rxjs';
import { DIALOG_WIDTH_DEFAULT } from '../../const';

@Injectable({
  providedIn: 'root'
})
export class DialogHelperService {
  isDialogOpened = false;

  constructor(public dialog: MatDialog) {}

  openDialog$<ReturnType>(
    dialogType: ComponentType<unknown>,
    dialogData: unknown,
    disableClose?: boolean,
    customConfig?: NonNullable<unknown>
  ): Observable<ReturnType> | undefined {
    if (this.isDialogOpened) {
      //Do not open multiple dialogs
      return;
    }

    this.isDialogOpened = true;

    let config: MatDialogConfig<unknown> = {
      width: DIALOG_WIDTH_DEFAULT,
      data: dialogData,
      disableClose: disableClose!
    };

    if (customConfig) {
      config = { ...config, ...customConfig };
    }

    const dialogRef = this.dialog.open<unknown>(dialogType, config);

    return dialogRef.afterClosed().pipe(
      map((r: ReturnType) => {
        this.isDialogOpened = false;

        return r;
      })
    );
  }
}
