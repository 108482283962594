<div class="fixed top-0 bottom-0 left-0 right-0 w-full h-full p-0 m-0"></div>
<div class="absolute bottom-10">
  <ng-container *ngIf="type === SNACKBAR_TYPES.SUCCESS">
    <div id="toast-success" class="flex items-center w-full max-w-xs p-4 text-snackbar-text bg-white rounded-lg shadow" role="alert">
      <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-snackbar-green bg-snackbar-green-light rounded-lg">
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path
            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"
          />
        </svg>
        <span class="sr-only">Check icon</span>
      </div>
      <div class="ms-3 text-sm font-normal">{{ data.message }}</div>
      <button
        (click)="snackBarRef.dismissWithAction()"
        type="button"
        class="ms-auto -mx-1.5 -my-1.5 bg-white text-snackbar-button-gray-medium hover:text-snackbar-button-gray-dark rounded-lg focus:ring-2 focus:ring-snackbar-button-gray-light p-1.5 hover:bg-snackbar-button-gray-lightest inline-flex items-center justify-center h-8 w-8"
        data-dismiss-target="#toast-success"
        aria-label="Close"
      >
        <span class="sr-only">Close</span>
        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
          />
        </svg>
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="type === SNACKBAR_TYPES.ERROR">
    <div id="toast-danger" class="flex items-center w-full max-w-xs p-4 mb-4 text-snackbar-text bg-white rounded-lg shadow" role="alert">
      <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-snackbar-red bg-snackbar-red-light rounded-lg">
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path
            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z"
          />
        </svg>
        <span class="sr-only">Error icon</span>
      </div>
      <div class="ms-3 text-sm font-normal">{{ data.message }}</div>
      <button
        (click)="snackBarRef.dismissWithAction()"
        type="button"
        class="ms-auto -mx-1.5 -my-1.5 bg-white text-snackbar-button-gray-medium hover:text-snackbar-button-gray-dark rounded-lg focus:ring-2 focus:ring-snackbar-button-gray-light p-1.5 hover:bg-snackbar-button-gray-lightest inline-flex items-center justify-center h-8 w-8"
        data-dismiss-target="#toast-danger"
        aria-label="Close"
      >
        <span class="sr-only">Close</span>
        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
          />
        </svg>
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="type === SNACKBAR_TYPES.INFO">
    <div id="toast-default" class="flex items-center w-full max-w-xs p-4 text-snackbar-text 0 bg-white rounded-lg shadow" role="alert">
      <div
        class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-snackbar-blue bg-snackbar-button-gray-lightest rounded-lg"
      >
        <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M8 3.464V1.1m0 2.365a5.338 5.338 0 0 1 5.133 5.368v1.8c0 2.386 1.867 2.982 1.867 4.175C15 15.4 15 16 14.462 16H1.538C1 16 1 15.4 1 14.807c0-1.193 1.867-1.789 1.867-4.175v-1.8A5.338 5.338 0 0 1 8 3.464ZM4.54 16a3.48 3.48 0 0 0 6.92 0H4.54Z"
          />
        </svg>
        <span class="sr-only">Fire icon</span>
      </div>
      <div class="ms-3 text-sm font-normal">{{ data.message }}</div>
      <button
        (click)="snackBarRef.dismissWithAction()"
        type="button"
        class="ms-auto -mx-1.5 -my-1.5 bg-white text-snackbar-button-gray-medium hover:text-snackbar-button-gray-dark rounded-lg focus:ring-2 focus:ring-snackbar-button-gray-light p-1.5 hover:bg-snackbar-button-gray-lightest inline-flex items-center justify-center h-8 w-8"
        data-dismiss-target="#toast-default"
        aria-label="Close"
      >
        <span class="sr-only">Close</span>
        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
          />
        </svg>
      </button>
    </div>
  </ng-container>
</div>
