<nav class="bg-header-background">
  <div class="mx-auto max-w-7xl px-2">
    <div class="relative flex h-16 items-center justify-between">
      <div class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
        <div class="flex flex-shrink-0 items-center">
          <img
            (click)="navigate($event, homeRoute)"
            (keydown.enter)="navigate($event, homeRoute)"
            (keydown.space)="navigate($event, homeRoute)"
            alt="Logo"
            ngSrc="assets/images/logo_white.png"
            height="973"
            width="3163"
            class="h-10 w-auto hover:cursor-pointer"
            tabindex="0"
          />
        </div>
      </div>
      <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
        <a
          href="#"
          (click)="navigate($event, loginRoute)"
          (keydown.enter)="navigate($event, loginRoute)"
          (keydown.space)="navigate($event, loginRoute)"
          class="text-white rounded-md px-3 py-2 text-lg font-medium text-header-text-color"
          tabindex="0"
        >
          Login
        </a>
      </div>
    </div>
  </div>
</nav>
