import { Injectable } from '@angular/core';
import { filter, map, Observable, of, switchMap } from 'rxjs';
import { RequestService } from '../../request';
import { ApiUrlService } from '../../api-url';
import { NavigationService } from '../../../navigation';
import { Layout, SeatMap, SimpleSectionConfig } from '../../../../models';
import { DialogHelperService } from '../../../dialog';
import { DIALOG_WIDTH_DEFAULT } from '../../../../const';
import { SnackBarService } from '../../../snackbar';
import { SNACKBAR_TYPES } from '../../../../widgets';
import { CONFIRM_REMOVAL_DIALOG_TYPE } from '../../../../dialogs';
import { ADMIN_DASHBOARD_LAYOUTS_ROUTE } from '../../../../const/routes/admin-dashboard-routes';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  constructor(
    private readonly requestService: RequestService,
    private readonly apiUrlService: ApiUrlService,
    private readonly navigation: NavigationService,
    private readonly dialogHelperService: DialogHelperService,
    private readonly snackBar: SnackBarService
  ) {}

  private getLayoutTypeByLayoutIdUrl(layoutId: number): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/layouts/type/${layoutId}`;
  }

  private saveSimpleSectionConfigUrl(): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/simplesectionconfigs`;
  }

  private copySimpleSectionConfigUrl(simpleSectionConfigId: number): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/simplesectionconfigs/${simpleSectionConfigId}/copy`;
  }

  private copySeatMapUrlUrl(seatMapId: number): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/seatmaps/${seatMapId}/copy`;
  }

  private saveSeatMapUrl(): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/seatmaps`;
  }

  private deleteLayoutUrl(layoutId: number): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/layouts/${layoutId}`;
  }

  private getLayoutsUrl(): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/layouts`;
  }

  getLayoutByIdUrl(layoutId: number): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/layouts/${layoutId}`;
  }

  getLayouts$(): Observable<Layout[]> {
    return this.requestService.getRequest$<Layout[]>(this.getLayoutsUrl());
  }

  saveSimpleSectionConfig(simpleSectionConfig: SimpleSectionConfig) {
    return this.requestService.postRequest$<SimpleSectionConfig, SimpleSectionConfig>(
      this.saveSimpleSectionConfigUrl(),
      simpleSectionConfig
    );
  }

  updateSimpleSectionConfig(simpleSectionConfig: SimpleSectionConfig) {
    return this.requestService.putRequest$<SimpleSectionConfig, SimpleSectionConfig>(
      this.saveSimpleSectionConfigUrl(),
      simpleSectionConfig
    );
  }

  saveSeatMap$(seatMap: SeatMap): Observable<SeatMap> {
    return this.requestService.postRequest$<SeatMap, SeatMap>(this.saveSeatMapUrl(), seatMap);
  }

  updateSeatMap$(seatMap: SeatMap): Observable<SeatMap> {
    return this.requestService.putRequest$<SeatMap, SeatMap>(this.saveSeatMapUrl(), seatMap);
  }

  copySimpleSection$(simpleSectionConfigId: number): Observable<SimpleSectionConfig> {
    return this.requestService.putRequest$<SimpleSectionConfig, undefined>(
      this.copySimpleSectionConfigUrl(simpleSectionConfigId),
      undefined
    );
  }

  copySeatMap$(seatMapId: number): Observable<SeatMap> {
    return this.requestService.putRequest$<SeatMap, undefined>(this.copySeatMapUrlUrl(seatMapId), undefined);
  }

  deleteLayout(layoutId: number): void {
    const dialog$ = this.dialogHelperService.openDialog$<boolean>(CONFIRM_REMOVAL_DIALOG_TYPE, DIALOG_WIDTH_DEFAULT, undefined);

    if (dialog$) {
      dialog$
        .pipe(
          switchMap(result => {
            if (result) {
              return this.requestService.deleteRequest$<boolean>(this.deleteLayoutUrl(layoutId));
            }

            return of(false);
          }),
          filter(result => result)
        )
        .subscribe(() => {
          this.snackBar.showSnackbarMessage('Layout has been deleted.', SNACKBAR_TYPES.SUCCESS);
          this.navigation.navigate(ADMIN_DASHBOARD_LAYOUTS_ROUTE);
        });
    }
  }

  getLayoutById$(layoutId: number): Observable<Layout> {
    return this.requestService.getRequest$<Layout>(this.getLayoutByIdUrl(layoutId)).pipe(
      map(result => {
        return result;
      })
    );
  }

  getLayoutTypeByLayoutId$(layoutId: number): Observable<string> {
    return this.requestService.getRequest$<string>(this.getLayoutTypeByLayoutIdUrl(layoutId)).pipe(
      map(result => {
        return result;
      })
    );
  }
}
