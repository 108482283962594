<app-dialog-wrapper [transparent]="true" dialogTitle="Delete" (closeClicked)="cancel()">
  <app-dialog-body>
    <p>Are you sure you want to delete this object?</p>
    <b>This can not be undone!</b>
  </app-dialog-body>
  <app-dialog-footer>
    <app-action-bar>
      <app-icon-button buttonType="cancel" label="Cancel" [icon]="X_MARK" (clicked)="cancel()"></app-icon-button>
      <app-icon-button buttonType="primary" label="Confirm" [icon]="CHECK" (clicked)="confirm()"></app-icon-button>
    </app-action-bar>
  </app-dialog-footer>
</app-dialog-wrapper>
