import { Component } from '@angular/core';
import { NavigationService, NavigationUrlService } from '../../../../index';
import { ADMIN_DASHBOARD_LOGIN_ROUTE } from '../../../../const/routes/admin-dashboard-routes';
import { WEBSITE_LOGIN_ROUTE } from '../../../../const/routes/website-routes';

@Component({
  selector: 'app-change-password-confirmation',
  templateUrl: './change-password-confirmation.component.html'
})
export class ChangePasswordConfirmationComponent {
  constructor(
    private readonly navigationService: NavigationService,
    private readonly navigationUrlService: NavigationUrlService
  ) {}

  onGoToLogin() {
    if (this.navigationUrlService.isAdminModule()) {
      this.navigationService.navigate(ADMIN_DASHBOARD_LOGIN_ROUTE);
    } else {
      this.navigationService.navigate(WEBSITE_LOGIN_ROUTE);
    }
  }
}
