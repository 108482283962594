import { Component, Input } from '@angular/core';
import { DomainHelperService } from '../../../services/domain/domain-helper-service';
import { LanguageService } from '../../../services';

@Component({
  selector: 'app-privacy-statement',
  standalone: true,
  imports: [],
  templateUrl: './privacy-statement.component.html'
})
export class PrivacyStatementComponent {
  @Input()
  linkColor = 'black';

  @Input()
  textDecoration = 'no-underline';

  constructor(
    private readonly domainHelperService: DomainHelperService,
    private readonly languageService: LanguageService
  ) {}

  protected getPrivacyStatementLink() {
    if (this.domainHelperService.isCHDomain()) {
      if (this.languageService.getCurrentLanguageKey().toLowerCase() === 'en') {
        return 'https://www.innovaticks.ch/privacy-statement.html';
      }

      return `https://www.innovaticks.ch/${this.languageService.getCurrentLanguageKey().toLowerCase()}/privacy-statement.html`;
    }

    if (this.languageService.getCurrentLanguageKey().toLowerCase() === 'en') {
      return 'https://www.innovaticks.org/privacy-statement.html';
    }

    return `https://www.innovaticks.org/${this.languageService.getCurrentLanguageKey().toLowerCase()}/privacy-statement.html`;
  }
}
