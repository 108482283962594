<button
  [title]="tooltipLabel"
  data-tooltip-target
  [id]="id"
  [type]="type"
  [class]="cssClass"
  [disabled]="!enabled"
  [tabIndex]="tabIndex"
  (click)="onClick()"
>
  <app-icon *ngIf="icon" [icon]="icon"></app-icon>
  <span *ngIf="label">{{ label }}</span>
</button>
