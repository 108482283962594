import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from '../../widgets';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {
  static readonly THOUSAND = 1000;
  static readonly DEFAULT_DURATION = 5;
  static readonly DEFAULT_BUTTON_LABEL = 'Dismiss';

  constructor(private readonly _snackBar: MatSnackBar) {}

  showSnackbarMessage(message: string, type: string, buttonLabel?: string, duration?: number) {
    const durationSec = duration ? duration : SnackBarService.DEFAULT_DURATION;
    const button = buttonLabel ? buttonLabel : SnackBarService.DEFAULT_BUTTON_LABEL;

    this._snackBar.openFromComponent(SnackBarComponent, {
      duration: durationSec * SnackBarService.THOUSAND,
      data: { type: type, message: message, buttonLabel: button }
    });
  }
}
