<app-dialog-wrapper [widthPx]="DIALOG_WIDTH_DEFAULT" dialogTitle="Scan QR Code" (closeClicked)="closeDialog()" [transparent]="true">
  <app-dialog-body>
    <div class="w-full flex justify-center">
      <qrcode [qrdata]="data.content" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
    </div>
  </app-dialog-body>
  <app-dialog-footer>
    <app-action-bar>
      <app-icon-button buttonType="primary" label="Close" [icon]="X_MARK" (clicked)="closeDialog()"></app-icon-button>
    </app-action-bar>
  </app-dialog-footer>
</app-dialog-wrapper>
