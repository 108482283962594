import { Seat } from '../seat-model';
import { Row } from '../row-model';
import { PriceConfig } from '../price';

export interface SimpleSection {
  id?: number;
  name: string;
  freeSeating: boolean;
  priceConfigId?: number;
  priceConfig?: PriceConfig;
  rows?: Row[];
  seats?: Seat[];
  availableSeats?: number;
}
