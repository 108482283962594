import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MissingTranslationHandler, TranslateLoader, TranslateModuleConfig } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { MyMissingTranslationHandler } from './missing-translation.handler';

export function translateLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, `${environment.appUrl}assets/i18n/`, '.json');
}

export const TRANSLATE_MODULE_CONFIG: TranslateModuleConfig = {
  defaultLanguage: 'en',
  useDefaultLang: true,
  missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler },
  loader: {
    provide: TranslateLoader,
    useFactory: translateLoaderFactory,
    deps: [HttpClient]
  }
};
