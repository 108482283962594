export interface Location {
  id?: number;
  name: string;
  internalName: string;
  street: string;
  streetNumber: string;
  zipCode: string;
  city: string;
  country: string;
  layout?: number | undefined;
}
