export const X_MARK = 'xmark';
export const LOGIN = 'right-to-bracket';
export const LOGOUT = 'right-from-bracket';
export const USER = 'circle-user';
export const KEY = 'key';
export const ERROR_TRIANGLE = 'triangle-exclamation';
export const ERROR_CIRCLE = 'circle-exclamation';
export const ANGLE_RIGHT = 'angle-right';
export const CHECK = 'check';
export const COPY = 'copy';
export const EDIT = 'pen';
export const DELETE = 'trash-can';
export const ADD = 'plus';
export const APPROVED = 'circle-check';
export const REJECTED = 'circle-xmark';
export const PENDING = 'clock';
export const SAVE = 'floppy-disk';
export const SHOW = 'eye';
export const ROLLING_EYES = 'face-rolling-eyes';
export const HELP = 'circle-question';
export const PUBLISH = 'upload';
export const CONCEAL = 'download';
export const SEND = 'envelope';
export const DOWN = 'chevron-down';
export const SECTION = 'chair';
export const SQUARE = 'square';
export const LINE = 'minus';
export const TEXT_ICON = 'font';
export const PASTE = 'paste';
export const PROFILE = 'user';
export const CART = 'cart-shopping';
export const LANGUAGE = 'globe';
export const SEARCH = 'magnifying-glass';
export const TICKET = 'ticket';
export const HAMBURGER = 'bars';
export const NEXT = 'arrow-right';
export const MONEY = 'money-bill';
export const CHART = 'chart-line';
export const FINISH = 'flag-checkered';
export const CSV = 'file-csv';
export const HIDE = 'eye-slash';
export const QR_CODE = 'qrcode';
export const RELOAD = 'rotate-right';

export type Icon =
  | typeof X_MARK
  | typeof LOGIN
  | typeof LOGOUT
  | typeof USER
  | typeof KEY
  | typeof ERROR_TRIANGLE
  | typeof ERROR_CIRCLE
  | typeof ANGLE_RIGHT
  | typeof CHECK
  | typeof COPY
  | typeof EDIT
  | typeof DELETE
  | typeof ADD
  | typeof APPROVED
  | typeof REJECTED
  | typeof PENDING
  | typeof SAVE
  | typeof SHOW
  | typeof ROLLING_EYES
  | typeof PUBLISH
  | typeof CONCEAL
  | typeof HELP
  | typeof SEND
  | typeof DOWN
  | typeof SQUARE
  | typeof SECTION
  | typeof LINE
  | typeof TEXT_ICON
  | typeof PASTE
  | typeof CART
  | typeof LANGUAGE
  | typeof SEARCH
  | typeof TICKET
  | typeof HAMBURGER
  | typeof NEXT
  | typeof MONEY
  | typeof CHART
  | typeof FINISH
  | typeof CSV
  | typeof HIDE
  | typeof QR_CODE
  | typeof RELOAD;
