import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgForOf, NgIf } from '@angular/common';
import {
  ActionBarComponent,
  AlertComponent,
  DialogBodyComponent,
  DialogFooterComponent,
  DialogWrapperComponent,
  IconButtonComponent
} from '../../../widgets';
import { CHECK, DIALOG_WIDTH_MEDIUM } from '../../../const';

@Component({
  selector: 'app-update-error-dialog',
  templateUrl: './update-error-dialog.component.html',
  imports: [
    ActionBarComponent,
    DialogBodyComponent,
    DialogFooterComponent,
    DialogWrapperComponent,
    IconButtonComponent,
    NgForOf,
    AlertComponent,
    NgIf
  ],
  standalone: true
})
export class UpdateErrorDialogComponent {
  protected readonly CHECK = CHECK;
  protected readonly DIALOG_WIDTH_MEDIUM = DIALOG_WIDTH_MEDIUM;

  constructor(
    public dialogRef: MatDialogRef<UpdateErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      errorMessages: string[];
    },
    public dialog: MatDialog
  ) {}

  confirm(): void {
    this.dialogRef.close(false);
  }
}

export const UPDATE_ERROR_DIALOG = UpdateErrorDialogComponent;
