import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Seat } from '../../../../models';
import { RequestService } from '../../request';
import { ApiUrlService } from '../../api-url';

@Injectable({
  providedIn: 'root'
})
export class SeatService {
  constructor(
    private readonly requestService: RequestService,
    private readonly apiUrlService: ApiUrlService
  ) {}

  private getSeatsByRowIdPublicUrl(rowId: number): string {
    return `${this.apiUrlService.getPublicApiBasePath()}/rows/${rowId}/seats`;
  }

  private getSeatsByRowIdWithAvailabilityPublicUrl(rowId: number, eventId: number): string {
    return `${this.apiUrlService.getPublicApiBasePath()}/rows/${rowId}/seats/availability/${eventId}`;
  }

  private getSeatsByRowIdUrl(rowId: number): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/rows/${rowId}/seats`;
  }

  getSeatsByRowIdPublic$(rowId: number): Observable<Seat[]> {
    return this.requestService.getRequest$<Seat[]>(this.getSeatsByRowIdPublicUrl(rowId));
  }

  getSeatsByRowIdWithAvailabilityPublic$(rowId: number, eventId: number): Observable<Seat[]> {
    return this.requestService.getRequest$<Seat[]>(this.getSeatsByRowIdWithAvailabilityPublicUrl(rowId, eventId));
  }

  getSeatsByRowId$(rowId: number): Observable<Seat[]> {
    return this.requestService.getRequest$<Seat[]>(this.getSeatsByRowIdUrl(rowId));
  }
}
