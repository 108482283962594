import {
  ACTIVE_TICKET_ENTRY_CODE_ERROR,
  ACTIVE_TICKET_TRANSFER_CODE_ERROR,
  BALANCE_TOO_LOW_ERROR,
  CUSTOMER_PAYOUT_ALREADY_REQUESTED_ERROR,
  DELETE_EVENT_GROUPS_WITH_EVENTS_ERROR,
  DELETE_EVENT_NOT_ALLOWED_ERROR,
  DELETE_LAYOUT_USED_FOR_LOCATIONS_ERROR,
  DELETE_LOCATION_USED_FOR_EVENTS_ERROR,
  DELETE_PRICE_CONFIG_USED_FOR_SECTION_ERROR,
  DELETE_PRICE_WITH_ACTIVE_ORDERS_ERROR,
  EDIT_LOCATION_USED_FOR_EVENT_WITH_ORDERS_ERROR,
  EDIT_LOCATION_USED_FOR_PUBLISHED_EVENT_ERROR,
  EDIT_PRICE_WITH_ACTIVE_ORDERS_ERROR,
  EVENT_LOCATION_CHANGED_NOT_ALLOWED_ERROR,
  EVENT_PERSONALIZATION_CHANGED_NOT_ALLOWED_ERROR,
  EVENT_START_CHANGED_NOT_ALLOWED_ERROR,
  EVENT_START_IN_PAST_ERROR,
  LAYOUT_CHANGED_NOT_ALLOWED_ERROR,
  NOT_ENOUGH_SEATS_NEXT_TO_EACH_OTHER_ERROR,
  ORDER_ENTRY_HISTORY_ALREADY_IN_CART_ERROR,
  PUBLISH_EVENT_FOR_ORGANIZER_NOT_APPROVED_ERROR,
  SEAT_ALREADY_BOOKED_ERROR,
  SESSION_EXPIRED_ERROR,
  TOO_MANY_SEATS_SELECTED_ERROR
} from './error-messages';

export const ERR_RESOURCE_IS_USED = 'ERR_RESOURCE_IS_USED';
export const VALIDATION_ERROR = 'VALIDATION_ERROR';
export const DELETE_EVENT_NOT_ALLOWED_EXCEPTION = 'DELETE_EVENT_NOT_ALLOWED';
export const EVENT_LOCATION_CHANGED_NOT_ALLOWED_EXCEPTION = 'EVENT_LOCATION_CHANGED_NOT_ALLOWED';
export const EVENT_START_IN_PAST_EXCEPTION = 'EVENT_START_IN_PAST';
export const EVENT_START_CHANGED_NOT_ALLOWED_EXCEPTION = 'EVENT_START_CHANGED_NOT_ALLOWED';
export const EVENT_PERSONALIZATION_CHANGED_NOT_ALLOWED_EXCEPTION = 'EVENT_PERSONALIZATION_CHANGED_NOT_ALLOWED';
export const EDIT_LOCATION_USED_FOR_EVENT_WITH_ORDERS_EXCEPTION = 'EDIT_LOCATION_USED_FOR_EVENT_WITH_ORDERS';
export const PUBLISH_EVENT_FOR_ORGANIZER_NOT_APPROVED_EXCEPTION = 'PUBLISH_EVENT_FOR_ORGANIZER_NOT_APPROVED';
export const EDIT_LOCATION_USED_FOR_PUBLISHED_EVENT_EXCEPTION = 'EDIT_LOCATION_USED_FOR_PUBLISHED_EVENT';
export const LAYOUT_CHANGED_NOT_ALLOWED_EXCEPTION = 'LAYOUT_CHANGED_NOT_ALLOWED';
export const DELETE_PRICE_CONFIG_USED_FOR_SECTION_EXCEPTION = 'DELETE_PRICE_CONFIG_USED_FOR_SECTION';
export const DELETE_LAYOUT_USED_FOR_LOCATIONS_EXCEPTION = 'DELETE_LAYOUT_USED_FOR_LOCATIONS';
export const DELETE_LOCATION_USED_FOR_EVENTS_EXCEPTION = 'DELETE_LOCATION_USED_FOR_EVENTS';
export const DELETE_EVENT_GROUPS_WITH_EVENTS_EXCEPTION = 'DELETE_EVENT_GROUPS_WITH_EVENTS';
export const SESSION_EXPIRED_EXCEPTION = 'SESSION_EXPIRED';
export const USER_HAS_NO_DEVICE_EXCEPTION = 'USER_HAS_NO_DEVICE';
export const NOT_ENOUGH_SEATS_NEXT_TO_EACH_OTHER_EXCEPTION = 'NOT_ENOUGH_SEATS_NEXT_TO_EACH_OTHER';
export const ORDER_ENTRY_HISTORY_ALREADY_IN_CART_EXCEPTION = 'ORDER_ENTRY_HISTORY_ALREADY_IN_CART';
export const SEAT_ALREADY_BOOKED_EXCEPTION = 'SEAT_ALREADY_BOOKED';
export const TOO_MANY_SEATS_SELECTED_EXCEPTION = 'TOO_MANY_SEATS_SELECTED';
export const DELETE_PRICE_WITH_ACTIVE_ORDERS_EXCEPTION = 'DELETE_PRICE_WITH_ACTIVE_ORDERS';
export const EDIT_PRICE_WITH_ACTIVE_ORDERS_EXCEPTION = 'EDIT_PRICE_WITH_ACTIVE_ORDERS';
export const CUSTOMER_PAYOUT_ALREADY_REQUESTED_EXCEPTION = 'CUSTOMER_PAYOUT_ALREADY_REQUESTED';
export const BALANCE_TOO_LOW_EXCEPTION = 'BALANCE_TOO_LOW';
export const ACTIVE_TICKET_TRANSFER_CODE_EXCEPTION = 'ACTIVE_TICKET_TRANSFER_CODE';
export const ACTIVE_TICKET_ENTRY_CODE_EXCEPTION = 'ACTIVE_TICKET_ENTRY_CODE';
export const ACCOUNT_NOT_ACTIVE_EXCEPTION = 'ERR_ACCOUNT_NOT_ACTIVE';

export const ERROR_KEY_MESSAGE_MAP: Map<string, string> = new Map<string, string>([
  [EDIT_LOCATION_USED_FOR_PUBLISHED_EVENT_EXCEPTION, EDIT_LOCATION_USED_FOR_PUBLISHED_EVENT_ERROR],
  [EDIT_LOCATION_USED_FOR_EVENT_WITH_ORDERS_EXCEPTION, EDIT_LOCATION_USED_FOR_EVENT_WITH_ORDERS_ERROR],
  [EVENT_LOCATION_CHANGED_NOT_ALLOWED_EXCEPTION, EVENT_LOCATION_CHANGED_NOT_ALLOWED_ERROR],
  [EVENT_START_IN_PAST_EXCEPTION, EVENT_START_IN_PAST_ERROR],
  [EVENT_START_CHANGED_NOT_ALLOWED_EXCEPTION, EVENT_START_CHANGED_NOT_ALLOWED_ERROR],
  [EVENT_PERSONALIZATION_CHANGED_NOT_ALLOWED_EXCEPTION, EVENT_PERSONALIZATION_CHANGED_NOT_ALLOWED_ERROR],
  [PUBLISH_EVENT_FOR_ORGANIZER_NOT_APPROVED_EXCEPTION, PUBLISH_EVENT_FOR_ORGANIZER_NOT_APPROVED_ERROR],
  [LAYOUT_CHANGED_NOT_ALLOWED_EXCEPTION, LAYOUT_CHANGED_NOT_ALLOWED_ERROR],
  [DELETE_PRICE_CONFIG_USED_FOR_SECTION_EXCEPTION, DELETE_PRICE_CONFIG_USED_FOR_SECTION_ERROR],
  [DELETE_LAYOUT_USED_FOR_LOCATIONS_EXCEPTION, DELETE_LAYOUT_USED_FOR_LOCATIONS_ERROR],
  [DELETE_LOCATION_USED_FOR_EVENTS_EXCEPTION, DELETE_LOCATION_USED_FOR_EVENTS_ERROR],
  [DELETE_EVENT_GROUPS_WITH_EVENTS_EXCEPTION, DELETE_EVENT_GROUPS_WITH_EVENTS_ERROR],
  [SESSION_EXPIRED_EXCEPTION, SESSION_EXPIRED_ERROR],
  [DELETE_PRICE_WITH_ACTIVE_ORDERS_EXCEPTION, DELETE_PRICE_WITH_ACTIVE_ORDERS_ERROR],
  [ORDER_ENTRY_HISTORY_ALREADY_IN_CART_EXCEPTION, ORDER_ENTRY_HISTORY_ALREADY_IN_CART_ERROR],
  [DELETE_EVENT_NOT_ALLOWED_EXCEPTION, DELETE_EVENT_NOT_ALLOWED_ERROR],
  [EDIT_PRICE_WITH_ACTIVE_ORDERS_EXCEPTION, EDIT_PRICE_WITH_ACTIVE_ORDERS_ERROR],
  [CUSTOMER_PAYOUT_ALREADY_REQUESTED_EXCEPTION, CUSTOMER_PAYOUT_ALREADY_REQUESTED_ERROR],
  [BALANCE_TOO_LOW_EXCEPTION, BALANCE_TOO_LOW_ERROR],
  [NOT_ENOUGH_SEATS_NEXT_TO_EACH_OTHER_EXCEPTION, NOT_ENOUGH_SEATS_NEXT_TO_EACH_OTHER_ERROR],
  [SEAT_ALREADY_BOOKED_EXCEPTION, SEAT_ALREADY_BOOKED_ERROR],
  [TOO_MANY_SEATS_SELECTED_EXCEPTION, TOO_MANY_SEATS_SELECTED_ERROR],
  [ACTIVE_TICKET_TRANSFER_CODE_EXCEPTION, ACTIVE_TICKET_TRANSFER_CODE_ERROR],
  [ACTIVE_TICKET_ENTRY_CODE_EXCEPTION, ACTIVE_TICKET_ENTRY_CODE_ERROR]
]);
