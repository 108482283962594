import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgForOf, NgIf } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { DOWN } from '../../../const';
import { InputLabelComponent } from '../input-label';
import { SelectOption } from '../types';
import { IconComponent } from '../../icon';

@Component({
  selector: 'app-multi-select-input',
  standalone: true,
  imports: [InputLabelComponent, NgIf, NgForOf, ReactiveFormsModule, IconComponent],
  templateUrl: './multi-select-input.component.html'
})
export class MultiSelectInputComponent<K, V> {
  protected readonly DOWN = DOWN;

  @Input()
  focused = false;

  @Input({ required: true })
  id!: string;
  @Input()
  label = '';
  @Input()
  minValues = 0;

  @Input({ required: true })
  domain: SelectOption<K, V>[] = [];
  @Input({ required: true })
  values: K[] = [];
  @Output()
  valuesChange = new EventEmitter<K[]>();
  @Output()
  spanClicked = new EventEmitter<boolean>();

  protected spanClickedAction() {
    this.spanClicked.emit(true);
  }

  protected isSelected(key: K) {
    for (const value of this.values) {
      if (value === key) {
        return true;
      }
    }

    return false;
  }

  protected onInputChange(inputValue: SelectOption<K, V>): void {
    let keyHandled = false;
    const values: K[] = [];

    for (const value of this.values) {
      if (value !== inputValue.key) {
        values.push(value);
      } else {
        keyHandled = true;
      }
    }

    if (!keyHandled) {
      values.push(inputValue.key);
    }

    this.valuesChange.emit(values);
  }
}
