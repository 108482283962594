import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class EmailValidatorService {
  NO_VALID_EMAIL_KEY = 'Please enter a valid email';

  constructor(private readonly translateService: TranslateService) {}

  validateEmail(email: string): string {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(email)) {
      return '';
    }

    return (this.translateService.instant(this.NO_VALID_EMAIL_KEY) as string) ?? this.NO_VALID_EMAIL_KEY;
  }
}
