<app-auth-header />

<app-auth-main-page-content>
  <app-h1 [title]="'Resend Account Confirmation Link'"></app-h1>

  <app-error-container *ngIf="showActiveError" [errorMessage]="'Your account has not yet been activated.'" />

  <app-form-row [label]="'Email'" [for]="'email'">
    <app-text-input
      [id]="'email'"
      [placeholder]="'Email'"
      [isRequired]="true"
      [type]="EMAIL"
      [(value)]="email"
      (valueChange)="onEmailChange()"
    ></app-text-input>
  </app-form-row>

  <app-action-bar>
    <app-icon-button
      buttonType="primary"
      label="Resend Confirmation Link"
      (clicked)="onResendConfirmationLink()"
      [enabled]="formValid"
    ></app-icon-button>
  </app-action-bar>
</app-auth-main-page-content>
