export const TEXT = 'text';
export const EMAIL = 'email';
export const PASSWORD = 'password';
export const DATE = 'date';
export const TIME = 'time';
export const NUMBER = 'number';
export const DECIMAL = 'decimal';
export const PHONE_NUMBER = 'phone_number';

export type TextInputType =
  | typeof TEXT
  | typeof EMAIL
  | typeof PASSWORD
  | typeof DATE
  | typeof TIME
  | typeof NUMBER
  | typeof DECIMAL
  | typeof PHONE_NUMBER;
