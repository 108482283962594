import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, mergeMap, Observable } from 'rxjs';
import { ErrorService } from '../../error';

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  constructor(
    private readonly http: HttpClient,
    private readonly errorService: ErrorService
  ) {}

  private handleErrorAndRetry$<ReturnBodyType>(
    error: HttpErrorResponse,
    retryFunction: () => Observable<ReturnBodyType>
  ): Observable<ReturnBodyType> {
    return this.errorService.handleHttpErrorResponse$(error).pipe(
      mergeMap((errorHandlerResult: boolean) => {
        if (errorHandlerResult) {
          return retryFunction();
        }
        throw error;
      })
    );
  }

  getRequest$<ReturnBodyType>(url: string): Observable<ReturnBodyType> {
    return this.http.get<ReturnBodyType>(url, { observe: 'body' }).pipe(
      catchError((error: HttpErrorResponse): Observable<ReturnBodyType> => {
        return this.handleErrorAndRetry$(error, () => this.getRequest$<ReturnBodyType>(url));
      })
    );
  }

  putRequest$<ReturnBodyType, PayloadType>(url: string, payload?: PayloadType | undefined): Observable<ReturnBodyType> {
    return this.http.put<ReturnBodyType>(url, payload).pipe(
      catchError((error: HttpErrorResponse): Observable<ReturnBodyType> => {
        return this.handleErrorAndRetry$(error, () => this.putRequest$<ReturnBodyType, PayloadType>(url, payload));
      })
    );
  }

  postRequest$<ReturnBodyType, PayloadType>(url: string, payload?: PayloadType): Observable<ReturnBodyType> {
    return this.http.post<ReturnBodyType>(url, payload).pipe(
      catchError((error: HttpErrorResponse): Observable<ReturnBodyType> => {
        return this.handleErrorAndRetry$(error, () => this.postRequest$<ReturnBodyType, PayloadType>(url, payload));
      })
    );
  }

  deleteRequest$<ReturnBodyType>(url: string): Observable<ReturnBodyType> {
    return this.http.delete<ReturnBodyType>(url, { observe: 'body' }).pipe(
      catchError((error: HttpErrorResponse): Observable<ReturnBodyType> => {
        return this.handleErrorAndRetry$(error, () => this.deleteRequest$<ReturnBodyType>(url));
      })
    );
  }
}
