<div class="flex grow">
  <div class="w-1/4 p-2 text-lg">
    <div class="font-bold">{{ label }}</div>
  </div>
  <div class="w-3/4 p-2 text-lg">{{ street }} {{ number }}</div>
</div>

<div class="flex grow">
  <div class="w-1/4 p-2 text-lg">
    <div class="font-bold"></div>
  </div>
  <div class="w-3/4 p-2 text-lg">{{ zip }} {{ city }}</div>
</div>
