export interface AdminInvitationConfirmationRequest {
  invitationId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  passwordConfirm?: string;
  language?: string;
  country?: string;
  invitationToken?: string;
}
