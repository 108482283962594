import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-confirmation-link-send',
  templateUrl: './confirmation-link-send.component.html'
})
export class ConfirmationLinkSendComponent implements OnInit {
  email!: string;

  constructor(private readonly route: ActivatedRoute) {}

  ngOnInit(): void {
    this.email = this.route.snapshot.paramMap.get('email')!;
  }
}
