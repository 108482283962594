export interface AdminInvitation {
  id?: number;

  firstName?: string;

  lastName?: string;

  email?: string;

  language?: string;

  // eslint-disable-next-line @typescript-eslint/ban-types
  invitationDateTime?: Date;

  // eslint-disable-next-line @typescript-eslint/ban-types
  invitationExpirationTime?: Date;
}
