<app-dialog-wrapper [transparent]="true" dialogTitle="Forbidden" (closeClicked)="confirm()">
  <app-dialog-body>
    <app-alert title="Forbidden!" message="You do not have the rights needed to view this resource."></app-alert>
  </app-dialog-body>
  <app-dialog-footer>
    <app-action-bar>
      <app-icon-button buttonType="primary" label="Got it!" [icon]="CHECK" (clicked)="confirm()"></app-icon-button>
    </app-action-bar>
  </app-dialog-footer>
</app-dialog-wrapper>
