<div [class]="classList">
  <div class="w-1/4 p-1 text-lg">
    <div class="mt-4">
      <label [for]="for" class="font-bold leading-form-label-line-height">{{ label }}</label>
    </div>
  </div>
  <div class="w-1/4 p-1 text-lg">
    <ng-content></ng-content>
  </div>
  <div class="w-1/4 p-1 text-lg"></div>
  <div class="w-1/4 p-1 text-lg"></div>
</div>
