<div class="flex grow">
  <div class="w-1/4 p-2 text-lg">
    <div class="font-bold">{{ label }}</div>
  </div>
  <div class="flex w-3/4 p-2 text-lg items-center">
    <div>
      <app-icon-button *ngIf="show" [buttonType]="'primary'" (clicked)="toggleShow()" [icon]="HIDE" />
      <app-icon-button *ngIf="!show" [buttonType]="'primary'" (clicked)="toggleShow()" [icon]="SHOW" />
    </div>
    <div class="ml-2">
      <app-icon-button [buttonType]="'primary'" (clicked)="copy()" [icon]="copied ? CHECK : COPY" />
    </div>
    <div class="mx-2">
      <app-icon-button *ngIf="canBeScanned" [buttonType]="'primary'" (clicked)="showQrCode()" [icon]="QR_CODE" />
    </div>
    <div *ngIf="update && updateIcon" class="mr-2">
      <app-icon-button [buttonType]="'primary'" (clicked)="update()" [icon]="updateIcon" [label]="updateLabel ?? ''" />
    </div>
    <span *ngIf="!show">******</span>
    <span *ngIf="show">{{ content }}</span>
  </div>
</div>
