import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-success-container',
  templateUrl: './success-container.component.html'
})
export class SuccessContainerComponent {
  @Input()
  successMessage = '';
}
