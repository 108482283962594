<div *ngIf="heightClass" [class]="'flex w-full ' + heightClass">
  <ag-grid-angular [rowData]="gridData" [columnDefs]="colDefs" class="ag-theme-quartz h-full w-full"></ag-grid-angular>
</div>

<div *ngIf="!heightClass" class="flex w-full">
  <ag-grid-angular
    domLayout="autoHeight"
    [rowData]="gridData"
    [columnDefs]="colDefs"
    [suppressDragLeaveHidesColumns]="true"
    columnMenu="new"
    class="ag-theme-material w-full"
  ></ag-grid-angular>
</div>
