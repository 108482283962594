import { Component } from '@angular/core';
import { NavigationService, NavigationUrlService } from '../../services';
import { WEBSITE_BASE_ROUTE, WEBSITE_LOGIN_ROUTE, WebsiteRoute } from '../../const/routes/website-routes';
import { ADMIN_DASHBOARD_EVENTS_ROUTE, ADMIN_DASHBOARD_LOGIN_ROUTE, AdminDashboardRoute } from '../../const/routes/admin-dashboard-routes';
import { BackofficeRoute } from '../../const/routes/backoffice-routes';

@Component({
  selector: 'app-auth-header',
  templateUrl: './auth-header.component.html'
})
export class AuthHeaderComponent {
  protected loginRoute: AdminDashboardRoute | WebsiteRoute;
  protected homeRoute: AdminDashboardRoute | WebsiteRoute;

  constructor(
    private readonly navigationService: NavigationService,
    private readonly navigationUrlService: NavigationUrlService
  ) {
    if (this.navigationUrlService.isAdminModule()) {
      this.loginRoute = ADMIN_DASHBOARD_LOGIN_ROUTE;
      this.homeRoute = ADMIN_DASHBOARD_EVENTS_ROUTE;
    } else {
      this.loginRoute = WEBSITE_LOGIN_ROUTE;
      this.homeRoute = WEBSITE_BASE_ROUTE;
    }
  }

  protected navigate(event: Event, route: WebsiteRoute | AdminDashboardRoute | BackofficeRoute) {
    event.preventDefault();
    this.navigationService.navigate(route);
  }
}
