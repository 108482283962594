import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-row-entry-text',
  templateUrl: './row-entry-text.component.html',
  styles: [':host{display: flex; flex: 1}']
})
export class RowEntryTextComponent {
  @Input()
  content = '';

  @Input()
  textSize = 'md';

  @Input()
  fontWeight = 'normal';

  @Input()
  route?: string;

  @Input()
  textColor = 'text-black';
}
