<app-dialog-wrapper [widthPx]="DIALOG_WIDTH_MEDIUM" dialogTitle="Login" (closeClicked)="closeDialog()">
  <app-dialog-body>
    <app-alert *ngIf="loginFailed" title="Login failure!" message="Invalid credentials."></app-alert>
    <app-text-input
      [id]="'email'"
      label="Email"
      [icon]="USER"
      [type]="EMAIL"
      [isRequired]="true"
      [(value)]="email"
      (valueChange)="validateForm()"
      (keyup.enter)="login()"
    ></app-text-input>
    <app-text-input
      [id]="'password'"
      label="Password"
      [icon]="KEY"
      [type]="PASSWORD"
      [isRequired]="true"
      [validatePassword]="false"
      [(value)]="password"
      (valueChange)="validateForm()"
      (keyup.enter)="login()"
    ></app-text-input>
  </app-dialog-body>
  <app-dialog-footer>
    <app-action-bar>
      <app-icon-button buttonType="cancel" label="Close" [icon]="X_MARK" (clicked)="closeDialog()"></app-icon-button>
      <app-icon-button buttonType="primary" label="Login" [icon]="LOGIN" (clicked)="login()" [enabled]="formValid"></app-icon-button>
      <div *ngIf="moduleType !== BACKOFFICE" class="absolute right-5 grid grid-cols-1">
        <app-link label="Sign up here" (clicked)="openSignUp()"></app-link>
        <app-link label="Forgot password?" (clicked)="openResetPassword()"></app-link>
      </div>
    </app-action-bar>
  </app-dialog-footer>
</app-dialog-wrapper>
