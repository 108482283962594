import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ColDef, ITextFilterParams, ValueFormatterFunc, ValueFormatterParams } from 'ag-grid-community';
import { DEFAULT_DATETIME_FORMAT } from '../../const';

@Injectable()
export class GridCommons {
  constructor(private readonly datePipe: DatePipe) {}

  // eslint-disable-next-line @typescript-eslint/ban-types
  readonly dateTimeFormatter: ValueFormatterFunc<unknown, Date> = (params: ValueFormatterParams<unknown, Date>) =>
    this.datePipe.transform(params.value, DEFAULT_DATETIME_FORMAT)!;
}

export const textFilterOptions: ColDef = {
  filter: true,
  filterParams: {
    filterOptions: ['contains', 'blank', 'notBlank'],
    defaultOption: 'contains',
    maxNumConditions: 1
  } as ITextFilterParams
};
