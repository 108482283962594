import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';
import { ERROR_TRIANGLE } from '../../../const';
import { IconComponent } from '../../icon';

@Component({
  selector: 'app-input-label',
  templateUrl: './input-label.component.html',
  standalone: true,
  imports: [NgIf, IconComponent]
})
export class InputLabelComponent {
  protected readonly ERROR_TRIANGLE = ERROR_TRIANGLE;

  @Input({ required: true })
  id!: string;
  @Input()
  label = '';
  @Input({ required: true })
  hasError: boolean | undefined;
  @Input({ required: true })
  errorMessage?: string;
  @Input()
  errorTextColor = 'text-error';
  @Input({ required: true })
  isRequired = false;
  @Input()
  onErrorOnlyShowIcon = false;
  @Input()
  labelColor = 'black';
}
