<app-input-label
  *ngIf="label && !hideLabel"
  [id]="id"
  [label]="label"
  [hasError]="false"
  [isRequired]="false"
  [errorMessage]="''"
></app-input-label>

<label class="relative inline-flex items-center cursor-pointer">
  <input [disabled]="disabled" [id]="id" type="checkbox" [(ngModel)]="value" class="sr-only peer" (change)="onSelectChange()" />
  <div
    class="w-11 h-6 bg-toggle-gray-lightest peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-toggle-blue-light rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-toggle-gray-light after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-toggle-blue"
  ></div>
</label>
