import { Component, Input } from '@angular/core';
import { NgForOf, NgIf } from '@angular/common';
import { ERROR_CIRCLE } from '../../const';
import { IconComponent } from '../icon';

@Component({
  selector: 'app-alert',
  standalone: true,
  imports: [NgIf, NgForOf, IconComponent],
  templateUrl: './alert.component.html'
})
export class AlertComponent {
  protected readonly ERROR_CIRCLE = ERROR_CIRCLE;

  @Input()
  title?: string;
  @Input()
  message?: string;
  @Input()
  messages?: string[];
}
