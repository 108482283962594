import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-form-row-two-columns',
  templateUrl: './form-row-two-columns.component.html'
})
export class FormRowTwoColumnsComponent {
  @Input()
  label = '';

  @Input()
  for = '';

  @Input()
  secondLabel = '';

  @Input()
  secondFor = '';

  @Input()
  hideSecondColumn = false;
}
