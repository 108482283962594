import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-object-detail-address',
  templateUrl: './object-detail-address.component.html'
})
export class ObjectDetailAddressComponent {
  @Input()
  label = 'Address';

  @Input()
  street = '';

  @Input()
  number = '';

  @Input()
  zip = '';

  @Input()
  city = '';
}
