import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-textarea-input',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './textarea-input.component.html'
})
export class TextareaInputComponent {
  @Input({ required: true })
  id!: string;
  @Input()
  label = '';
  @Input()
  disabled = false;
  @Input()
  placeholder = '';

  @Input({ required: true })
  value!: string | undefined;
  @Output()
  valueChange = new EventEmitter<string>();

  onInputChange(): void {
    this.valueChange.emit(this.value);
  }
}
