import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  ActionBarComponent,
  DialogBodyComponent,
  DialogFooterComponent,
  DialogWrapperComponent,
  IconButtonComponent,
  AlertComponent
} from '../../../widgets';
import { CHECK } from '../../../const';

@Component({
  selector: 'app-server-error-dialog',
  templateUrl: './server-down-dialog.component.html',
  imports: [ActionBarComponent, DialogBodyComponent, DialogFooterComponent, DialogWrapperComponent, IconButtonComponent, AlertComponent],
  standalone: true
})
export class ServerDownDialogComponent {
  protected readonly CHECK = CHECK;

  constructor(
    public dialogRef: MatDialogRef<ServerDownDialogComponent>,
    public dialog: MatDialog
  ) {}

  confirm(): void {
    this.dialogRef.close(false);
  }
}

export const SERVER_IS_DOWN_DIALOG_TYPE = ServerDownDialogComponent;
