<div class="p-4 mb-4 text-md text-error-container-red-dark rounded-lg bg-error-container-red-light" role="alert">
  {{ errorMessage }}
  <div class="pl-4" *ngIf="subError">
    {{ subError }}
    <div class="pl-4" *ngIf="errorObjects">
      <ul class="list-disc pl-4">
        <li *ngFor="let object of errorObjects">{{ object }}</li>
      </ul>
    </div>
  </div>
</div>
