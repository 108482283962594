import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CHECK, X_MARK } from '../../const';
import { DialogWrapperComponent } from '../../widgets/dialog/dialog-wrapper/dialog-wrapper.component';
import { DialogBodyComponent } from '../../widgets/dialog/dialog-body/dialog-body.component';
import { DialogFooterComponent } from '../../widgets/dialog/dialog-footer/dialog-footer.component';
import { ActionBarComponent } from '../../widgets/action-bar/action-bar.component';
import { IconButtonComponent } from '../../widgets';

@Component({
  selector: 'app-confirm-removal-dialog',
  templateUrl: './confirm-removal-dialog.component.html',
  imports: [DialogWrapperComponent, DialogBodyComponent, DialogFooterComponent, ActionBarComponent, IconButtonComponent],
  standalone: true
})
export class ConfirmRemovalDialogComponent {
  protected readonly X_MARK = X_MARK;
  protected readonly CHECK = CHECK;

  constructor(public dialogRef: MatDialogRef<ConfirmRemovalDialogComponent>) {}

  confirm(): void {
    this.dialogRef.close(true);
  }

  cancel(): void {
    this.dialogRef.close(false);
  }
}

export const CONFIRM_REMOVAL_DIALOG_TYPE = ConfirmRemovalDialogComponent;
