export interface SeatMapDefaultConfig {
  x: number;
  y: number;
  zoom: number;
}

export const DEFAULT_SEAT_MAP_CONFIG = {
  x: 0,
  y: 0,
  zoom: 1
} as SeatMapDefaultConfig;
