import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { catchError, map, Observable, of } from 'rxjs';
import { NavigationService } from '../navigation';
import { ApiUrlService, RequestService } from '../rest';
import { LocalStorageService } from '../local-storage';
import { ConfirmPasswordResetRequestModel, LoginPayload, Organizer, User } from '../../models';
import { ModuleType } from '../../const';
import { LOGIN_DIALOG_TYPE } from '../../dialogs';
import { DialogHelperService } from '../dialog';
import { SignUpUserRequest } from '../../models/auth/sign-up-user-request-model';
import { WEBSITE_LOGIN_ROUTE, WebsiteRoute } from '../../const/routes/website-routes';
import { ADMIN_DASHBOARD_LOGIN_ROUTE, AdminDashboardRoute } from '../../const/routes/admin-dashboard-routes';
import { BACKOFFICE_LOGIN_ROUTE, BackofficeRoute } from '../../const/routes/backoffice-routes';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly publicBasePath = `${this.apiUrlService.getPublicApiBasePath()}/auth`;
  private readonly adminBasePath = `${this.apiUrlService.getAdminApiBasePath()}/auth`;
  private readonly internalBasePath = `${this.apiUrlService.getInternalApiBasePath()}/auth`;
  private readonly backofficeBasePath = `${this.apiUrlService.getBackofficeBasePath()}/auth`;
  private readonly logoutAdminApiPath = `${this.adminBasePath}/logout`;
  private readonly logoutInternalApiPath = `${this.internalBasePath}/logout`;
  private readonly logoutBackofficeApiPath = `${this.backofficeBasePath}/logout`;
  private readonly signupOrganizerApiPath = `${this.publicBasePath}/signUpOrganizer`;
  private readonly confirmUserAccountEmail = `${this.publicBasePath}/confirmationLink`;
  private readonly resendConfirmationLink = `${this.publicBasePath}/newConfirmationLink`;
  private readonly tokenStillValidPathUser = `${this.internalBasePath}/check_token_still_valid`;
  private readonly tokenStillValidPathOrganizer = `${this.adminBasePath}/check_token_still_valid`;
  private readonly tokenStillValidPathBackoffice = `${this.backofficeBasePath}/check_token_still_valid`;

  readonly loginApiPath = `${this.publicBasePath}/login`;
  readonly signupApiPath = `${this.publicBasePath}/signUp`;
  readonly resetPasswordPath = `${this.publicBasePath}/reset-password`;
  readonly confirmResetPasswordPath = `${this.publicBasePath}/confirm-reset-password`;
  readonly phoneConfirmationPath = `${this.publicBasePath}/confirmPhone`;
  readonly requestNew2FACodePath = `${this.internalBasePath}/new2FACode`;

  constructor(
    private readonly http: HttpClient,
    private readonly requestService: RequestService,
    private readonly apiUrlService: ApiUrlService,
    private readonly navigation: NavigationService,
    private readonly localStorageService: LocalStorageService,
    private readonly dialogHelperService: DialogHelperService
  ) {}

  openLoginDialog$(moduleType: ModuleType): Observable<boolean> {
    const dialog$ = this.dialogHelperService.openDialog$<boolean>(LOGIN_DIALOG_TYPE, moduleType, undefined);

    if (dialog$) {
      return dialog$;
    }

    return of(false);
  }

  hasOrganizerPermissions$(): Observable<boolean> {
    return this.requestService.getRequest$(`${this.adminBasePath}/check_has_organizer_role`);
  }

  isAuthTokenStillValidForUser$(): Observable<boolean> {
    return this.isAuthTokenStillValid$(this.tokenStillValidPathUser);
  }

  isAuthTokenStillValidForOrganizer$(): Observable<boolean> {
    return this.isAuthTokenStillValid$(this.tokenStillValidPathOrganizer);
  }

  isAuthTokenStillValidForBackoffice$(): Observable<boolean> {
    return this.isAuthTokenStillValid$(this.tokenStillValidPathBackoffice);
  }

  isAuthTokenStillValid$(url: string): Observable<boolean> {
    const token = this.localStorageService.getAuthenticationToken();
    if (!token || token === '') {
      return of(false);
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    });

    return this.http.get(url, { headers, observe: 'response' }).pipe(
      map((response: HttpResponse<unknown>) => {
        const authToken = response.headers.get('authorization');
        if (authToken) {
          this.localStorageService.storeAuthenticationToken(authToken);

          return true;
        }

        return false;
      }),
      catchError(() => of(false))
    );
  }

  loginUser$(payload: LoginPayload): Observable<HttpResponse<User>> {
    return this.http.post<User>(this.loginApiPath, payload, { observe: 'response' });
  }

  login$(payload: LoginPayload): Observable<HttpResponse<Organizer>> {
    return this.http.post<Organizer>(this.loginApiPath, payload, { observe: 'response' });
  }

  logoutAdmin(): void {
    this.logout(this.logoutAdminApiPath, ADMIN_DASHBOARD_LOGIN_ROUTE);
  }

  logoutUser() {
    this.logout(this.logoutInternalApiPath, WEBSITE_LOGIN_ROUTE);
  }

  logoutBackofficeUser() {
    this.logout(this.logoutBackofficeApiPath, BACKOFFICE_LOGIN_ROUTE);
  }

  logout(url: string, redirectRoute: WebsiteRoute | AdminDashboardRoute | BackofficeRoute) {
    const authToken = this.localStorageService.getAuthenticationToken();
    if (authToken) {
      this.requestService.postRequest$<boolean, undefined>(url, undefined).subscribe(() => {
        this.localStorageService.removeAuthenticationToken();
        this.navigation.navigate(redirectRoute);
      });
    } else {
      this.navigation.navigate(redirectRoute);
    }
  }

  signUpUser$(userRequest: SignUpUserRequest): Observable<boolean> {
    return this.requestService.postRequest$<boolean, SignUpUserRequest>(this.signupApiPath, userRequest);
  }

  signUpOrganizer$(organizer: Organizer): Observable<boolean> {
    return this.requestService.postRequest$<boolean, Organizer>(this.signupOrganizerApiPath, organizer);
  }

  confirmUserAccountEmail$(token: string): Observable<boolean> {
    return this.requestService.postRequest$<boolean, string>(this.confirmUserAccountEmail, token);
  }

  resendConfirmationLink$(email: string): Observable<boolean> {
    return this.requestService.postRequest$<boolean, string>(this.resendConfirmationLink, email);
  }

  resetPassword$(email: string): Observable<boolean> {
    return this.requestService.postRequest$<boolean, string>(this.resetPasswordPath, email);
  }

  confirmResetPassword$(payload: ConfirmPasswordResetRequestModel): Observable<boolean> {
    return this.requestService.postRequest$<boolean, ConfirmPasswordResetRequestModel>(this.confirmResetPasswordPath, payload);
  }

  sendPhoneConfirmation$(confirmationCode: string): Observable<boolean> {
    return this.requestService.postRequest$<boolean, ConfirmPasswordResetRequestModel>(`${this.phoneConfirmationPath}/${confirmationCode}`);
  }

  requestNew2FACode$(): Observable<boolean> {
    return this.requestService.putRequest$<boolean, unknown>(`${this.requestNew2FACodePath}`);
  }
}
