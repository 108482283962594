<div class="grow flex justify-center">
  <div class="max-w-7xl flex grow flex-col min-h-[calc(100vh-124px)] pb-8">
    <ng-content></ng-content>
  </div>
</div>
<footer class="absolute bottom-0 w-full bg-white h-[60px] justify-center flex items-center border-t border-t-black">
  <div class="flex gap-4">
    <app-imprint />
    <app-terms-and-conditions />
    <app-privacy-statement />
  </div>
</footer>
