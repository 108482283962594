import { Component } from '@angular/core';
import { NavigationService, NavigationUrlService } from '../../../../services';
import { ADMIN_DASHBOARD_RESET_PASSWORD_ROUTE } from '../../../../const/routes/admin-dashboard-routes';
import { WEBSITE_RESET_PASSWORD_ROUTE } from '../../../../const/routes/website-routes';

@Component({
  selector: 'app-reset-password-failed',
  templateUrl: './reset-password-failed.component.html'
})
export class ResetPasswordFailedComponent {
  constructor(
    private readonly navigationService: NavigationService,
    private readonly navigationUrlService: NavigationUrlService
  ) {}

  onResetPassword() {
    if (this.navigationUrlService.isAdminModule()) {
      this.navigationService.navigate(ADMIN_DASHBOARD_RESET_PASSWORD_ROUTE);
    } else {
      this.navigationService.navigate(WEBSITE_RESET_PASSWORD_ROUTE);
    }
  }
}
