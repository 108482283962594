import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-h2',
  templateUrl: './h2.component.html'
})
export class H2Component {
  @Input()
  content = '';
}
