import { AbstractRow } from '../abstract-row-model';
import { Seat } from '../seat-model';
import { PriceConfig } from '../price';

export const SECTION_TYPE = 'SECTION';

export interface SeatMapObject {
  id: number;
  name: string;
  type: string;
  rows?: AbstractRow[];
  seats?: Seat[];
  freeSeatSelection?: boolean;
  priceConfigId?: number;
  priceConfig?: PriceConfig;
  availableSeats?: number;
}
