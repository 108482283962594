import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService, NavigationService, NavigationUrlService } from '../../../index';
import { ADMIN_DASHBOARD_EMAIL_CONFIRMATION_SUCCESSFUL_ROUTE } from '../../../const/routes/admin-dashboard-routes';
import { WEBSITE_SIGNUP_EMAIL_CONFIRMATION_SUCCESSFUL_ROUTE } from '../../../const/routes/website-routes';

@Component({
  selector: 'app-signup-email-confirmation',
  templateUrl: './signup-email-confirmation.component.html'
})
export class SignupEmailConfirmationComponent implements OnInit {
  constructor(
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly navigationService: NavigationService,
    private readonly navigationUrlService: NavigationUrlService
  ) {}

  ngOnInit(): void {
    const code = this.route.snapshot.queryParamMap.get('code')!;
    const path = this.navigationUrlService.isAdminModule()
      ? ADMIN_DASHBOARD_EMAIL_CONFIRMATION_SUCCESSFUL_ROUTE
      : WEBSITE_SIGNUP_EMAIL_CONFIRMATION_SUCCESSFUL_ROUTE;
    this.authService.confirmUserAccountEmail$(code).subscribe({
      next: result => {
        if (result) {
          this.navigationService.navigate(path);
        } else {
          this.navigationService.navigate(path);
        }
      },
      error: () => {
        this.navigationService.navigate(path);
      }
    });
  }
}
