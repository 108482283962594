import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withFetch } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { provideClientHydration, Title } from '@angular/platform-browser';
import { provideRouter, withDisabledInitialNavigation } from '@angular/router';
import { LocalizeRouterPipe, withLocalizeRouter } from '@gilsdav/ngx-translate-router';
import { catchError, Observable, of } from 'rxjs';
import { API_URL } from '../tokens';
import { LOCALIZE_ROUTER_CONFIG, LocalStorageService, RequestAuthInterceptorService, TRANSLATE_MODULE_CONFIG } from './shared';
import { routes as rootRoutes } from './app-routing.module';

function getCsrfToken(httpClient: HttpClient, apiUrl: string, localStorageService: LocalStorageService): () => Observable<unknown> {
  return () => {
    localStorageService.clearCsrfToken();

    return httpClient.get(`${apiUrl}/public/auth/connect`, { withCredentials: true }).pipe(
      catchError(err => {
        console.log('App init error on connect api', err);

        return of(undefined);
      })
    );
  };
}

export const AppConfig: ApplicationConfig = {
  providers: [
    // App Initializer for CSRF token
    {
      provide: APP_INITIALIZER,
      useFactory: getCsrfToken,
      deps: [HttpClient, API_URL, LocalStorageService],
      multi: true
    },
    // HTTP Interceptor
    RequestAuthInterceptorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestAuthInterceptorService,
      multi: true
    },
    // Translation Service
    provideHttpClient(withFetch()),
    importProvidersFrom(TranslateModule.forRoot(TRANSLATE_MODULE_CONFIG)),
    provideRouter(rootRoutes, withDisabledInitialNavigation(), withLocalizeRouter(rootRoutes, LOCALIZE_ROUTER_CONFIG)),
    provideClientHydration(),
    LocalizeRouterPipe,
    Title
  ]
};
