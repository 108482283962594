<app-auth-header />

<app-auth-main-page-content>
  <app-h1 [title]="'Signup Successful'"></app-h1>

  <p>Your account has been created.</p>
  <p>An email with confirmation link has been sent to '{{ email }} '</p>
  <p class="mb-4">After confirming your email we will check your information and approve you as a vendor.</p>

  <app-action-bar>
    <app-icon-button
      buttonType="primary"
      label="Request new Confirmation Link"
      (clicked)="onRequestNewConfirmationLink()"
    ></app-icon-button>
  </app-action-bar>
</app-auth-main-page-content>
