<div class="flex grow items-end gap-8">
  <div class="w-1/4 p-1 text-lg">
    <label [for]="for" class="font-bold leading-form-label-line-height">{{ label }}</label>
  </div>
  <div class="w-1/4 p-1 text-lg">
    <ng-content select="[slot='firstInput']'"></ng-content>
  </div>

  <div class="w-1/4 p-1 text-lg">
    <label *ngIf="!hideSecondColumn" [for]="secondFor" class="font-bold leading-form-label-line-height">{{ secondLabel }}</label>
  </div>
  <div class="w-1/4 p-1 text-lg">
    <ng-container *ngIf="!hideSecondColumn">
      <ng-content select="[slot='secondInput']'"></ng-content>
    </ng-container>
  </div>
</div>
