import { Component, Input } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  imports: [FaIconComponent, NgIf],
  standalone: true
})
export class IconComponent {
  @Input({ required: true })
  icon!: IconProp;

  @Input()
  size?: SizeProp = undefined;

  @Input()
  link?: string | undefined;

  @Input()
  target = '_blank';
}
