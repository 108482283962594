import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-object-image-detail-row',
  templateUrl: './object-image-detail-row.component.html'
})
export class ObjectImageDetailRowComponent {
  @Input()
  label = '';

  @Input()
  imageUrl = '';

  @Input()
  altText = '';
}
