import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-row-entry',
  templateUrl: './row-entry.component.html',
  styles: [':host{display: flex; flex: 1}']
})
export class RowEntryComponent {
  @Input()
  highlightRow = false;
}
