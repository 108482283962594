export const DIALOG_WIDTH_DEFAULT = 'DEFAULT';
export const DIALOG_WIDTH_MEDIUM = 'MEDIUM';
export const DIALOG_WIDTH_WIDE = 'WIDE';
export const DIALOG_WIDTH_WIDEST = 'WIDEST';
export const DIALOG_WIDTH_EVEN_WIDER = 'EVEN_WIDER';

export type DialogWidth =
  | typeof DIALOG_WIDTH_DEFAULT
  | typeof DIALOG_WIDTH_MEDIUM
  | typeof DIALOG_WIDTH_WIDE
  | typeof DIALOG_WIDTH_WIDEST
  | typeof DIALOG_WIDTH_EVEN_WIDER;
