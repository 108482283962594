import { RelLink } from '../rel-link-model';
import { EventDescription } from './event-description-model';

//TODO: rearrange folders in model folder

export interface AbstractEvent {
  id: number | undefined;
  internalName: string;
  imageUrl: string | undefined;
  defaultLanguage: string;
  descriptions: EventDescription[];
  maxTicketAmountPerOrder: number | undefined;
  eventPersonalizationType: string | undefined;
  type: string;
  _links?: {
    self: RelLink;
    public_event_group: RelLink;
    event_group: RelLink;
  };
}
