import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  ActionBarComponent,
  DialogBodyComponent,
  DialogFooterComponent,
  DialogWrapperComponent,
  IconButtonComponent,
  AlertComponent
} from '../../../widgets';
import { CHECK } from '../../../const';

@Component({
  selector: 'app-generic-error-dialog',
  templateUrl: './generic-error-dialog.component.html',
  imports: [ActionBarComponent, DialogBodyComponent, DialogFooterComponent, DialogWrapperComponent, IconButtonComponent, AlertComponent],
  standalone: true
})
export class GenericErrorDialogComponent {
  protected readonly CHECK = CHECK;

  constructor(
    public dialogRef: MatDialogRef<GenericErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dialog: MatDialog
  ) {}

  confirm(): void {
    this.dialogRef.close(false);
  }
}

export const GENERIC_ERROR_DIALOG_TYPE = GenericErrorDialogComponent;
