import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgIf } from '@angular/common';
import { Icon, X_MARK } from '../../../const';
import { ButtonComponent } from './button.component';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styles: [':host{display: flex; flex: 1; min-width: fit-content}'],
  standalone: true,
  imports: [NgIf, ButtonComponent]
})
export class IconButtonComponent {
  protected readonly X_MARK = X_MARK;

  @Input()
  label?: string;
  @Input()
  enabled = true;
  @Input({ required: true })
  buttonType!: ButtonType;
  @Input()
  icon?: Icon;
  @Input()
  tooltipLabel = '';
  @Input()
  id = '';

  @Output()
  clicked = new EventEmitter<void>();

  onClick() {
    this.clicked.emit();
  }
}

type ButtonType = 'primary' | 'secondary' | 'cancel' | 'delete' | 'closeDialog';
