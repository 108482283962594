<div class="flex items-center gap-2">
  <ng-container *ngIf="documentation">
    <a [href]="getDocumentationLink()" target="_blank">
      <app-icon
        [icon]="HELP"
        [link]="getDocumentationLink()"
        [size]="'lg'"
        [class]="iconColorClass"
        [className]="'hover:cursor-pointer'"
      ></app-icon>
    </a>
    <h1 [classList]="'flex text-3xl font-medium leading-tight text-primary mt-4 mb-4 text-' + titleColor">{{ title }}</h1>
  </ng-container>
  <ng-container *ngIf="!documentation">
    <app-icon *ngIf="icon && !hasIconClickedFunction" [icon]="icon" [size]="'lg'" [class]="iconColorClass"></app-icon>
    <app-icon
      *ngIf="icon && hasIconClickedFunction"
      [icon]="icon"
      [size]="'lg'"
      [class]="iconColorClass"
      (click)="click()"
      [className]="'hover:cursor-pointer'"
    ></app-icon>
    <h1 [classList]="'flex text-3xl font-medium leading-tight text-primary mt-4 mb-4 text-' + titleColor">{{ title }}</h1>
  </ng-container>
</div>
