export interface SearchFields {
  searchText: string;
  dateFrom: string;
  dateTo: string;
  searchEvents: boolean;
  searchGroups: boolean;
  searchLocations: boolean;
  searchOrganizers: boolean;
}

export const DEFAULT_SEARCH_OBJECT = {
  searchText: '',
  dateFrom: '',
  dateTo: '',
  searchEvents: true,
  searchGroups: true,
  searchLocations: true,
  searchOrganizers: true
} as SearchFields;
