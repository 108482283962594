import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  ActionBarComponent,
  DialogBodyComponent,
  DialogFooterComponent,
  DialogWrapperComponent,
  IconButtonComponent,
  AlertComponent
} from '../../../widgets';
import { CHECK } from '../../../const';

@Component({
  selector: 'app-not-found-dialog',
  templateUrl: './not-found-dialog.component.html',
  imports: [ActionBarComponent, DialogBodyComponent, DialogFooterComponent, DialogWrapperComponent, IconButtonComponent, AlertComponent],
  standalone: true
})
export class NotFoundDialogComponent {
  protected readonly CHECK = CHECK;

  constructor(
    public dialogRef: MatDialogRef<NotFoundDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dialog: MatDialog
  ) {}

  confirm(): void {
    this.dialogRef.close(false);
  }
}

export const NOT_FOUND_DIALOG_TYPE = NotFoundDialogComponent;
