import { PersonalizationType } from '../../models';

export const NONE = {
  key: 'NONE',
  value: 'No personalization'
} as PersonalizationType;

export const BUYER = {
  key: 'BUYER',
  value: 'Buyer personalization'
} as PersonalizationType;

export const PERSONAL = {
  key: 'PERSONAL',
  value: 'Personal'
} as PersonalizationType;

export const FULL = {
  key: 'FULL',
  value: 'With identification'
} as PersonalizationType;

export const EVENT_PERSONALIZATION_TYPES = [NONE, BUYER, PERSONAL, FULL];

export const DEFAULT_PERSONALIZATION_TYPE = NONE;
