import { Injectable } from '@angular/core';
import { EVENT_PERSONALIZATION_TYPES, NONE } from '../../const/event-personalization/event-personalization';
import { PersonalizationType } from '../../models/personalization-type-model';

@Injectable({
  providedIn: 'root'
})
export class PersonalizationService {
  getDefaultPersonalizationType() {
    return NONE;
  }

  getPersonalizationObjectForKey(key: string | undefined): PersonalizationType {
    for (const type of EVENT_PERSONALIZATION_TYPES) {
      if (type.key === key) {
        return type;
      }
    }

    return this.getDefaultPersonalizationType();
  }
}
