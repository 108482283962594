import { Component, Input } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { CHECK, COPY, HIDE, Icon, QR_CODE, SHOW, SHOW_COPIED_INDICATOR_LENGTH } from '../../../const';
import { DialogHelperService } from '../../../services';
import { QR_CODE_DIALOG_TYPE } from '../../../dialogs/qr-code-dialog/qr-code-dialog.component';

@Component({
  selector: 'app-object-hidden-detail-row',
  templateUrl: './object-hidden-detail-row.component.html'
})
export class ObjectHiddenDetailRowComponent {
  protected readonly SHOW = SHOW;
  protected readonly HIDE = HIDE;
  protected readonly QR_CODE = QR_CODE;
  protected readonly COPY = COPY;
  protected readonly CHECK = CHECK;

  @Input()
  label = '';

  @Input()
  content = '';

  @Input()
  canBeScanned = false;

  @Input()
  update?: () => void;

  @Input()
  updateIcon?: Icon;

  @Input()
  updateLabel?: string;

  show = false;
  copied = false;

  constructor(
    private readonly clipboard: Clipboard,
    private readonly dialogHelperService: DialogHelperService
  ) {}

  toggleShow() {
    this.show = !this.show;
  }

  copy() {
    this.clipboard.copy(this.content);
    this.copied = true;
    setTimeout(() => {
      this.copied = false;
    }, SHOW_COPIED_INDICATOR_LENGTH);
  }

  showQrCode() {
    this.dialogHelperService.openDialog$<boolean>(
      QR_CODE_DIALOG_TYPE,
      {
        content: this.content
      },
      undefined
    );
  }
}
