export const CUSTOMER_DASHBOARD_BASE_ROUTE = '/dashboard';
export const CUSTOMER_DASHBOARD_ORDERS_ROUTE = `${CUSTOMER_DASHBOARD_BASE_ROUTE}/orders`;
export const CUSTOMER_DASHBOARD_ORDER_ROUTE = `${CUSTOMER_DASHBOARD_BASE_ROUTE}/order/:id`;
export const CUSTOMER_DASHBOARD_TICKETS_ROUTE = `${CUSTOMER_DASHBOARD_BASE_ROUTE}/tickets`;
export const CUSTOMER_DASHBOARD_BALANCE_ROUTE = `${CUSTOMER_DASHBOARD_BASE_ROUTE}/balance`;
export const CUSTOMER_DASHBOARD_EDIT_PROFILE_ROUTE = `${CUSTOMER_DASHBOARD_BASE_ROUTE}/profile/edit`;
export const CUSTOMER_DASHBOARD_PROFILE_ROUTE = `${CUSTOMER_DASHBOARD_BASE_ROUTE}/profile`;
export const CUSTOMER_DASHBOARD_CONFIRM_DEVICE_ROUTE = `${CUSTOMER_DASHBOARD_BASE_ROUTE}/confirm-device`;

export type CustomerDashboardRoute =
  | typeof CUSTOMER_DASHBOARD_BASE_ROUTE
  | typeof CUSTOMER_DASHBOARD_ORDERS_ROUTE
  | typeof CUSTOMER_DASHBOARD_ORDER_ROUTE
  | typeof CUSTOMER_DASHBOARD_TICKETS_ROUTE
  | typeof CUSTOMER_DASHBOARD_BALANCE_ROUTE
  | typeof CUSTOMER_DASHBOARD_EDIT_PROFILE_ROUTE
  | typeof CUSTOMER_DASHBOARD_PROFILE_ROUTE
  | typeof CUSTOMER_DASHBOARD_CONFIRM_DEVICE_ROUTE;
