import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { RequestService } from '../../request/request-service';
import { ApiUrlService } from '../../api-url';
import { Location } from '../../../../models/location-model';
import { Layout } from '../../../../models/layout/layout-model';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  constructor(
    private readonly requestService: RequestService,
    private readonly apiUrlService: ApiUrlService
  ) {}

  private getLayoutByEventIdPublicUrl(eventId: number): string {
    return `${this.apiUrlService.getPublicApiBasePath()}/locations/${eventId}/layout`;
  }

  private getLayoutByLocationIdUrl(locationId: number): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/locations/${locationId}/layout`;
  }

  private getLocationsUrl(): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/locations`;
  }

  private getLocationByIdUrl(locationId: number): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/locations/${locationId}`;
  }

  private copyLocationUrl(locationId: number): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/locations/copy/${locationId}`;
  }

  private deleteLocationUrl(locationId: number): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/locations/${locationId}`;
  }

  private saveLocationUrl(): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/locations`;
  }

  private getLocationByEventIdUrl(eventId: number): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/locations/${eventId}/location`;
  }

  private getLocationByEventIdUrlPublic(eventId: number): string {
    return `${this.apiUrlService.getPublicApiBasePath()}/locations/${eventId}/location`;
  }

  getLocations$(): Observable<Location[]> {
    return this.requestService.getRequest$<Location[]>(this.getLocationsUrl());
  }

  getLocationById$(locationId: number): Observable<Location> {
    return this.requestService.getRequest$<Location>(this.getLocationByIdUrl(locationId));
  }

  copyLocation$(locationId: number) {
    return this.requestService.postRequest$<Location, undefined>(this.copyLocationUrl(locationId));
  }

  deleteLocation$(locationId: number): Observable<boolean> {
    return this.requestService.deleteRequest$<boolean>(this.deleteLocationUrl(locationId));
  }

  saveLocation$(location: Location) {
    return this.requestService.postRequest$<Location, Location>(this.saveLocationUrl(), location);
  }

  updateLocation$(location: Location) {
    return this.requestService.putRequest$<Location, Location>(this.saveLocationUrl(), location);
  }

  getLayoutByEventIdPublic$(eventId: number): Observable<Layout> {
    return this.requestService.getRequest$<Layout>(this.getLayoutByEventIdPublicUrl(eventId));
  }

  getLayoutByLocationId$(locationId: number): Observable<Layout> {
    return this.requestService.getRequest$<Layout>(this.getLayoutByLocationIdUrl(locationId));
  }

  getLocationByEventId$(eventId: number): Observable<Location> {
    return this.requestService.getRequest$<Location>(this.getLocationByEventIdUrl(eventId)).pipe(
      map(result => {
        return result;
      })
    );
  }

  getLocationByEventIdPublic$(eventId: number): Observable<Location> {
    return this.requestService.getRequest$<Location>(this.getLocationByEventIdUrlPublic(eventId)).pipe(
      map(result => {
        return result;
      })
    );
  }
}
