<app-input-label
  *ngIf="!hideLabel"
  [id]="id"
  [label]="label"
  [hasError]="isRequired && !value"
  [errorMessage]="'An entry must be selected'"
  [isRequired]="isRequired"
>
</app-input-label>
<select
  [disabled]="disabled"
  (change)="onInputChange($event)"
  [name]="id"
  [id]="id"
  [class]="
    selectCss ??
    'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
  "
  autocomplete="on"
>
  <option *ngFor="let item of domain" [class]="optionCss ?? ''" [selected]="isSelected(item)" [value]="item.key">
    {{ translate ? (item.value | as: 'string' | translated) : item.value }}
  </option>
</select>
