import { Component, Input } from '@angular/core';
import { DomainHelperService } from '../../../services/domain/domain-helper-service';
import { LanguageService } from '../../../services';

@Component({
  selector: 'app-terms-and-conditions',
  standalone: true,
  imports: [],
  templateUrl: './terms-and-conditions.component.html'
})
export class TermsAndConditionsComponent {
  @Input()
  linkColor = 'black';

  @Input()
  textDecoration = 'no-underline';

  constructor(
    private readonly domainHelperService: DomainHelperService,
    private readonly languageService: LanguageService
  ) {}

  protected getTermsAndConditionsLink() {
    if (this.domainHelperService.isCHDomain()) {
      if (this.languageService.getCurrentLanguageKey().toLowerCase() === 'en') {
        return 'https://www.innovaticks.ch/terms-and-conditions.html';
      }

      return `https://www.innovaticks.ch/${this.languageService.getCurrentLanguageKey().toLowerCase()}/terms-and-conditions.html`;
    }

    if (this.languageService.getCurrentLanguageKey().toLowerCase() === 'en') {
      return 'https://www.innovaticks.org/terms-and-conditions.html';
    }

    return `https://www.innovaticks.org/${this.languageService.getCurrentLanguageKey().toLowerCase()}/terms-and-conditions.html`;
  }
}
