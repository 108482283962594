<app-dialog-wrapper [transparent]="true" dialogTitle="Could not update object" (closeClicked)="confirm()" [widthPx]="DIALOG_WIDTH_MEDIUM">
  <app-dialog-body>
    <ng-container *ngFor="let error of data.errorMessages">
      <app-alert [message]="error"></app-alert>
    </ng-container>
  </app-dialog-body>
  <app-dialog-footer>
    <app-action-bar>
      <app-icon-button buttonType="primary" label="Got it!" [icon]="CHECK" (clicked)="confirm()"></app-icon-button>
    </app-action-bar>
  </app-dialog-footer>
</app-dialog-wrapper>
