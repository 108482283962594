export const INNOVATICKS_DOCUMENTATION_BASE_LINK = 'https://www.innovaticks';
export const DOCUMENTATION_FOLDER = '/documentation';

export const VENDOR_DOCUMENTATION_LINK = 'vendor.pdf';
export const BILLS_DOCUMENTATION_LINK = 'bills.pdf';
export const EVENTS_DOCUMENTATION_LINK = 'events.pdf';
export const LAYOUTS_DOCUMENTATION_LINK = 'layouts.pdf';
export const LOCATIONS_DOCUMENTATION_LINK = 'locations.pdf';
export const PAYOUTS_DOCUMENTATION_LINK = 'payouts.pdf';
export const PRICE_CONFIGS_DOCUMENTATION_LINK = 'price_configs.pdf';
export const STATISTICS_DOCUMENTATION_LINK = 'statistics.pdf';
export const USERS_DOCUMENTATION_LINK = 'user_management.pdf';

export type DocumentationLink =
  | typeof VENDOR_DOCUMENTATION_LINK
  | typeof BILLS_DOCUMENTATION_LINK
  | typeof EVENTS_DOCUMENTATION_LINK
  | typeof LAYOUTS_DOCUMENTATION_LINK
  | typeof LOCATIONS_DOCUMENTATION_LINK
  | typeof PAYOUTS_DOCUMENTATION_LINK
  | typeof PRICE_CONFIGS_DOCUMENTATION_LINK
  | typeof STATISTICS_DOCUMENTATION_LINK
  | typeof USERS_DOCUMENTATION_LINK;
