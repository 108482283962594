<div [class]="classList">
  <div [classList]="mobileWidth + ' lg:w-1/2 p-1 text-' + textSize">
    <div class="lg:mt-4">
      <label [for]="for" [classList]="'font-bold leading-form-label-line-height text-' + labelColor">{{ label }}</label>
    </div>
  </div>
  <div [classList]="mobileWidth + ' lg:w-1/2 p-1 text-lg'">
    <ng-content></ng-content>
  </div>
</div>
