import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationService, NavigationUrlService } from '../../../index';
import { ADMIN_DASHBOARD_SIGNUP_RESEND_CONFIRMATION_WITH_EMAIL_ROUTE } from '../../../const/routes/admin-dashboard-routes';
import { WEBSITE_SIGNUP_RESEND_CONFIRMATION_WITH_EMAIL_ROUTE } from '../../../const/routes/website-routes';

@Component({
  selector: 'app-signup-confirmation',
  templateUrl: './signup-confirmation.component.html'
})
export class SignupConfirmationComponent implements OnInit {
  email!: string;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly navigationService: NavigationService,
    private readonly navigationUrlService: NavigationUrlService
  ) {}

  onRequestNewConfirmationLink() {
    if (this.navigationUrlService.isAdminModule()) {
      this.navigationService.navigate(ADMIN_DASHBOARD_SIGNUP_RESEND_CONFIRMATION_WITH_EMAIL_ROUTE, [this.email]);
    } else {
      this.navigationService.navigate(WEBSITE_SIGNUP_RESEND_CONFIRMATION_WITH_EMAIL_ROUTE, [this.email]);
    }
  }

  ngOnInit(): void {
    this.email = this.route.snapshot.paramMap.get('email')!;
  }
}
