import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import {
  ACTIVE_TICKET_ENTRY_CODE_EXCEPTION,
  ACTIVE_TICKET_TRANSFER_CODE_EXCEPTION,
  BAD_REQUEST,
  BALANCE_TOO_LOW_EXCEPTION,
  CONFLICT,
  CONNECTION_REFUSED,
  CUSTOMER_PAYOUT_ALREADY_REQUESTED_EXCEPTION,
  DELETE_EVENT_GROUPS_WITH_EVENTS_EXCEPTION,
  DELETE_EVENT_NOT_ALLOWED_EXCEPTION,
  DELETE_LAYOUT_USED_FOR_LOCATIONS_EXCEPTION,
  DELETE_LOCATION_USED_FOR_EVENTS_EXCEPTION,
  DELETE_PRICE_CONFIG_USED_FOR_SECTION_EXCEPTION,
  DELETE_PRICE_WITH_ACTIVE_ORDERS_EXCEPTION,
  EDIT_LOCATION_USED_FOR_EVENT_WITH_ORDERS_EXCEPTION,
  EDIT_LOCATION_USED_FOR_PUBLISHED_EVENT_EXCEPTION,
  EDIT_PRICE_WITH_ACTIVE_ORDERS_EXCEPTION,
  ERR_RESOURCE_IS_USED,
  ERROR_KEY_MESSAGE_MAP,
  EVENT_LOCATION_CHANGED_NOT_ALLOWED_EXCEPTION,
  EVENT_PERSONALIZATION_CHANGED_NOT_ALLOWED_EXCEPTION,
  EVENT_START_CHANGED_NOT_ALLOWED_EXCEPTION,
  EVENT_START_IN_PAST_EXCEPTION,
  FORBIDDEN,
  INTERNAL_SERVER_ERROR,
  LAYOUT_CHANGED_NOT_ALLOWED_EXCEPTION,
  NOT_ENOUGH_SEATS_NEXT_TO_EACH_OTHER_EXCEPTION,
  NOT_FOUND,
  ORDER_ENTRY_HISTORY_ALREADY_IN_CART_EXCEPTION,
  PUBLISH_EVENT_FOR_ORGANIZER_NOT_APPROVED_EXCEPTION,
  SEAT_ALREADY_BOOKED_EXCEPTION,
  SESSION_EXPIRED_EXCEPTION,
  TOO_MANY_SEATS_SELECTED_EXCEPTION,
  UNAUTHORIZED,
  USER_HAS_NO_DEVICE_EXCEPTION,
  VALIDATION_ERROR,
  VALIDATION_KEY_MESSAGE_MAP
} from '../../const';
import { DialogHelperService } from '../dialog';
import { ApiError, ApiSubError } from '../../models';
import { LocalStorageService } from '../local-storage';
import { NavigationService, NavigationUrlService } from '../navigation';
import { SnackBarService } from '../snackbar';
import { SNACKBAR_TYPES } from '../../widgets';
import {
  CREATE_ERROR_DIALOG_TYPE,
  DELETE_ERROR_DIALOG_TYPE,
  FORBIDDEN_DIALOG_TYPE,
  GENERIC_ERROR_DIALOG_TYPE,
  INTERNAL_SERVER_ERROR_DIALOG_TYPE,
  LOGIN_DIALOG_TYPE,
  NOT_FOUND_DIALOG_TYPE,
  SERVER_IS_DOWN_DIALOG_TYPE,
  UPDATE_ERROR_DIALOG
} from '../../dialogs';
import { CUSTOMER_DASHBOARD_BASE_ROUTE } from '../../const/routes/customer-dashboard-routes';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  private static readonly UPDATE_ERRORS: string[] = [
    EDIT_LOCATION_USED_FOR_PUBLISHED_EVENT_EXCEPTION,
    PUBLISH_EVENT_FOR_ORGANIZER_NOT_APPROVED_EXCEPTION,
    EVENT_START_IN_PAST_EXCEPTION,
    EVENT_START_CHANGED_NOT_ALLOWED_EXCEPTION,
    EVENT_LOCATION_CHANGED_NOT_ALLOWED_EXCEPTION,
    EVENT_PERSONALIZATION_CHANGED_NOT_ALLOWED_EXCEPTION,
    LAYOUT_CHANGED_NOT_ALLOWED_EXCEPTION,
    DELETE_PRICE_CONFIG_USED_FOR_SECTION_EXCEPTION,
    DELETE_LAYOUT_USED_FOR_LOCATIONS_EXCEPTION,
    DELETE_LOCATION_USED_FOR_EVENTS_EXCEPTION,
    DELETE_EVENT_GROUPS_WITH_EVENTS_EXCEPTION,
    ORDER_ENTRY_HISTORY_ALREADY_IN_CART_EXCEPTION,
    DELETE_PRICE_WITH_ACTIVE_ORDERS_EXCEPTION,
    EDIT_PRICE_WITH_ACTIVE_ORDERS_EXCEPTION,
    EDIT_LOCATION_USED_FOR_EVENT_WITH_ORDERS_EXCEPTION,
    ACTIVE_TICKET_TRANSFER_CODE_EXCEPTION,
    ACTIVE_TICKET_ENTRY_CODE_EXCEPTION
  ];

  private static readonly SNACKBAR_ERRORS: string[] = [
    CUSTOMER_PAYOUT_ALREADY_REQUESTED_EXCEPTION,
    BALANCE_TOO_LOW_EXCEPTION,
    NOT_ENOUGH_SEATS_NEXT_TO_EACH_OTHER_EXCEPTION,
    SEAT_ALREADY_BOOKED_EXCEPTION,
    TOO_MANY_SEATS_SELECTED_EXCEPTION
  ];

  constructor(
    private readonly translateService: TranslateService,
    private readonly dialogHelperService: DialogHelperService,
    private readonly localStorageService: LocalStorageService,
    private readonly navigationService: NavigationService,
    private readonly snackBarService: SnackBarService,
    private readonly router: Router,
    private readonly navigationUrlService: NavigationUrlService
  ) {}

  private openForbiddenDialog$(): Observable<boolean> {
    const dialog$ = this.dialogHelperService.openDialog$<boolean>(FORBIDDEN_DIALOG_TYPE, undefined);
    if (dialog$) {
      return dialog$;
    }

    return of(false);
  }

  private openNotFoundDialog$(): Observable<boolean> {
    const dialog$ = this.dialogHelperService.openDialog$<boolean>(NOT_FOUND_DIALOG_TYPE, undefined);
    if (dialog$) {
      return dialog$;
    }

    return of(false);
  }

  private openInternalServerErrorDialog$(): Observable<boolean> {
    const dialog$ = this.dialogHelperService.openDialog$<boolean>(INTERNAL_SERVER_ERROR_DIALOG_TYPE, undefined);
    if (dialog$) {
      return dialog$;
    }

    return of(false);
  }

  private openServerIsDownDialog$(): Observable<boolean> {
    const dialog$ = this.dialogHelperService.openDialog$<boolean>(SERVER_IS_DOWN_DIALOG_TYPE, undefined);
    if (dialog$) {
      return dialog$;
    }

    return of(false);
  }

  private openGenericErrorPopUp$(): Observable<boolean> {
    const dialog$ = this.dialogHelperService.openDialog$<boolean>(GENERIC_ERROR_DIALOG_TYPE, undefined);
    if (dialog$) {
      return dialog$;
    }

    return of(false);
  }

  private extractError(error: HttpErrorResponse): ApiError {
    return error.error as ApiError;
  }

  private translateMessage(errorKey: string, messageMap: Map<string, string>) {
    const message: string | undefined = messageMap.get(errorKey);
    if (message) {
      return (this.translateService.instant(message) as string) ?? message;
    }
    throw new Error(`Api Error Key-Message mapping not initialized for Key: ${errorKey}`);
  }

  private getTranslatedMessageForValidationError(errorKey: string): string {
    return this.translateMessage(errorKey, VALIDATION_KEY_MESSAGE_MAP);
  }

  private getTranslatedMessageForExceptionError(errorKey: string) {
    return this.translateMessage(errorKey, ERROR_KEY_MESSAGE_MAP);
  }

  private handleValidationError$(validationErrors: ApiSubError[]): Observable<boolean> {
    const errorMessages: string[] = [];
    for (const subError of validationErrors) {
      errorMessages.push(this.getTranslatedMessageForValidationError(subError.code));
    }

    const dialog$ = this.dialogHelperService.openDialog$<boolean>(CREATE_ERROR_DIALOG_TYPE, { errorMessages });

    if (dialog$) {
      return dialog$;
    }

    return of(false);
  }

  private handleError400$(error: HttpErrorResponse): Observable<boolean> {
    const apiError: ApiError = this.extractError(error);
    switch (apiError.message) {
      case VALIDATION_ERROR:
        return this.handleValidationError$(apiError.subErrors);
      case ERR_RESOURCE_IS_USED:
        return this.handleResourceIsUsedError(apiError);
      case DELETE_EVENT_NOT_ALLOWED_EXCEPTION:
        return this.handleDeleteEventNotAllowedError();
      case SESSION_EXPIRED_EXCEPTION:
        this.localStorageService.removeCartSessionId();

        return of(false);
      case USER_HAS_NO_DEVICE_EXCEPTION:
        this.navigationService.navigate(CUSTOMER_DASHBOARD_BASE_ROUTE);

        return of(false);
      default:
        console.log(apiError.message);
        if (ErrorService.UPDATE_ERRORS.includes(apiError.message)) {
          this.handleUpdateError(apiError);
        }
        if (ErrorService.SNACKBAR_ERRORS.includes(apiError.message)) {
          this.handleSnackbarError(apiError);
        }
        throw new Error('Unhandled Rest-Exception Error');
    }
  }

  private handleResourceIsUsedError(apiError: ApiError) {
    const dialog$ = this.dialogHelperService.openDialog$<boolean>(DELETE_ERROR_DIALOG_TYPE, {
      violatedObjectTable: apiError.subErrors[0]!.violatedObjectTable,
      objectReferenceTable: apiError.subErrors[0]!.objectReferenceTable
    });

    if (dialog$) {
      return dialog$;
    }

    return of(false);
  }

  private handleDeleteEventNotAllowedError() {
    const dialog$ = this.dialogHelperService.openDialog$<boolean>(DELETE_ERROR_DIALOG_TYPE, {
      violatedObjectTable: 'Event',
      objectReferenceTable: 'Order'
    });

    if (dialog$) {
      return dialog$;
    }

    return of(false);
  }

  private handleUpdateError(apiError: ApiError) {
    return this.dialogHelperService.openDialog$<Observable<boolean>>(UPDATE_ERROR_DIALOG, {
      errorMessages: [this.getTranslatedMessageForExceptionError(apiError.message)]
    });
  }

  private handleSnackbarError(apiError: ApiError) {
    this.snackBarService.showSnackbarMessage(this.getTranslatedMessageForExceptionError(apiError.message), SNACKBAR_TYPES.ERROR);

    return of(false);
  }

  private handleError409$(error: HttpErrorResponse): Observable<boolean> {
    const apiError: ApiError = this.extractError(error);
    switch (apiError.message) {
      case SEAT_ALREADY_BOOKED_EXCEPTION:
        this.snackBarService.showSnackbarMessage(
          'At least one seat is not available anymore. Please reload the page and select again.',
          SNACKBAR_TYPES.ERROR
        );

        return of(false);
      default:
        if (ErrorService.UPDATE_ERRORS.includes(apiError.message)) {
          const dialog$ = this.dialogHelperService.openDialog$<boolean>(UPDATE_ERROR_DIALOG, {
            errorMessages: [this.getTranslatedMessageForExceptionError(apiError.message)]
          });

          if (dialog$) {
            return dialog$;
          }

          return of(false);
        }
        throw new Error('Unhandled Rest-Exception Error');
    }
  }

  getTranslatedValidationErrors(error: HttpErrorResponse): string[] {
    const subErrors: ApiSubError[] = this.extractError(error).subErrors;
    const errors: string[] = [];
    for (const subError of subErrors) {
      errors.push(this.getTranslatedMessageForValidationError(subError.code));
    }

    return errors;
  }

  // eslint-disable-next-line complexity
  handleHttpErrorResponse$(error: HttpErrorResponse): Observable<boolean> {
    try {
      switch (error.status) {
        case UNAUTHORIZED:
          return this.handleUnauthorizedError();
        case BAD_REQUEST:
          return this.handleError400$(error);
        case CONFLICT:
          return this.handleError409$(error);
        case FORBIDDEN:
          return this.openForbiddenDialog$();
        case NOT_FOUND:
          return this.openNotFoundDialog$();
        case INTERNAL_SERVER_ERROR:
          return this.openInternalServerErrorDialog$();
        case CONNECTION_REFUSED:
          return this.openServerIsDownDialog$();
        default:
          return this.openGenericErrorPopUp$();
      }
    } catch (error) {
      console.error(error);

      return of(false);
    }
  }

  handleUnauthorizedError() {
    const dialog$ = this.dialogHelperService.openDialog$<boolean>(
      LOGIN_DIALOG_TYPE,
      this.navigationUrlService.getModuleFromUrl(this.router.routerState.snapshot.url),
      undefined
    );

    if (dialog$) {
      return dialog$;
    }

    return of(false);
  }
}
