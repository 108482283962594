import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass } from '@angular/common';
import { DialogHeaderComponent } from '../dialog-header';
import { IconButtonComponent } from '../../form-elements';
import { H1Component } from '../../tags';
import {
  DIALOG_WIDTH_DEFAULT,
  DIALOG_WIDTH_EVEN_WIDER,
  DIALOG_WIDTH_MEDIUM,
  DIALOG_WIDTH_WIDE,
  DIALOG_WIDTH_WIDEST,
  DialogWidth
} from '../../../const';

@Component({
  selector: 'app-dialog-wrapper',
  templateUrl: './dialog-wrapper.component.html',
  imports: [DialogHeaderComponent, IconButtonComponent, H1Component, NgClass],
  standalone: true
})
export class DialogWrapperComponent {
  protected readonly DIALOG_WIDTH_DEFAULT = DIALOG_WIDTH_DEFAULT;
  protected readonly DIALOG_WIDTH_MEDIUM = DIALOG_WIDTH_MEDIUM;
  protected readonly DIALOG_WIDTH_WIDE = DIALOG_WIDTH_WIDE;
  protected readonly DIALOG_WIDTH_WIDEST = DIALOG_WIDTH_WIDEST;
  protected readonly DIALOG_WIDTH_EVEN_WIDER = DIALOG_WIDTH_EVEN_WIDER;

  @Input({ required: true })
  dialogTitle!: string;
  @Input()
  transparent = false;
  @Input()
  widthPx: DialogWidth = DIALOG_WIDTH_DEFAULT;

  @Output()
  closeClicked = new EventEmitter<void>();

  onCloseClicked() {
    this.closeClicked.emit();
  }
}
