import { Inject, Injectable } from '@angular/core';
import { API_URL } from '../../../../../tokens';

@Injectable({
  providedIn: 'root'
})
export class ApiUrlService {
  private readonly publicApiBasePath = `${this.apiBasePath}/public`;
  private readonly internalApiBasePath = `${this.apiBasePath}/internal`;
  private readonly adminApiBasePath = `${this.apiBasePath}/admin`;
  private readonly backofficeApiBasePath = `${this.apiBasePath}/backoffice`;

  constructor(@Inject(API_URL) private readonly apiBasePath: string) {}

  getPublicApiBasePath(): string {
    return this.publicApiBasePath;
  }

  getInternalApiBasePath(): string {
    return this.internalApiBasePath;
  }

  getAdminApiBasePath(): string {
    return this.adminApiBasePath;
  }

  getBackofficeBasePath(): string {
    return this.backofficeApiBasePath;
  }
}
