import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './modules/website/pages/error/page_not_found/page-not-found.component';

export const routes: Routes = [
  {
    path: 'admin',
    loadChildren: async () => import('./modules/admin_dashboard/admin-dashboard.module').then<any>(m => m.AdminDashboardModule)
  },
  {
    path: 'admin',
    redirectTo: 'admin',
    data: { skipRouteLocalization: { localizeRedirectTo: true } }
  },
  {
    path: 'backoffice',
    loadChildren: async () => import('./modules/backoffice/backoffice.module').then<any>(m => m.BackofficeModule)
  },
  {
    path: 'backoffice',
    redirectTo: 'backoffice',
    data: { skipRouteLocalization: { localizeRedirectTo: true } }
  },
  {
    path: 'dashboard',
    loadChildren: async () => import('./modules/customer_dashboard/customer-dashboard.module').then<any>(m => m.CustomerDashboardModule)
  },
  {
    path: 'dashboard',
    redirectTo: 'dashboard',
    data: { skipRouteLocalization: { localizeRedirectTo: true } }
  },
  {
    path: '',
    loadChildren: async () => import('./modules/website/website.module').then<any>(m => m.WebsiteModule)
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
