import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DIALOG_WIDTH_DEFAULT, X_MARK } from '../../const';

@Component({
  selector: 'app-qr-code-dialog',
  templateUrl: './qr-code-dialog.component.html'
})
export class QrCodeDialogComponent {
  protected readonly X_MARK = X_MARK;
  protected readonly DIALOG_WIDTH_DEFAULT = DIALOG_WIDTH_DEFAULT;

  constructor(
    public dialogRef: MatDialogRef<QrCodeDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      content: string;
    }
  ) {}

  protected closeDialog(): void {
    this.dialogRef.close(false);
  }
}

export const QR_CODE_DIALOG_TYPE = QrCodeDialogComponent;
