export const WEBSITE_BASE_ROUTE = '/';
export const WEBSITE_HOME_ROUTE = `${WEBSITE_BASE_ROUTE}/home`;
export const WEBSITE_EVENT_ROUTE = `${WEBSITE_BASE_ROUTE}/event/:id`;
export const WEBSITE_EVENT_GROUP_ROUTE = `${WEBSITE_BASE_ROUTE}/event-group/:id`;
export const WEBSITE_VENDOR_ROUTE = `${WEBSITE_BASE_ROUTE}/vendor/:id`;
export const WEBSITE_LOGIN_ROUTE = `${WEBSITE_BASE_ROUTE}/login`;
export const WEBSITE_SIGNUP_ROUTE = `${WEBSITE_BASE_ROUTE}/signup`;
export const WEBSITE_SIGNUP_EMAIL_CONFIRMATION_ROUTE = `${WEBSITE_BASE_ROUTE}/signup/email-confirmation`;
export const WEBSITE_SIGNUP_EMAIL_CONFIRMATION_FAILED_ROUTE = `${WEBSITE_BASE_ROUTE}/signup/email-confirmation/failed`;
export const WEBSITE_SIGNUP_EMAIL_CONFIRMATION_SUCCESSFUL_ROUTE = `${WEBSITE_BASE_ROUTE}/signup/email-confirmation/successful`;
export const WEBSITE_SIGNUP_SUCCESSFUL_ROUTE = `${WEBSITE_BASE_ROUTE}/signup/successful/:email`;
export const WEBSITE_SIGNUP_RESEND_CONFIRMATION_ROUTE = `${WEBSITE_BASE_ROUTE}/signup/confirmation/resend`;
export const WEBSITE_SIGNUP_RESEND_CONFIRMATION_WITH_EMAIL_ROUTE = `${WEBSITE_BASE_ROUTE}/signup/confirmation/resend/:email`;
export const WEBSITE_SIGNUP_RESEND_CONFIRMATION_SUCCESS_ROUTE = `${WEBSITE_BASE_ROUTE}/signup/confirmation/resend/confirmation/:email`;
export const WEBSITE_RESET_PASSWORD_CONFIRMATION_ROUTE = `${WEBSITE_BASE_ROUTE}/reset-password/confirmation/:email`;
export const WEBSITE_RESET_PASSWORD_ROUTE = `${WEBSITE_BASE_ROUTE}/reset-password`;
export const WEBSITE_CHANGE_PASSWORD_ROUTE = `${WEBSITE_BASE_ROUTE}/change-password`;
export const WEBSITE_CHANGE_PASSWORD_FAILED_ROUTE = `${WEBSITE_BASE_ROUTE}/change-password/failed`;
export const WEBSITE_CART_ROUTE = `${WEBSITE_BASE_ROUTE}/cart`;
export const WEBSITE_CHANGE_PASSWORD_CONFIRMATION_ROUTE = `${WEBSITE_BASE_ROUTE}/change-password/confirmation`;

export type WebsiteRoute =
  | typeof WEBSITE_BASE_ROUTE
  | typeof WEBSITE_HOME_ROUTE
  | typeof WEBSITE_EVENT_ROUTE
  | typeof WEBSITE_EVENT_GROUP_ROUTE
  | typeof WEBSITE_VENDOR_ROUTE
  | typeof WEBSITE_LOGIN_ROUTE
  | typeof WEBSITE_SIGNUP_ROUTE
  | typeof WEBSITE_SIGNUP_EMAIL_CONFIRMATION_ROUTE
  | typeof WEBSITE_SIGNUP_EMAIL_CONFIRMATION_FAILED_ROUTE
  | typeof WEBSITE_SIGNUP_EMAIL_CONFIRMATION_SUCCESSFUL_ROUTE
  | typeof WEBSITE_SIGNUP_SUCCESSFUL_ROUTE
  | typeof WEBSITE_SIGNUP_RESEND_CONFIRMATION_ROUTE
  | typeof WEBSITE_SIGNUP_RESEND_CONFIRMATION_WITH_EMAIL_ROUTE
  | typeof WEBSITE_SIGNUP_RESEND_CONFIRMATION_SUCCESS_ROUTE
  | typeof WEBSITE_RESET_PASSWORD_CONFIRMATION_ROUTE
  | typeof WEBSITE_RESET_PASSWORD_ROUTE
  | typeof WEBSITE_CHANGE_PASSWORD_ROUTE
  | typeof WEBSITE_CHANGE_PASSWORD_FAILED_ROUTE
  | typeof WEBSITE_CART_ROUTE
  | typeof WEBSITE_CHANGE_PASSWORD_CONFIRMATION_ROUTE;
