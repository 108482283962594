import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-row',
  templateUrl: './form-row.component.html'
})
export class FormRowComponent implements OnInit {
  @Input()
  label = '';

  @Input()
  for = '';

  @Input()
  stickLabelToTop = false;

  classList = '';

  ngOnInit() {
    this.classList = `flex grow gap-8 ${this.stickLabelToTop ? 'items-start' : 'items-end'}`;
  }
}
