import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { getLanguageByLocale, Language } from '../../const';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  constructor(
    private readonly translateService: TranslateService,
    private readonly localizeRouterService: LocalizeRouterService
  ) {}

  getLanguageFromUrl(url: string): Language | undefined {
    let language: Language | undefined;
    const segments = url.split('/');
    if (segments.length > 0) {
      const firstSegment = segments[0];
      if (firstSegment !== undefined) {
        try {
          language = getLanguageByLocale(firstSegment);
        } catch (error) {
          if (segments.length > 1) {
            const secondSegment = segments[1];
            if (secondSegment !== undefined) {
              try {
                language = getLanguageByLocale(secondSegment);
              } catch (error) {
                language = undefined;
              }
            }
          }
        }
      }
    }

    return language;
  }

  changeLanguage(language: Language) {
    this.localizeRouterService.changeLanguage(language.locale);
  }

  getCurrentLanguageKey(): string {
    return this.getCurrentLanguage().key;
  }

  getCurrentLanguage(): Language {
    return getLanguageByLocale(this.translateService.currentLang);
  }

  defaultLanguageOnApplicationInit(initUrl: string) {
    const requestedLanguage = this.getLanguageFromUrl(initUrl);
    const browserLocale = this.translateService.getBrowserLang();
    if (browserLocale && !requestedLanguage) {
      try {
        const browserLanguage = getLanguageByLocale(browserLocale);
        this.changeLanguage(browserLanguage);
      } catch {
        console.info('Browser language not available, using default language instead.');
      }
    }
  }
}
