export const UNIQUE_PRICE_CONFIG_NAME_CONSTRAINT_ERROR = 'A price config with this name already exists.';

export const UNIQUE_LAYOUT_NAME_CONSTRAINT_ERROR = 'A layout with this name already exists.';

export const UNIQUE_LOCATION_NAME_CONSTRAINT_ERROR = 'A location with this internal name already exists.';

export const UNIQUE_EVENT_NAME_CONSTRAINT_ERROR = 'An event or a group with this internal name already exists.';
export const DELETE_EVENT_NOT_ALLOWED_ERROR = 'This event has been published or has active orders. It can not be deleted.';
export const EVENT_START_CHANGED_NOT_ALLOWED_ERROR =
  'This event has been published or has active orders. The start of the event can not be changed.';
export const EVENT_START_IN_PAST_ERROR = 'The start of the event must not be in the past';
export const EVENT_LOCATION_CHANGED_NOT_ALLOWED_ERROR =
  'This event has been published or has active orders. The location of the event can not be changed.';
export const EVENT_PERSONALIZATION_CHANGED_NOT_ALLOWED_ERROR =
  'This event has been published or has active orders. The personalization type of the event can not be changed.';
export const PUBLISH_EVENT_FOR_ORGANIZER_NOT_APPROVED_ERROR =
  'This organizer has not yet been approved. It is only possible to publish events after the organizer has been approved.';
export const EDIT_LOCATION_USED_FOR_PUBLISHED_EVENT_ERROR =
  'This location is used for a published event or an event with active orders. The layout of this location can not be changed.';
export const EDIT_LOCATION_USED_FOR_EVENT_WITH_ORDERS_ERROR =
  'This location is used for an event with orders. The layout of this location can not be changed.';
export const LAYOUT_CHANGED_NOT_ALLOWED_ERROR =
  'This layout is used for events that have been published or have active orders. It is not possible to edit this layout';

export const DELETE_PRICE_CONFIG_USED_FOR_SECTION_ERROR = 'This price config is used for layout sections. It can not be deleted.';
export const DELETE_PRICE_WITH_ACTIVE_ORDERS_ERROR = 'This price is used for active orders. It can not be deleted.';
export const DELETE_LAYOUT_USED_FOR_LOCATIONS_ERROR = 'This layout is used for a location. It can not be deleted.';
export const DELETE_LOCATION_USED_FOR_EVENTS_ERROR = 'This location is used for an event. It can not be deleted.';
export const DELETE_EVENT_GROUPS_WITH_EVENTS_ERROR = 'This event group contains at least one event. It can not be deleted.';

export const ADMIN_INVITATION_EMAIL_NOT_REGISTERED_CONSTRAINT_ERROR = 'This user with this email is already registered.';
export const NO_PENDING_INVITATION_CONSTRAINT_ERROR = 'User with this email is already invited';
export const SESSION_EXPIRED_ERROR = 'This cart session has expired';
export const ORDER_ENTRY_HISTORY_ALREADY_IN_CART_ERROR =
  'This ticket is currently in a cart. Resale can not be stopped at the moment. Please try again later.';
export const EDIT_PRICE_WITH_ACTIVE_ORDERS_ERROR = 'This price is used for active orders. The price can not be changed';
export const CUSTOMER_PAYOUT_ALREADY_REQUESTED_ERROR = 'A payout has already been requested';
export const BALANCE_TOO_LOW_ERROR = 'Your balance is not high enough for this purchase';
export const NOT_ENOUGH_SEATS_NEXT_TO_EACH_OTHER_ERROR = 'There are not enough seats next to each other.';
export const SEAT_ALREADY_BOOKED_ERROR = 'At least one seat is not available anymore. Please reload the page and select again.';
export const TOO_MANY_SEATS_SELECTED_ERROR = 'You selected too many seats.';
export const ACTIVE_TICKET_TRANSFER_CODE_ERROR = 'There is an active code to transfer the ticket. It can not be resold at the moment.';
export const ACTIVE_TICKET_ENTRY_CODE_ERROR = 'There is an active entry code for this ticket. It can not be resold at the moment.';
