<app-auth-header />

<app-auth-main-page-content>
  <app-h1 [title]="'Account Confirmation'"></app-h1>

  <h1>Account Confirmation</h1>
  <p>Email successfully confirmed.</p>
  <p class="mb-4" *ngIf="!confirmMobileNumber">You can now login to your account.</p>
  <p class="my-4" *ngIf="confirmMobileNumber">Please confirm your mobile number.</p>

  <app-error-container *ngIf="deviceConfirmationFailed" [errorMessage]="'The code you entered is not valid. Please check your code'" />
  <app-success-container *ngIf="deviceConfirmationSuccessful" [successMessage]="'Your mobile number has been confirmed.'" />

  <ng-container *ngIf="!deviceConfirmationSuccessful">
    <app-form-row *ngIf="confirmMobileNumber" [label]="'Confirmation Code * '" [for]="'confirmationCode'">
      <app-text-input [id]="'confirmationCode'" [(value)]="confirmationCode" [placeholder]="'Confirmation Code'" />
    </app-form-row>

    <app-action-bar>
      <app-icon-button *ngIf="!confirmMobileNumber" buttonType="primary" label="Login" (clicked)="onGoToLogin()"></app-icon-button>
      <app-icon-button
        *ngIf="confirmMobileNumber"
        buttonType="primary"
        label="Submit"
        (clicked)="confirmPhone()"
        [enabled]="!!confirmationCode"
      ></app-icon-button>
    </app-action-bar>
  </ng-container>
</app-auth-main-page-content>
