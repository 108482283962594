<div class="flex items-center gap-2">
  <app-icon class="bg-transparent text-white" [icon]="LANGUAGE"></app-icon>
  <app-select-input
    [id]="'language'"
    [translate]="true"
    [isRequired]="true"
    [hideLabel]="true"
    [(value)]="selectedLanguage"
    [domain]="AVAILABLE_LANGUAGES"
    (valueChange)="onLanguageChange()"
    selectCss="bg-transparent text-white font-semibold"
    optionCss="text-black"
  ></app-select-input>
</div>
