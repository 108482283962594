import { Component, Input } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { NavigationService } from '../../../services';
import { WebsiteRoute } from '../../../const/routes/website-routes';
import { CustomerDashboardRoute } from '../../../const/routes/customer-dashboard-routes';
import { AdminDashboardRoute } from '../../../const/routes/admin-dashboard-routes';
import { BackofficeRoute } from '../../../const/routes/backoffice-routes';

@Component({
  selector: 'app-object-detail-row',
  templateUrl: './object-detail-row.component.html'
})
export class ObjectDetailRowComponent {
  @Input()
  fullWidth = false;

  @Input()
  label = '';

  @Input()
  route?: WebsiteRoute | CustomerDashboardRoute | AdminDashboardRoute | BackofficeRoute | undefined;

  @Input()
  routeParams: (string | number)[] = [];

  @Input()
  extras?: NavigationExtras;

  @Input()
  link?: string | undefined;

  @Input()
  openInNewTab = false;

  @Input()
  content = '';

  @Input()
  infoText = '';

  constructor(private readonly navigation: NavigationService) {}

  navigate(event: Event) {
    event.preventDefault();
    if (this.route) {
      if (this.extras) {
        this.navigation.navigateWithExtras(this.route, this.routeParams, this.extras);
      } else {
        this.navigation.navigate(this.route, this.routeParams);
      }
    }
  }
}
