export interface User {
  id: number;
  mobileNumber: string;
  mobileNumberConfirmed: boolean;
  iban?: string;
  firstName?: string;
  lastName?: string;
  email: string;
  language: string;
  street?: string;
  streetNumber?: string;
  zipCode?: string;
  city?: string;
  country?: string;
}
