export const BACKOFFICE_BASE_ROUTE = '/backoffice';
export const BACKOFFICE_OVERVIEW_ROUTE = `${BACKOFFICE_BASE_ROUTE}/overview`;
export const BACKOFFICE_ORGANIZERS_ROUTE = `${BACKOFFICE_BASE_ROUTE}/organizers`;
export const BACKOFFICE_ORGANIZER_ROUTE = `${BACKOFFICE_BASE_ROUTE}/organizers/:id`;
export const BACKOFFICE_EVENT_PAYOUTS_ROUTE = `${BACKOFFICE_BASE_ROUTE}/event-payouts`;
export const BACKOFFICE_CUSTOMER_PAYOUTS_ROUTE = `${BACKOFFICE_BASE_ROUTE}/customer-payouts`;
export const BACKOFFICE_VENDOR_BILLS_ROUTE = `${BACKOFFICE_BASE_ROUTE}/vendor-bills`;
export const BACKOFFICE_EVENT_BOOKINGS_ROUTE = `${BACKOFFICE_BASE_ROUTE}/event-bookings`;
export const BACKOFFICE_LOGIN_ROUTE = `${BACKOFFICE_BASE_ROUTE}/login`;

export type BackofficeRoute =
  | typeof BACKOFFICE_BASE_ROUTE
  | typeof BACKOFFICE_OVERVIEW_ROUTE
  | typeof BACKOFFICE_ORGANIZERS_ROUTE
  | typeof BACKOFFICE_ORGANIZER_ROUTE
  | typeof BACKOFFICE_EVENT_PAYOUTS_ROUTE
  | typeof BACKOFFICE_CUSTOMER_PAYOUTS_ROUTE
  | typeof BACKOFFICE_VENDOR_BILLS_ROUTE
  | typeof BACKOFFICE_EVENT_BOOKINGS_ROUTE
  | typeof BACKOFFICE_LOGIN_ROUTE;
