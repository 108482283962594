import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-form-address',
  templateUrl: './form-address.component.html'
})
export class FormAddressComponent {
  @Input()
  label = 'Address';
  @Input()
  for = '';
}
