import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { NgIf } from '@angular/common';
import { ApiError, Organizer } from '../../models';
import { AuthService, LocalStorageService, NavigationService, NavigationUrlService } from '../../services';
import {
  ACCOUNT_NOT_ACTIVE_EXCEPTION,
  ADMIN_DASHBOARD,
  BACKOFFICE,
  CUSTOMER_DASHBOARD,
  DIALOG_WIDTH_MEDIUM,
  KEY,
  LOGIN,
  ModuleType,
  USER,
  X_MARK
} from '../../const';
import {
  ActionBarComponent,
  AlertComponent,
  DialogBodyComponent,
  DialogFooterComponent,
  DialogWrapperComponent,
  EMAIL,
  IconButtonComponent,
  LineLayoutComponent,
  LinkComponent,
  PASSWORD,
  TextInputComponent
} from '../../widgets';
import {
  ADMIN_DASHBOARD_RESET_PASSWORD_ROUTE,
  ADMIN_DASHBOARD_SIGNUP_RESEND_CONFIRMATION_WITH_EMAIL_ROUTE,
  ADMIN_DASHBOARD_SIGNUP_ROUTE
} from '../../const/routes/admin-dashboard-routes';
import {
  WEBSITE_RESET_PASSWORD_ROUTE,
  WEBSITE_SIGNUP_RESEND_CONFIRMATION_WITH_EMAIL_ROUTE,
  WEBSITE_SIGNUP_ROUTE
} from '../../const/routes/website-routes';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  imports: [
    LinkComponent,
    IconButtonComponent,
    DialogFooterComponent,
    TextInputComponent,
    LineLayoutComponent,
    AlertComponent,
    DialogBodyComponent,
    DialogWrapperComponent,
    ActionBarComponent,
    NgIf
  ],
  standalone: true
})
export class LoginDialogComponent {
  protected readonly DIALOG_WIDTH_MEDIUM = DIALOG_WIDTH_MEDIUM;

  protected email?: string;
  protected password?: string;
  protected formValid = false;
  protected loginFailed = false;

  protected readonly LOGIN = LOGIN;
  protected readonly X_MARK = X_MARK;
  protected readonly KEY = KEY;
  protected readonly USER = USER;
  protected readonly PASSWORD = PASSWORD;
  protected readonly EMAIL = EMAIL;
  protected readonly BACKOFFICE = BACKOFFICE;

  constructor(
    private readonly navigation: NavigationService,
    private readonly authService: AuthService,
    private readonly localStorageService: LocalStorageService,
    private readonly navigationUrlService: NavigationUrlService,
    public dialogRef: MatDialogRef<LoginDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public moduleType: ModuleType
  ) {}

  protected closeDialog(): void {
    this.dialogRef.close(false);
  }

  validateForm() {
    this.formValid = this.email !== undefined && this.password !== undefined;
  }

  login(): void {
    if (this.formValid) {
      this.authService.login$({ email: this.email!, password: this.password!, module: this.moduleType }).subscribe({
        next: (response: HttpResponse<Organizer>) => {
          const authToken = response.headers.get('Authorization');
          if (authToken) {
            this.localStorageService.storeAuthenticationToken(authToken);
            this.dialogRef.close(true);
          }
        },
        error: (e: HttpErrorResponse) => {
          if ((e.error as ApiError).subErrors?.[0]?.message === ACCOUNT_NOT_ACTIVE_EXCEPTION) {
            this.dialogRef.close(false);

            if (!this.email) {
              return;
            }

            const path = this.navigationUrlService.isAdminModule()
              ? ADMIN_DASHBOARD_SIGNUP_RESEND_CONFIRMATION_WITH_EMAIL_ROUTE
              : WEBSITE_SIGNUP_RESEND_CONFIRMATION_WITH_EMAIL_ROUTE;

            this.navigation.navigateWithExtras(path, [this.email], {
              queryParams: { showActiveError: true }
            });
          }
          this.loginFailed = true;
        }
      });
    }
  }

  openResetPassword() {
    this.dialogRef.close(false);
    if (this.moduleType === ADMIN_DASHBOARD) {
      this.navigation.navigate(ADMIN_DASHBOARD_RESET_PASSWORD_ROUTE);
    } else if (this.moduleType === CUSTOMER_DASHBOARD) {
      this.navigation.navigate(WEBSITE_RESET_PASSWORD_ROUTE);
    }
  }

  openSignUp() {
    this.dialogRef.close(false);
    if (this.moduleType === ADMIN_DASHBOARD) {
      this.navigation.navigate(ADMIN_DASHBOARD_SIGNUP_ROUTE);
    } else if (this.moduleType === CUSTOMER_DASHBOARD) {
      this.navigation.navigate(WEBSITE_SIGNUP_ROUTE);
    }
  }
}

export const LOGIN_DIALOG_TYPE = LoginDialogComponent;
