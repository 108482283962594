<app-input-label
  [id]="id"
  [hasError]="values.length < minValues"
  [errorMessage]="'Please select at least ' + minValues + ' object(s)'"
  [isRequired]="true"
></app-input-label>

<div class="text-sm block w-full p-2.5 border border-button-blue-darkest rounded-lg pr-1">
  <div class="flex grow" (click)="spanClickedAction()" tabindex="0" role="button" (keydown)="spanClickedAction()">
    <div class="flex flex-1">
      <span>{{ label }}</span>
    </div>
    <div class="flex flex-1 justify-end items-center">
      <app-icon [icon]="DOWN"></app-icon>
    </div>
  </div>
</div>
<ul *ngIf="focused" [id]="id" class="text-sm font-medium border border-button-blue-darkest rounded-lg border-t-0">
  <ng-container *ngFor="let option of domain">
    <li class="w-full">
      <div class="flex items-center ps-3">
        <input
          [checked]="isSelected(option.key)"
          (change)="onInputChange(option)"
          [id]="option.key"
          type="checkbox"
          value=""
          class="w-4 h-4 border border-button-blue-darkest rounded"
          autocomplete="on"
        />
        <label [for]="option.key" class="hover:cursor-pointer w-full py-3 ms-2 text-sm font-medium">{{ option.value }}</label>
      </div>
    </li>
  </ng-container>
</ul>
