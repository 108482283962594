import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '../../request/request-service';
import { ApiUrlService } from '../../api-url/api-url.service';
import { Price } from '../../../../models/price/price-model';

@Injectable({
  providedIn: 'root'
})
export class PriceService {
  constructor(
    private readonly requestService: RequestService,
    private readonly apiUrlService: ApiUrlService
  ) {}

  private getPricesByPriceConfigIdPublicUrl(priceConfigId: number): string {
    return `${this.apiUrlService.getPublicApiBasePath()}/prices/${priceConfigId}/prices`;
  }

  private getPricesByPriceConfigIdUrl(priceConfigId: number): string {
    return `${this.apiUrlService.getAdminApiBasePath()}/prices/${priceConfigId}/prices`;
  }

  getPricesByPriceConfigIdPublic$(priceConfigId: number): Observable<Price[]> {
    return this.requestService.getRequest$<Price[]>(this.getPricesByPriceConfigIdPublicUrl(priceConfigId));
  }

  getPricesByPriceConfigId$(priceConfigId: number): Observable<Price[]> {
    return this.requestService.getRequest$<Price[]>(this.getPricesByPriceConfigIdUrl(priceConfigId));
  }
}
