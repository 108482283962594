import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponseBase } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { LocalStorageService } from '../../local-storage';

@Injectable({
  providedIn: 'root'
})
export class RequestAuthInterceptorService implements HttpInterceptor {
  constructor(private readonly localStorageService: LocalStorageService) {}

  private isInternalRequest(url: string): boolean {
    return url.includes('api/internal') || url.includes('api/admin') || url.includes('api/backoffice');
  }

  private getCustomHeaders(request: HttpRequest<unknown>): Record<string, string | string[]> {
    const authToken = this.localStorageService.getAuthenticationToken();
    const csrfToken = this.localStorageService.getCsrfToken();

    const authHeader: Record<string, string | string[]> =
      authToken && this.isInternalRequest(request.url) ? { Authorization: `Bearer ${authToken}` } : {};
    const csrfHeader: Record<string, string | string[]> = csrfToken ? { 'X-XSRF-TOKEN': csrfToken } : {};

    return { ...authHeader, ...csrfHeader };
  }

  private updateStoredAuthToken(headers: HttpHeaders) {
    const newToken = headers.get('Authorization');
    if (newToken) {
      this.localStorageService.storeAuthenticationToken(newToken);
    }
  }

  private updateStoredCsrfToken(headers: HttpHeaders) {
    const newCsrfToken = headers.get('X-XSRF-TOKEN');
    if (newCsrfToken) {
      this.localStorageService.storeCsrfToken(newCsrfToken);
    }
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const newRequest = request.clone({
      withCredentials: true,
      setHeaders: this.getCustomHeaders(request)
    });

    return next.handle(newRequest).pipe(
      map(event => {
        if (event instanceof HttpResponseBase) {
          this.updateStoredAuthToken(event.headers);
          this.updateStoredCsrfToken(event.headers);
        }

        return event;
      })
    );
  }
}
