export interface ApiError {
  status: number;
  timestamp: string;
  message: string;
  debugMessage: string;
  subErrors: ApiSubError[];
}

export interface ApiSubError {
  objectName: string;
  fieldName: string;
  code: string;
  rejectedValue: unknown;
  message: string;
  violatedObjectTable: string;
  objectReferenceTable: string;
}
