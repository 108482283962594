<app-auth-header />

<app-auth-main-page-content>
  <app-h1 [title]="'Account Confirmation'"></app-h1>

  <p>This confirmation link is no longer valid.</p>
  <p [class]="'mb-4'">Please request a new confirmation link below and try again.</p>

  <app-action-bar>
    <app-icon-button
      buttonType="primary"
      label="Request new Confirmation Link"
      (clicked)="onRequestNewConfirmationLink()"
    ></app-icon-button>
  </app-action-bar>
</app-auth-main-page-content>
