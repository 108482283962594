import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-rows-no-content-text',
  templateUrl: './rows-no-content-text.component.html'
})
export class RowsNoContentTextComponent {
  @Input()
  noContentText!: string;
}
