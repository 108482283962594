<!-- MOBILE HEADER BAR -->
<div
  [classList]="
    'h-[64px] pt-2 w-full flex lg:hidden ' + (headerHeightClass === HEADER_HEIGHTS_CLASSES.DEFAULT ? 'bg-black' : 'bg-header-background')
  "
>
  <div class="w-5/6 pl-[10px]">
    <img
      alt="logo"
      class="h-[48px] w-auto cursor-pointer"
      ngSrc="assets/images/logo_white.png"
      width="3163"
      height="973"
      (click)="navigateToHome()"
      (keydown.enter)="navigateToHome()"
      (keydown.space)="navigateToHome()"
      tabindex="0"
    />
  </div>
  <div class="flex w-1/6 justify-end p-[10px] pt-1">
    <app-icon *ngIf="!mobileMenuOpen" [icon]="HAMBURGER" [class]="'text-white'" [size]="'xl'" (click)="toggleMobileMenu()"></app-icon>
    <app-icon *ngIf="mobileMenuOpen" [icon]="X_MARK" [class]="'text-white'" [size]="'xl'" (click)="toggleMobileMenu()"></app-icon>
  </div>
</div>

<!-- MOBILE MENU -->
<ng-container *ngIf="mobileMenuOpen">
  <div
    [classList]="
      'absolute w-full flex p-[20px] lg:hidden text-white ' +
      (headerHeightClass === HEADER_HEIGHTS_CLASSES.DEFAULT ? 'bg-black' : 'bg-header-background')
    "
  >
    <ul class="w-full">
      <li *ngIf="showSearchField" class="h-[30px] flex w-full">
        <input class="block h-[30px] rounded-[20px] pl-2 mr-2 text-black" placeholder="Search" type="text" [(ngModel)]="searchInput" />
        <app-icon [icon]="SEARCH" (click)="handleSearch()" [class]="'text-white'" [size]="'xl'"></app-icon>
      </li>
      <li *ngIf="ticketCounter" class="h-[30px] flex w-full mt-4">
        <app-icon
          [icon]="CART"
          [class]="'text-white'"
          [size]="'xl'"
          (click)="navigate(WEBSITE_CART_ROUTE)"
          class="hover:cursor-pointer"
        ></app-icon>
        <div>
          <a class="block h-[30px] text-lg pl-4 font-semibold" [routerLink]="WEBSITE_CART_ROUTE">Cart</a>
        </div>
      </li>
      <li class="h-[30px] flex w-full mt-4">
        <app-locale-select></app-locale-select>
      </li>
      <li *ngIf="isLoggedIn" class="h-[30px] flex w-full mt-4">
        <app-icon [icon]="PROFILE" [size]="'xl'"></app-icon>
        <a class="block h-[30px] text-lg pl-4 font-semibold" [routerLink]="[CUSTOMER_DASHBOARD_PROFILE_ROUTE]">Profile</a>
      </li>
      <li *ngIf="isLoggedIn" class="h-[30px] flex w-full mt-4">
        <app-icon [icon]="LOGOUT" [size]="'xl'"></app-icon>
        <a
          class="block h-[30px] text-lg pl-4 font-semibold"
          (click)="logout($event)"
          (keydown.enter)="logout($event)"
          (keydown.space)="logout($event)"
          tabindex="0"
        >
          Log out
        </a>
      </li>
      <li *ngIf="!isLoggedIn && showLoginButton" class="h-[30px] flex w-full mt-4">
        <app-icon [icon]="LOGIN" [size]="'xl'"></app-icon>
        <a class="block h-[30px] text-lg pl-4 font-semibold" [routerLink]="[WEBSITE_LOGIN_ROUTE]">Log in</a>
      </li>
    </ul>
  </div>
</ng-container>

<!-- DESKTOP HEADER BAR -->
<div
  [classList]="
    (showBackgroundImage
      ? 'bg-background-image bg-left-bottom bg-cover '
      : headerHeightClass === HEADER_HEIGHTS_CLASSES.DEFAULT
        ? 'bg-black '
        : '') + (headerHeightClass === HEADER_HEIGHTS_CLASSES.DEFAULT ? 'block h-[550px] bg-black' : 'bg-header-background')
  "
>
  <div
    [classList]="
      'block ' +
      'bg-opacity-70 ' +
      (headerHeightClass === HEADER_HEIGHTS_CLASSES.DEFAULT ? 'bg-black h-[550px]' : '') +
      (headerHeightClass === HEADER_HEIGHTS_CLASSES.SMALL ? 'bg-header-background hidden lg:block h-[64px]' : '')
    "
  >
    <div class="pt-[10px] mx-auto max-w-7xl px-2 hidden lg:flex">
      <div class="w-1/6 pl-2.5">
        <img
          alt="logo"
          class="h-[40px] w-auto cursor-pointer"
          ngSrc="assets/images/logo_white.png"
          width="3163"
          height="973"
          (click)="navigateToHome()"
          (keydown.enter)="navigateToHome()"
          (keydown.space)="navigateToHome()"
          tabindex="0"
        />
      </div>
      <div class="w-5/6 flex justify-end">
        <nav>
          <ul class="flex text-white items-center h-full text-lg pr-5 gap-4 font-semibold">
            <li *ngIf="showSearchField" class="flex items-center gap-2">
              <input class="rounded-[20px] pl-2 text-black" placeholder="Search Events" type="text" [(ngModel)]="searchInput" />
              <app-icon
                [classList]="'cursor-pointer'"
                [icon]="SEARCH"
                (click)="handleSearch()"
                (keydown.enter)="handleSearch()"
                (keydown.space)="handleSearch()"
                tabindex="0"
              ></app-icon>
            </li>
            <li *ngIf="ticketCounter" class="flex items-center gap-2">
              <app-icon
                [icon]="CART"
                (click)="navigate(WEBSITE_CART_ROUTE)"
                (keydown.enter)="navigate(WEBSITE_CART_ROUTE)"
                (keydown.space)="navigate(WEBSITE_CART_ROUTE)"
                tabindex="0"
                class="hover:cursor-pointer"
              ></app-icon>
              <a [routerLink]="[WEBSITE_CART_ROUTE]">Cart</a>
              <div
                class="absolute top-[5px] ml-[10px] bg-red h-[20px] w-[20px] rounded-[20px] hover:cursor-pointer"
                (click)="navigate(WEBSITE_CART_ROUTE)"
                (keydown.enter)="navigate(WEBSITE_CART_ROUTE)"
                (keydown.space)="navigate(WEBSITE_CART_ROUTE)"
                tabindex="0"
              >
                <div class="flex flex-grow items-center justify-center h-[20px] w-[20px]">
                  <p class="leading-3">{{ ticketCounter }}</p>
                </div>
              </div>
            </li>
            <li class="flex items-center gap-2">
              <app-locale-select></app-locale-select>
            </li>
            <li *ngIf="isLoggedIn" class="flex items-center gap-2">
              <img
                (click)="navigate(CUSTOMER_DASHBOARD_PROFILE_ROUTE)"
                (keydown.enter)="navigate(CUSTOMER_DASHBOARD_PROFILE_ROUTE)"
                (keydown.space)="navigate(CUSTOMER_DASHBOARD_PROFILE_ROUTE)"
                alt="Profile Icon"
                ngSrc="assets/images/profile_icon.png"
                height="512"
                width="512"
                class="invert h-10 w-auto hover:cursor-pointer"
                tabindex="0"
              />
            </li>
            <li *ngIf="isLoggedIn" class="flex items-center">
              <a
                *ngIf="showLoginButton"
                href="#"
                (click)="logout($event)"
                (keydown.enter)="logout($event)"
                (keydown.space)="logout($event)"
                class="text-white rounded-md py-2 text-lg font-medium text-header-text-color"
                tabindex="0"
              >
                Logout
              </a>
            </li>
            <li *ngIf="!isLoggedIn && showLoginButton" class="flex items-center">
              <a [routerLink]="[WEBSITE_LOGIN_ROUTE]">Login</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="lg:mt-12">
      <ng-content></ng-content>
    </div>
  </div>
</div>
